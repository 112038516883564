import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Form, Button, Row, Col} from 'react-bootstrap';
import CustomDateRangePicker from '../CustomDateRangePicker';
import {dateFormatToSend} from '../../util/transformDate.js'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';

import _get from 'lodash/get';
import _extend from 'lodash/extend';

class SearchForm extends Component {
	constructor(props) {
		super(props);
		this.onFieldChange = this.onFieldChange.bind(this);
		this.onDateSelect = this.onDateSelect.bind(this);		
		this.onSubmit = this.onSubmit.bind(this);

		this.state = {			
			criteria: {
				"StayPeriod": null,
				"DeadlinePeriod": null,
			}
		};

	}

	componentDidMount() {
		if (this.props.criteria) {
			this.getCriteria();
		}
	}

	onFieldChange(e) {
		let fieldName = e.target.name
		let fieldValue = e.target.value

		this.setState({
			criteria: _extend(this.state.criteria, {
				[fieldName]: fieldValue
			})
		})
	}

	onDateSelect(name, i, startDateValue, endDateValue) {
		let obj
		if (startDateValue) {
			obj = {
				"DateFrom": dateFormatToSend(startDateValue),
				"DateTo": dateFormatToSend(endDateValue),
			}
		} else {
			obj = null
		}

		this.setState({
			criteria: _extend(this.state.criteria, {
				[name]: obj
			})
		})
	}

	onKeyDown(e) {
		if (e.key === 'Enter') {
			this.props.handleFormSubmit(this.state.criteria)
		}
	}

	onSubmit() {
		this.props.handleFormSubmit(this.state.criteria)
	}

	getCriteria() {
		if (this.props.criteria) {		
			this.setState({				
				criteria: _extend(this.state.criteria, this.props.criteria)
			})
		}
	}

  render() {
		return (
      <Row>
			  <Col xs="8" className={`pt-4 offset-2 ${this.props.hasResult ? 'border-bottom' : ''}`}>
					<Form onKeyDown={(e) => this.onKeyDown(e)}>						
						<h2>Advanced Search</h2>		

						<Form.Group controlId="formDeadlinePeriod">
							<Row>
								<Col xs="3"><Form.Label>Deadline Period</Form.Label></Col>
								<Col xs="9">
									<CustomDateRangePicker
										handleSelect={this.onDateSelect}
										name="DeadlinePeriod"
										startDateValue={_get(this.state.criteria.DeadlinePeriod, 'DateFrom', '')}
										endDateValue={_get(this.state.criteria.DeadlinePeriod, 'DateTo', '')}
										isClearAvailable
									/>
								</Col>
							</Row>
						</Form.Group>

						<Form.Group controlId="formStayPeriod">
							<Row>
								<Col xs="3"><Form.Label>Stay Period</Form.Label></Col>
								<Col xs="9">
									<CustomDateRangePicker
										handleSelect={this.onDateSelect}
										name="StayPeriod"
										startDateValue={_get(this.state.criteria.StayPeriod, 'DateFrom')}
										endDateValue={_get(this.state.criteria.StayPeriod, 'DateTo')}
										isClearAvailable
									/>
								</Col>
							</Row>
						</Form.Group>
						
						<div className="text-center pt-3 pb-5">
							<Button variant="info" size="lg" type="button" onClick={() => this.onSubmit()}>
								<FontAwesomeIcon icon={faSearch} className="mr-1" />Search
							</Button>
						</div>
					</Form>
				</Col>
			</Row>
    );
  }
}

SearchForm.propTypes = {
	handleFormSubmit: PropTypes.func,
	criteria: PropTypes.object,
	hasResult: PropTypes.bool
};

function mapStateToProps(state) {
	return {
		criteria: state.searchnotissue.criteria
	}
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
