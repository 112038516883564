import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Auth from '../Auth/Auth';
import Select from 'react-select';

import _debounce from 'lodash/debounce';
import _map from 'lodash/map';
import _find from 'lodash/find';
const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

const endpoint = `${currentServerURL}/information/GetCustList`;
const method = 'POST'

class CustomerCodeSelect extends Component {
	constructor(props) {
		super(props);
		this.onSelectInputChange = this.onSelectInputChange.bind(this);
		this.onSelectClick = this.onSelectClick.bind(this);

		this.state = {
			options: [],
			isSearching: false,
			currentObj: '',
		};
	}

	componentDidMount() {
		if (this.props.defaultCustomerCode) {
			this.getLabel(this.props.defaultCustomerCode)
		}
	}

	getLabel(string) {
		const body = {"CustCodeOrName": string}

		fetch(endpoint, {
			method: method,
			body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
				if (result.status === "Success") {
					let options = _map(result.custList, (r) => ({
						'value': r.cltCode,
						'label': r.cltCode + ' - ' + r.cltName
					}))
					this.setState({
						currentObj: _find(options, {'value': string})
					})
				}
			})
			.catch((error) => {
				console.log('error: ', error)
			});
	}

	onSelectInputChange = _debounce((string, action) => {
		const act = action.action
		if (act === "input-change") {

			if (string) {
				this.setState({
					isSearching: true
				})

				const body = {"CustCodeOrName": string}

		    fetch(endpoint, {
					method: method,
		      body: JSON.stringify(body),
					headers: {
						Authorization: 'Bearer ' + auth.getAccessToken(),
						'Content-Type': 'application/json',
		        'Accept-Encoding': 'zip, deflate, br'
					}
				})
					.then(response => response.json())
					.then((result) => {
						if (result.status === "Success") {
							let options = _map(result.custList, (r) => ({
								'value': r.cltCode,
								'label': r.cltCode + ' - ' + r.cltName
							}))
							this.setState({
								options: options,
								isSearching: false
							})
						}

					})
					.catch((error) => {
						console.log('error: ', error)
						this.setState({
							isSearching: false
						})
					});

			} else {
				this.clearLoginOptions()
			}
		}
	 }, 1000)

	onSelectClick(selectedOption) {
		if (selectedOption) {
			this.props.handleSelect(selectedOption.value)
			this.setState({
				currentObj: selectedOption
			})
		} else { // clear
			this.clearLoginOptions()
		}
	}

	clearLoginOptions() {
		this.setState({
			options: [],
			currentObj: ''
		});
		this.props.handleSelect('')
	}

  render() {
		return (
			<Select
				name="customerCodeSelect"
				value={this.state.currentObj}
				onChange={this.onSelectClick}
				options={this.state.options ? this.state.options : []}
				onInputChange={(string, action) => this.onSelectInputChange(string, action)}
				placeholder="Search Customer Code"
				isClearable
				isLoading={this.state.isSearching}
			/>
    );
  }
}

CustomerCodeSelect.propTypes = {
	handleSelect: PropTypes.func,
	defaultCustomerCode: PropTypes.string,
}

export default CustomerCodeSelect;
