// general
export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

export const GET_UNREAD_MSG_REQUEST = 'GET_UNREAD_MSG_REQUEST';
export const GET_UNREAD_MSG_SUCCESS = 'GET_UNREAD_MSG_SUCCESS';
export const GET_UNREAD_MSG_FAILURE = 'GET_UNREAD_MSG_FAILURE';

export const GET_CURRENCY_LIST_REQUEST = 'GET_CURRENCY_LIST_REQUEST';
export const GET_CURRENCY_LIST_SUCCESS = 'GET_CURRENCY_LIST_SUCCESS';
export const GET_CURRENCY_LIST_FAILURE = 'GET_CURRENCY_LIST_FAILURE';

// search
export const SEARCH_BOOKING_REQUEST = 'SEARCH_BOOKING_REQUEST';
export const SEARCH_BOOKING_SUCCESS = 'SEARCH_BOOKING_SUCCESS';
export const SEARCH_BOOKING_FAILURE = 'SEARCH_BOOKING_FAILURE';
export const CHANGE_PAGE = 'CHANGE_PAGE';

// booking
export const CLEAR_BOOKING = 'CLEAR_BOOKING';
export const CLEAR_MSG = 'CLEAR_MSG';
export const CLEAR_BOOKING_SEARCH_RESULT = 'CLEAR_BOOKING_SEARCH_RESULT';

// search not issue
export const SEARCH_BOOKING_NOTISSUE_REQUEST = 'SEARCH_BOOKING_NOTISSUE_REQUEST';
export const SEARCH_BOOKING_NOTISSUE_SUCCESS = 'SEARCH_BOOKING_NOTISSUE_SUCCESS';
export const SEARCH_BOOKING_NOTISSUE_FAILURE = 'SEARCH_BOOKING_NOTISSUE_FAILURE';
export const CHANGE_NOTISSUE_PAGE = 'CHANGE_NOTISSUE_PAGE';

// booking not issue
export const CLEAR_NOTISSUE_BOOKING = 'CLEAR_NOTISSUE_BOOKING';
export const CLEAR_NOTISSUE_MSG = 'CLEAR_NOTISSUE_MSG';
export const CLEAR_BOOKING_SEARCH_NOTISSUE_RESULT = 'CLEAR_BOOKING_SEARCH_NOTISSUE_RESULT';

export const GET_BOOKING_REQUEST = 'GET_BOOKING_REQUEST';
export const GET_BOOKING_SUCCESS = 'GET_BOOKING_SUCCESS';
export const GET_BOOKING_FAILURE = 'GET_BOOKING_FAILURE';

export const UPDATE_BOOKING_REQUEST = 'UPDATE_BOOKING_REQUEST';
export const UPDATE_BOOKING_SUCCESS = 'UPDATE_BOOKING_SUCCESS';
export const UPDATE_BOOKING_FAILURE = 'UPDATE_BOOKING_FAILURE';

export const GET_HEADER_REQUEST = 'GET_HEADER_REQUEST';
export const GET_HEADER_SUCCESS = 'GET_HEADER_SUCCESS';
export const GET_HEADER_FAILURE = 'GET_HEADER_FAILURE';

export const SEND_RO_REQUEST = 'SEND_RO_REQUEST';
export const SEND_RO_SUCCESS = 'SEND_RO_SUCCESS';
export const SEND_RO_FAILURE = 'SEND_RO_FAILURE';

export const READ_MSG_REQUEST = 'READ_MSG_REQUEST';
export const READ_MSG_SUCCESS = 'READ_MSG_SUCCESS';
export const READ_MSG_FAILURE = 'READ_MSG_FAILURE';

export const DELETE_MSG_REQUEST = 'DELETE_MSG_REQUEST';
export const DELETE_MSG_SUCCESS = 'DELETE_MSG_SUCCESS';
export const DELETE_MSG_FAILURE = 'DELETE_MSG_FAILURE';

export const CREATE_MSG_REQUEST = 'CREATE_MSG_REQUEST';
export const CREATE_MSG_SUCCESS = 'CREATE_MSG_SUCCESS';
export const CREATE_MSG_FAILURE = 'CREATE_MSG_FAILURE';

export const ADD_HISTORY_REQUEST = 'ADD_HISTORY_REQUEST';
export const ADD_HISTORY_SUCCESS = 'ADD_HISTORY_SUCCESS';
export const ADD_HISTORY_FAILURE = 'ADD_HISTORY_FAILURE';

export const GET_RO_HISTORY_CONTENT_REQUEST = 'GET_RO_HISTORY_CONTENT_REQUEST';
export const GET_RO_HISTORY_CONTENT_SUCCESS = 'GET_RO_HISTORY_CONTENT_SUCCESS';
export const GET_RO_HISTORY_CONTENT_FAILURE = 'GET_RO_HISTORY_CONTENT_FAILURE';

export const CLEAR_RO_HISTORY_CONTENT = 'CLEAR_RO_HISTORY_CONTENT';

export const VERIFY_BOOKING_REQUEST = 'VERIFY_BOOKING_REQUEST';
export const VERIFY_BOOKING_SUCCESS = 'VERIFY_BOOKING_SUCCESS';
export const VERIFY_BOOKING_FAILURE = 'VERIFY_BOOKING_FAILURE';

export const UNVERIFY_BOOKING_REQUEST = 'UNVERIFY_BOOKING_REQUEST';
export const UNVERIFY_BOOKING_SUCCESS = 'UNVERIFY_BOOKING_SUCCESS';
export const UNVERIFY_BOOKING_FAILURE = 'UNVERIFY_BOOKING_FAILURE';

export const CLONE_SEGMENT_REQUEST = 'CLONE_SEGMENT_REQUEST';
export const CLONE_SEGMENT_SUCCESS = 'CLONE_SEGMENT_SUCCESS';
export const CLONE_SEGMENT_FAILURE = 'CLONE_SEGMENT_FAILURE';

export const COPY_ONE_TO_W_BOOKING_REQUEST = 'COPY_ONE_TO_W_BOOKING_REQUEST';
export const COPY_ONE_TO_W_BOOKING_SUCCESS = 'COPY_ONE_TO_W_BOOKING_SUCCESS';
export const COPY_ONE_TO_W_BOOKING_FAILURE = 'COPY_ONE_TO_W_BOOKING_FAILURE';

export const COPY_W_TO_ONE_BOOKING_REQUEST = 'COPY_W_TO_ONE_BOOKING_REQUEST';
export const COPY_W_TO_ONE_BOOKING_SUCCESS = 'COPY_W_TO_ONE_BOOKING_SUCCESS';
export const COPY_W_TO_ONE_BOOKING_FAILURE = 'COPY_W_TO_ONE_BOOKING_FAILURE';

export const CANCEL_BOOKING_REQUEST = 'CANCEL_BOOKING_REQUEST';
export const CANCEL_BOOKING_SUCCESS = 'CANCEL_BOOKING_SUCCESS';
export const CANCEL_BOOKING_FAILURE = 'CANCEL_BOOKING_FAILURE';

export const CREATE_MANUAL_BOOKING_REQUEST = 'CREATE_MANUAL_BOOKING_REQUEST';
export const CREATE_MANUAL_BOOKING_SUCCESS = 'CREATE_MANUAL_BOOKING_SUCCESS';
export const CREATE_MANUAL_BOOKING_FAILURE = 'CREATE_MANUAL_BOOKING_FAILURE';

// alert msg
export const GET_ALERT_MSG_REQUEST = 'GET_ALERT_MSG_REQUEST';
export const GET_ALERT_MSG_SUCCESS = 'GET_ALERT_MSG_SUCCESS';
export const GET_ALERT_MSG_FAILURE = 'GET_ALERT_MSG_FAILURE';

export const DELETE_ALERT_MSG_REQUEST = 'DELETE_ALERT_MSG_REQUEST';
export const DELETE_ALERT_MSG_SUCCESS = 'DELETE_ALERT_MSG_SUCCESS';
export const DELETE_ALERT_MSG_FAILURE = 'DELETE_ALERT_MSG_FAILURE';

export const CREATE_ALERT_MSG_REQUEST = 'CREATE_ALERT_MSG_REQUEST';
export const CREATE_ALERT_MSG_SUCCESS = 'CREATE_ALERT_MSG_SUCCESS';
export const CREATE_ALERT_MSG_FAILURE = 'CREATE_ALERT_MSG_FAILURE';

export const UPDATE_ALERT_MSG_REQUEST = 'UPDATE_ALERT_MSG_REQUEST';
export const UPDATE_ALERT_MSG_SUCCESS = 'UPDATE_ALERT_MSG_SUCCESS';
export const UPDATE_ALERT_MSG_FAILURE = 'UPDATE_ALERT_MSG_FAILURE';

export const CLEAR_ALERT_MSG_RECORDS = 'CLEAR_ALERT_MSG_RECORDS';

// rosters
export const GET_ROSTERS_REQUEST = 'GET_ROSTERS_REQUEST';
export const GET_ROSTERS_SUCCESS = 'GET_ROSTERS_SUCCESS';
export const GET_ROSTERS_FAILURE = 'GET_ROSTERS_FAILURE';

export const UPDATE_ROSTERS_REQUEST = 'UPDATE_ROSTERS_REQUEST';
export const UPDATE_ROSTERS_SUCCESS = 'UPDATE_ROSTERS_SUCCESS';
export const UPDATE_ROSTERS_FAILURE = 'UPDATE_ROSTERS_FAILURE';

export const ADD_ROSTERS_REQUEST = 'ADD_ROSTERS_REQUEST';
export const ADD_ROSTERS_SUCCESS = 'ADD_ROSTERS_SUCCESS';
export const ADD_ROSTERS_FAILURE = 'ADD_ROSTERS_FAILURE';

export const GET_TEAM_MEMBER_REQUEST = 'GET_TEAM_MEMBER_REQUEST';
export const GET_TEAM_MEMBER_SUCCESS = 'GET_TEAM_MEMBER_SUCCESS';
export const GET_TEAM_MEMBER_FAILURE = 'GET_TEAM_MEMBER_FAILURE';

export const CLEAR_ROSTER_SEARCH_RESULT = 'CLEAR_ROSTER_SEARCH_RESULT';
