import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Form, Button, Row, Col} from 'react-bootstrap';
import CustomerCodeSelect from '../CustomerCodeSelect';

import _pull from 'lodash/pull';
import _clone from 'lodash/clone';
import _extend from 'lodash/extend';
import _size from 'lodash/size';

import {noOfAdultInArr} from '../../util/transformName.js';

class VerifyModal extends Component {
	constructor(props) {
		super(props);

		this.onFieldChange = this.onFieldChange.bind(this)
		this.onSubmitClick = this.onSubmitClick.bind(this)
		this.onCloseClick = this.onCloseClick.bind(this)
		this.onCheckBoxClick = this.onCheckBoxClick.bind(this)
		this.onPaxesClick = this.onPaxesClick.bind(this)
		this.onCustCodeChange = this.onCustCodeChange.bind(this)

		this.state = {
			form : {
		    userRemark: "",
				selectedPax: [],
				pnr: "",
				reqInv: true,
				cltCode: "",
		    COD1: "", // XO Reference
		    COD2: "", // Reference
				vchEmail: "",
				night: 0,
				package: 0,
				isProvideCoupon: false,
				isPromotion: false,
				isShowCustRef: false,
				isShowMap: false,
		    adtQty: 0,
		    chdQty: 0,
			},
			selectedPaxName: []
		};
	}

	componentDidMount() {
		// this.getState()
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.isOpen && this.props.isOpen) {
			this.getState()
		}
	}

	onFieldChange(e) {
		this.setState({
			form: _extend(this.state.form, {
				[e.target.name]: e.target.value
			})
		})
	}

	onCustCodeChange(value) {
		this.setState({
			form: _extend(this.state.form, {
				cltCode: value
			})
		})
	}

	onSubmitClick() {
		if (this.props.isVerified) {
			this.props.handleUnverify(
				this.props.bookingDetail.get('txno'),
				this.props.bookingDetail.get('seqnum')
			)
		} else {
			this.props.handleVerify(
				this.props.bookingDetail.get('txno'),
				this.props.bookingDetail.get('seqnum'),
				this.state.form
			)
		}
	}

	onCloseClick() {
		this.props.handleCloseModal()
	}

	onPaxesClick(e, paxName, paxNum) {
		var deepNum = _clone(this.state.form.selectedPax);
		var deepName = _clone(this.state.selectedPaxName);

		if (e.target.checked) {
			deepNum.push(paxNum)
			deepName.push(paxName)
		} else {
			_pull(deepNum, paxNum);
			_pull(deepName, paxName);
		}

		this.setState({
			form: _extend(this.state.form, {
				selectedPax: deepNum,
				adtQty: noOfAdultInArr(deepName),
				chdQty: _size(deepName) - noOfAdultInArr(deepName)
			}),
			selectedPaxName: deepName
		})
	}

	onCheckBoxClick(e) {
		this.setState({
			form: _extend(this.state.form, {
				[e.target.name]: e.target.checked
			})
		})
	}

	getState() {
		let paxesNum = []
		this.props.bookingDetail.get('paxes').map(pax =>
			paxesNum.push(pax.get('paxNum'))
		)
		let paxesName = []
		this.props.bookingDetail.get('paxes').map(pax =>
			paxesName.push(pax.get('famName'))
		)

		this.setState({
			form : {
		    userRemark: this.props.bookingDetail.get('note1') + ' ' + this.props.bookingDetail.get('note2'),
				selectedPax: paxesNum,
				pnr: "",
				reqInv: true,
				cltCode: this.props.bookingDetail.get('customerCode'),
		    COD1: "", // XO Reference
		    COD2: "", // Reference
				vchEmail: "",
				night: this.props.bookingDetail.get('night'),
				package: 0,
				isProvideCoupon: this.props.bookingDetail.get('provideCoupon'),
				isPromotion: false,
				isShowCustRef: false,
				isShowMap: false,
		    adtQty: noOfAdultInArr(paxesName),
		    chdQty: _size(paxesName) - noOfAdultInArr(paxesName),
			},
			selectedPaxName: paxesName
		})
	}

	render() {
		return (
			<Modal show={this.props.isOpen} centered onHide={this.onCloseClick} size={this.props.isVerified ? 'md' : 'lg'}>
			  <Modal.Header closeButton>
			    <Modal.Title>{this.props.isVerified ? "Unverify" : "Verify"}</Modal.Title>
			  </Modal.Header>

				<Modal.Body>
					{this.props.isVerified ?
						<div className="text-center my-4">Confirm to unverify?</div>:
						<div>
							<Row className="form-group">
								<Col xs={3}>Booking No.</Col>
								<Col xs={9}>
									{this.props.bookingDetail.get('txno')} ({this.props.bookingDetail.get('seqnum')})
								</Col>
							</Row>
							<Row className="form-group">
								<Col xs={3}>Hotel Name</Col>
								<Col xs={9}>
									{this.props.bookingDetail.get('hotelName')}
								</Col>
							</Row>
							<Row className="form-group">
								<Col xs={3}>Type</Col>
								<Col xs={9}>
									Voucher
								</Col>
							</Row>
							<Row className="form-group">
								<Col xs={3}><Form.Label>Remarks</Form.Label></Col>
								<Col xs={9}>
									<Form.Control
										type="text"
										value={this.state.form.userRemark}
										name="userRemark"
										onChange={(e) => this.onFieldChange(e)}
										autoComplete="off"
									/>
								</Col>
							</Row>
							<Row className="form-group">
								<Col xs={3}><Form.Label>Passenger</Form.Label></Col>
								<Col xs={9}>
									{this.props.bookingDetail.get('paxes').map((pax, i) =>
										<div key={i}>
											<Form.Check
												type="checkbox"
												id={pax.get('famName')}
												label={pax.get('famName')}
												checked={this.state.form.selectedPax.includes(pax.get('paxNum'))}
												onChange={(e) => this.onPaxesClick(e, pax.get('famName'), pax.get('paxNum'))}
												name="selectedPax"
											/>
										</div>
									)}
								</Col>
							</Row>
							<Row className="form-group">
								<Col xs={3}><Form.Label>PNR</Form.Label></Col>
								<Col xs={9}>
									<Form.Control
										type="text"
										value={this.state.form.pnr}
										name="pnr"
										onChange={(e) => this.onFieldChange(e)}
									/>
								</Col>
							</Row>
							<Row className="form-group">
								<Col xs={3}><Form.Label>Auto Invoicing</Form.Label></Col>
								<Col xs={9}>
									<Form.Check
										type="checkbox"
										id="checkbox-confirm"
										checked={this.state.form.reqInv}
										onChange={(e) => this.onCheckBoxClick(e)}
										name="reqInv"
									/>
								</Col>
							</Row>
							<Row className="form-group">
								<Col xs={3}><Form.Label>Customer Code</Form.Label></Col>
								<Col xs={9}>
									<CustomerCodeSelect
										handleSelect={this.onCustCodeChange}
										defaultCustomerCode={this.props.bookingDetail.get('customerCode')}
									/>
								</Col>
							</Row>
							<Row className="form-group">
								<Col xs={3}><Form.Label>XO Reference</Form.Label></Col>
								<Col xs={9}>
									<Form.Control
										type="text"
										value={this.state.form.COD1}
										name="COD1"
										onChange={(e) => this.onFieldChange(e)}
									/>
								</Col>
							</Row>
							<Row className="form-group">
								<Col xs={3}><Form.Label>Reference</Form.Label></Col>
								<Col xs={9}>
									<Form.Control
										type="text"
										value={this.state.form.COD2}
										name="COD2"
										onChange={(e) => this.onFieldChange(e)}
									/>
								</Col>
							</Row>
							<Row className="form-group">
								<Col xs={3}><Form.Label>Voucher Email</Form.Label></Col>
								<Col xs={9}>
									<Form.Control
										type="email"
										value={this.state.form.vchEmail}
										name="vchEmail"
										onChange={(e) => this.onFieldChange(e)}
										autoComplete="off"
									/>
								</Col>
							</Row>
							<Row className="form-group">
								<Col xs={3}><Form.Label>Night</Form.Label></Col>
								<Col xs={9}>
									<Form.Control
										type="number"
										value={this.state.form.night}
										name="night"
										onChange={(e) => this.onFieldChange(e)}
										min="0"
									/>
								</Col>
							</Row>
							<Row className="form-group">
								<Col xs={3}><Form.Label>Package</Form.Label></Col>
								<Col xs={9}>
									<Form.Control
										type="number"
										value={this.state.form.package}
										name="package"
										onChange={(e) => this.onFieldChange(e)}
										min="0"
									/>
								</Col>
							</Row>
							<Row className="form-group">
								<Col xs={3}>Provide Coupon</Col>
								<Col xs={9}>
									<Form.Control
										type="checkbox"
										checked={this.state.form.isProvideCoupon}
										name="isProvideCoupon"
										onChange={(e) => this.onCheckBoxClick(e)}
									/>
								</Col>
							</Row>
							<Row className="form-group">
								<Col xs={3}>Is promotion</Col>
								<Col xs={9}>
									<Form.Control
										type="checkbox"
										checked={this.state.form.isPromotion}
										name="isPromotion"
										onChange={(e) => this.onCheckBoxClick(e)}
									/>
								</Col>
							</Row>
							<Row className="form-group">
								<Col xs={3}>Show Customer Ref.</Col>
								<Col xs={9}>
									<Form.Control
										type="checkbox"
										checked={this.state.form.isShowCustRef}
										name="isShowCustRef"
										onChange={(e) => this.onCheckBoxClick(e)}
									/>
								</Col>
							</Row>
							<Row className="form-group">
								<Col xs={3}>Map Information</Col>
								<Col xs={9}>
									<Form.Control
										type="checkbox"
										checked={this.state.form.isShowMap}
										name="isShowMap"
										onChange={(e) => this.onCheckBoxClick(e)}
									/>
								</Col>
							</Row>
						</div>
					}
			  </Modal.Body>

			  <Modal.Footer>
					<div className="text-center w-100">
						<Button variant="primary" onClick={this.onSubmitClick}>{this.props.isVerified ? 'Unverify' : 'Submit'}</Button>
					</div>
			  </Modal.Footer>
			</Modal>
	  );
	}
}

VerifyModal.propTypes = {
	isOpen: PropTypes.bool,
	isVerified: PropTypes.bool,
	bookingDetail: PropTypes.object,
	handleCloseModal: PropTypes.func,
	handleVerify: PropTypes.func,
	handleUnverify: PropTypes.func
};

export default VerifyModal;
