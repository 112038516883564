import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';

import TypeCodeSelect from '../../components/TypeCodeSelect';
import LoginSelect from '../../components/LoginSelect';

class PopUpModal extends Component {
	constructor(props) {
		super(props);

		this.onSubmitClick = this.onSubmitClick.bind(this)
		this.onCloseClick = this.onCloseClick.bind(this)
		this.onFieldChange = this.onFieldChange.bind(this)
    this.onTypeCodeSelect = this.onTypeCodeSelect.bind(this);
		this.onLoginSelect = this.onLoginSelect.bind(this);

		this.state = {
			amendnote: '',
			typeCode: '',
			login: ''
		};
	}

	onSubmitClick() {
		if (this.props.action === 'cloneSeg') {
			this.props.handleCloneSeg(
				this.props.bookingDetail.get('txno'),
				this.props.bookingDetail.get('seqnum')
			)
		} else if (this.props.action === 'cloneToBooking') {
			if (this.props.isWBooking) {
				this.props.handleCopyWTo1Booking(
					this.props.bookingDetail.get('txno'),
					this.props.bookingDetail.get('seqnum')
				)
			} else {
				this.props.handleCopy1ToWBooking(
					this.props.bookingDetail.get('txno'),
					this.props.bookingDetail.get('seqnum'),
					this.state.typeCode,
					this.state.login,
				)
			}

		} else if (this.props.action === 'cancel') {
			this.props.handleCancelBooking(
				this.props.bookingDetail.get('txno'),
				this.props.bookingDetail.get('seqnum'),
				this.state.amendnote
			)
		}
	}

	onCloseClick() {
		this.props.handleCloseModal()
		this.setState({
			amendnote: '',
			typeCode: '',
			login: ''
		})
	}

	onFieldChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onTypeCodeSelect(typeCode, login) {
		this.setState({
			typeCode: typeCode ? typeCode : '',
			login: typeCode ? login : ''
		});
	}

  onLoginSelect(login, name, i) {
		this.setState({
			login: login ? login : ''
		});
	}

	render() {
		return (
			<Modal show={this.props.isOpen} centered onHide={this.onCloseClick}>
			  <Modal.Header closeButton>
					<Modal.Title>
						{this.props.action === 'cloneSeg' && 'Clone Segment'}
						{this.props.action === 'cloneToBooking' && `Copy to ${this.props.isWBooking ? this.props.oneTnxo : this.props.wTnxo} (${this.props.bookingDetail.get('seqnum')})`}
						{this.props.action === 'cancel' && 'Cancel Booking'}
					</Modal.Title>
			  </Modal.Header>

				<Modal.Body>
					{this.props.action === 'cloneSeg' &&
						<div className="text-center my-4">Are you sure to clone a new segment from {this.props.bookingDetail.get('txno')} ({this.props.bookingDetail.get('seqnum')})?</div>
					}
					{this.props.action === 'cloneToBooking' &&
						<div className="text-center my-4">Are you sure to copy<br/> {this.props.bookingDetail.get('txno')} ({this.props.bookingDetail.get('seqnum')}) to {this.props.isWBooking ? this.props.oneTnxo : this.props.wTnxo} ({this.props.bookingDetail.get('seqnum')})?</div>
					}

					{this.props.action === 'cloneToBooking' && !this.props.isWBooking &&
						<div>
							<Form.Group>
								<Row>
									<Col xs="3" className="text-right"><Form.Label>Company</Form.Label></Col>
									<Col xs="9">
										<TypeCodeSelect
											handleSelect={this.onTypeCodeSelect}
											selectedTypeCode={this.state.typeCode}
											isClearable
										/>
									</Col>
								</Row>
							</Form.Group>
							<Form.Group>
								<Row>
									<Col xs="3" className="text-right"><Form.Label>User</Form.Label></Col>
									<Col xs="9">
										<LoginSelect
											handleSelect={this.onLoginSelect}
											selectedTypeCode={this.state.typeCode}
											selectedLogin={this.state.login}
											isClearable
										/>
									</Col>
								</Row>
							</Form.Group>

						</div>
					}

					{this.props.action === 'cancel' &&
						<div>
							<div className="text-center my-4">
								Are you sure to cancel this booking?
							</div>
							<div className="row justify-content-center mb-3">
								<div className="col-9 text-center">
									Amendment Notes
									<Form.Control
										type="text"
										value={this.state.amendnote}
										name="amendnote"
										onChange={(e) => this.onFieldChange(e)}
									/>
								</div>
							</div>
						</div>
					}
			  </Modal.Body>

			  <Modal.Footer>
					<div className="text-center w-100">
						<Button
							variant="primary"
							onClick={this.onSubmitClick}
							disabled={this.props.action === 'cancel' && this.state.amendnote === ''}
						>Submit</Button>
					</div>
			  </Modal.Footer>
			</Modal>
	  );
	}
}

PopUpModal.propTypes = {
	isOpen: PropTypes.bool,
	action: PropTypes.string,
	isWBooking: PropTypes.bool,
	oneTnxo: PropTypes.string,
	wTnxo: PropTypes.string,
	bookingDetail: PropTypes.object,
	handleCloseModal: PropTypes.func,
	handleCloneSeg: PropTypes.func,
	handleCopy1ToWBooking: PropTypes.func,
	handleCopyWTo1Booking: PropTypes.func,
	handleCancelBooking: PropTypes.func
};

export default PopUpModal;
