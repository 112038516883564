import {combineReducers} from 'redux';
import * as ActionTypes from '../constants/ActionTypes';

// Import the actions types constant we defined in our actions
import search from './search';
import searchnotissue from './searchnotissue';
import loading from './loading';
import booking from './booking';
import msg from './msg';
import alertMsg from './alertMsg';
import roster from './roster';

const initialState = {
	profileInfo: null,
	unreadMsg: [],
	currencyList: null
};

const general = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.GET_PROFILE_SUCCESS:
		return Object.assign({}, state, {
			profileInfo: action.payload
		});
	case ActionTypes.GET_UNREAD_MSG_SUCCESS:
		return Object.assign({}, state, {
			unreadMsg: action.payload
		});
	case ActionTypes.GET_CURRENCY_LIST_SUCCESS:
		return Object.assign({}, state, {
			currencyList: action.payload
		});
	default:
		return state;
	}
};

// Combine all the reducers
const rootReducer = combineReducers({
  general,
  loading,
	search,
	searchnotissue,
	booking,
	msg,
	alertMsg,
	roster,
})

export default rootReducer;
