import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Form} from 'react-bootstrap';
import _map from 'lodash/map';
import _size from 'lodash/size';
import TextareaAutosize from 'react-autosize-textarea'

const subject = 'D/L PASS & BKG AUTO RELEASE'
const detail =
  'As no reply from your side and this booking already passed deadline date so booking auto release.'
class PopUpModal extends Component {
	constructor(props) {
		super(props);

		this.onSubmitClick = this.onSubmitClick.bind(this)
		this.onCloseClick = this.onCloseClick.bind(this)
		this.onFieldChange = this.onFieldChange.bind(this)

		this.state = {
			amendnote: '',
			subject: subject,
			detail: detail,
			isAddMsg: true
		}
	}

	onSubmitClick() {
		if (this.props.action === 'cancel') {
			_map(this.props.cxlCriteria.params, c => {
			  c.Amendnotes = this.state.amendnote
			})
			if (this.state.isAddMsg) {
			  this.props.cxlCriteria.msg = {
				Subject: this.state.subject,
				Detail: this.state.detail
			  }
			}
			this.props.handleCancelBooking(this.props.cxlCriteria)
			this.onCloseClick()
		}
	}

	onCloseClick() {
		this.props.handleCloseModal()
		this.setState({
			amendnote: '',
			subject: subject,
			detail: detail,
			isAddMsg: true
		})
	}

	onFieldChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	render() {
		return (
			<Modal show={this.props.isOpen} centered onHide={this.onCloseClick} size="lg">
			  <Modal.Header closeButton>
					<Modal.Title>
						{this.props.action === 'cancel' && 'Cancel Booking'}
					</Modal.Title>
			  </Modal.Header>

				<Modal.Body>					
					{this.props.action === 'cancel' &&
						<div>
							<div className="text-center my-4">
								{this.props.cxlIsBatch ? 'Are you sure to cancel these ' + _size(this.props.cxlCriteria) + ' booking(s)?' : 'Are you sure to cancel this booking?'}
							</div>
							<div className="row justify-content-center mb-3">
								<div className="col-lg-12 pt-4">
									<div>
										<div className="form-group row">
											<div className="col-3">
												<Form.Label htmlFor="amendnote">
													Amendment Notes
									</Form.Label>
											</div>
											<div className="col-9">
												<Form.Control
													type="text"
													value={this.state.amendnote}
													name="amendnote"
													onChange={e => this.onFieldChange(e)}
												/>
											</div>
										</div>
										<hr></hr>
										<div className="form-group row">
											<div className="col-9 offset-3">
												<Form.Check
													type="switch"
													id="isAddMsg"
													name="isAddMsg"
													checked={this.state.isAddMsg}
													onChange={() =>
														this.setState({ isAddMsg: !this.state.isAddMsg })
													}
													label="Add New message after cancel success"
												/>
											</div>
										</div>
										{this.state.isAddMsg && (
											<div>
												<div className="form-group row">
													<div className="col-3">
														<Form.Label htmlFor="subject">Subject</Form.Label>
													</div>
													<div className="col-9">
														<Form.Control
															type="text"
															value={this.state.subject}
															id="subject"
															name="subject"
															onChange={e => this.onFieldChange(e)}
															maxLength={80}
														/>
													</div>
												</div>
												<div className="form-group row">
													<div className="col-3">
														<Form.Label htmlFor="detail">Detail</Form.Label>
													</div>
													<div className="col-9">
														<TextareaAutosize
															value={this.state.detail}
															name="detail"
															rows={10}
															className="form-control"
															onResize={e => { }}
															onChange={e => this.onFieldChange(e)}
														/>
													</div>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					}
			  </Modal.Body>

			  <Modal.Footer>
					<div className="text-center w-100">
						<Button
							variant="primary"
							onClick={this.onSubmitClick}
							disabled={this.props.action === 'cancel' && this.state.amendnote === ''}
						>Submit</Button>
					</div>
			  </Modal.Footer>
			</Modal>
	  );
	}
}

PopUpModal.propTypes = {
	cxlIsBatch: PropTypes.bool, 
	isOpen: PropTypes.bool,
	action: PropTypes.string,
	cxlCriteria: PropTypes.array,
	handleCancelBooking: PropTypes.func,
	handleCloseModal: PropTypes.func
};

export default PopUpModal;
