import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Accordion, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleDown, faChevronCircleUp} from '@fortawesome/free-solid-svg-icons';

import Title from '../components/Title';
import SearchNotIssueForm from '../components/SearchNotIssuePage/SearchForm';
import SearchNotIssueResult from '../components/SearchNotIssuePage/SearchResult';
import {withRouter} from 'react-router-dom';

import _get from 'lodash/get';
import _chunk from 'lodash/chunk';
import _omitBy from 'lodash/omitBy';

import {searchBookingNotIssue, changeNotIssuePage, clearBookingSearchNotIssueResult} from '../actions/searchnotissue'; // Import your actions
import {getBookingDetail, batchCancelBooking} from '../actions/booking';

const chunkSize = 30;

class SearchBookingNotIssuePage extends Component {
  constructor(props) {
    super(props);
    this.onCollapseClick = this.onCollapseClick.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onDetailClick = this.onDetailClick.bind(this);
    this.onCancelBooking = this.onCancelBooking.bind(this);

    this.state = {
      isOpen: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.onCollapseClick();
  }

  componentWillUnmount() {
    if (!this.props.bookingTxno) {
      this.props.clearBookingSearchNotIssueResult();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchNotIssueResult !== this.props.searchNotIssueResult) {
      this.onCollapseClick();
      this.props.changeNotIssuePage(0);
    }
    if (!prevProps.bookingTxno && this.props.bookingTxno) {
      // route to detail page
      this.props.history.push(`/${this.props.bookingTxno}-${this.props.bookingSeqnum}`)
    }
  }

  onCollapseClick() {
    if (this.props.searchNotIssueResult && this.props.searchNotIssueResult.status === "Success") {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }
  }

  onFormSubmit(criteria) {
    const criteriaWithValue = _omitBy(criteria, cri => (cri == null || cri === ""))
    this.props.searchBookingNotIssue(criteriaWithValue);
  }

  onPageChange(val) {
    if (this.props.currentPage !== val) {
      this.props.changeNotIssuePage(val);
    }
  }

  onDetailClick(txNo, seqnum) {
    this.props.getBookingDetail(txNo, seqnum)
  }

  onCancelBooking(criteria) {
    this.props.batchCancelBooking(criteria, () => {
      this.props.searchBookingNotIssue(this.props.searchCriteria)
      this.onCollapseClick();
    })
  }

  render() {
    let recordQuan = 0
    if (this.props.searchNotIssueResult && this.props.searchNotIssueResult.bookingDetails) {
      recordQuan = this.props.searchNotIssueResult.bookingDetails.length
    }
    const bookingDetails = _get(this.props.searchNotIssueResult, 'bookingDetails', [])
    const hasResult = recordQuan !== 0
    return (
      <div>
        <Title title="Search Booking(Not Issue)" browserTitle="Search Booking(Not Issue)"/>
        <Accordion defaultActiveKey="0">
          <div>
            <div className="collapse-wrapper text-center">
              <span className="collapse__line"/>
              <Accordion.Toggle as={Button} variant="link" eventKey="0" className="btn py-0 px-5" onClick={() => this.onCollapseClick()}>
                <FontAwesomeIcon icon={this.state.isOpen ? faChevronCircleDown : faChevronCircleUp} size="lg" className="collapse__icon" />
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse in={this.state.isOpen} eventKey="0">
              <SearchNotIssueForm
                handleFormSubmit={this.onFormSubmit}
                hasResult={this.props.searchNotIssueResult && this.props.searchNotIssueResult.status === "Success"}
              />
            </Accordion.Collapse>
          </div>
        </Accordion>
        {this.props.searchNotIssueResult && this.props.searchNotIssueResult.status === "Success" &&
          <SearchNotIssueResult
            recordQuan={recordQuan}
            pageSize={chunkSize}
            hasResult={hasResult}
            chunkedBookingRecords={_chunk(bookingDetails, chunkSize)}
            searchNotIssueResult={this.props.searchNotIssueResult}
            currentPage={this.props.currentPage}
            handlePageChange={this.onPageChange}
            handleDetailClick={this.onDetailClick}
            handleCancelBooking={this.onCancelBooking}
          />
        }
      </div>
    );
  }
}


SearchNotIssueForm.propTypes = {
	searchBookingNotIssue: PropTypes.func,
	changeNotIssuePage: PropTypes.func,
	getBookingDetail: PropTypes.func,
	clearBookingSearchNotIssueResult: PropTypes.func,
  cancelBooking: PropTypes.func,
};

function mapStateToProps(state) {  
	return {
		searchNotIssueResult: state.searchnotissue.searchNotIssueResult,
		currentPage: state.searchnotissue.currentPage,
    bookingTxno: state.booking.bookingTxno,
    bookingSeqnum: state.booking.bookingSeqnum,
    searchCriteria: state.searchnotissue.criteria,
	}
}

const mapDispatchToProps = {
	searchBookingNotIssue,
  changeNotIssuePage,
  getBookingDetail,
  clearBookingSearchNotIssueResult,
  batchCancelBooking,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchBookingNotIssuePage));
