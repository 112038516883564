import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Auth from '../Auth/Auth';
import Select from 'react-select';

import _debounce from 'lodash/debounce';
import _map from 'lodash/map';
import _template from 'lodash/template';
const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();


const endpoint = _template(`${currentServerURL}/information/TypeCodeList?keywords=<%= string %>`);
const method = 'GET'

class TypeCodeMultiSelect extends Component {
	constructor(props) {
		super(props);
		this.onSelectInputChange = this.onSelectInputChange.bind(this);
		this.onSelectClick = this.onSelectClick.bind(this);

		this.state = {
			options: [],
			isSearching: false,
			value: [],
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.preValue && this.props.preValue) {
			if (prevProps.preValue.length > 0 && this.props.preValue.length === 0) {
				this.clearOptions()
			}
		}
	}

	onSelectInputChange = _debounce((string, action) => {
		const act = action.action
		if (act === "input-change") {
			if (string) {
				this.setState({
					isSearching: true
				})

		    fetch(endpoint({'string' : string}), {
					method: method,
					headers: {
						Authorization: 'Bearer ' + auth.getAccessToken(),
						'Content-Type': 'application/x-www-form-urlencoded'
					}
				})
					.then(response => response.json())
					.then((result) => {
						let options = _map(result, (r) => ({
							'value': r.typecode,
							'label': r.company_name
						}))
						this.setState({
							options: options,
							isSearching: false
						})

					})
					.catch((error) => {
						console.log('error: ', error)
						this.setState({
							isSearching: false
						})
					});

			} else {
				this.clearOptions()
			}
		}
	 }, 1000)

	onSelectClick(selectedOption) {
		if (selectedOption) {
			this.setState({
				value: selectedOption
			})
			this.props.handleSelect(selectedOption)
		} else { // clear
			this.clearOptions()
		}
	}

	clearOptions() {
		this.setState({
			options: [],
			value: []
		});
		this.props.handleSelect([])
	}

  render() {
		return (
			<Select
				name="typeCodeSelect"
				value={this.state.value}
				onChange={this.onSelectClick}
				options={this.state.options ? this.state.options : []}
				onInputChange={(string, action) => this.onSelectInputChange(string, action)}
				placeholder="Search Company"
				isLoading={this.state.isSearching}
				isMulti
				closeMenuOnSelect={false}
			/>
    );
  }
}

TypeCodeMultiSelect.propTypes = {
	handleSelect: PropTypes.func,
	preValue: PropTypes.array
}

export default TypeCodeMultiSelect;
