import _filter from 'lodash/filter';
import _trim from 'lodash/trim';

export function getStatus(obj) {
  const confirmedLength = _filter(obj, (o) => (o.status === 'HK' || o.status === 'AA')).length
  const confirmLength = _filter(obj, (o) => (o.status === 'KK')).length
  const cancelLength = _filter(obj, (o) => (o.status === 'XX' || o.status === 'UC' || o.status === 'HX')).length
  const requestLength = _filter(obj, (o) => (o.status === 'RQ' || o.status === 'HN')).length

  const statusObj = {
    'confirmed': confirmedLength,
    'confirm': confirmLength,
    'cancel': cancelLength,
    'request': requestLength
  }
  return statusObj
}

export function getFlightDetail(string) {
  if (_trim(string)) {
    let arr = [
      _trim(string.substring(0, 8)),
      string.substring(8, 14),
      string.substring(14, 19)
    ]
    return arr
  } else {
    return ["Flight", "", ""]
  }
}
