import fetch from 'node-fetch';
import Auth from '../Auth/Auth';
import * as ActionTypes from '../constants/ActionTypes';

const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

export function getRosters(criteria) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/Roster/GetRosters`;
    const body = {
      "Criteria": criteria
    }

		dispatch({
			type: ActionTypes.GET_ROSTERS_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.status === "Success") {
          dispatch({
            type: ActionTypes.GET_ROSTERS_SUCCESS,
            payload: result.rosters
          })
        } else{
          dispatch({
  					type: ActionTypes.GET_ROSTERS_FAILURE,
  					payload: result,
            msg: `Get roster failed. <b>${result.message}</b>`
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.GET_ROSTERS_FAILURE,
					error,
					payload: null,
          msg: `Get roster failed. API issue.`
				})
			});
	};
}

export function updateRoster(rosters) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/Roster/Manage/Update`;
    const body = {
      "Rosters": rosters
    }

		dispatch({
			type: ActionTypes.UPDATE_ROSTERS_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.status === "Success") {
          dispatch({
            type: ActionTypes.UPDATE_ROSTERS_SUCCESS,
            msg: `Update roster succeed.`
          })
        } else{
          dispatch({
  					type: ActionTypes.UPDATE_ROSTERS_FAILURE,
  					payload: result,
            msg: `Update roster failed. <b>${result.message}</b>`
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.UPDATE_ROSTERS_FAILURE,
					error,
					payload: null,
          msg: `Update roster failed. API issue.`
				})
			});
	};
}

export function addNewRoster(rosters) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/Roster/Manage/NEW`;
    const body = {
      "Rosters": rosters
    }

		dispatch({
			type: ActionTypes.ADD_ROSTERS_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.status === "Success") {
          dispatch({
            type: ActionTypes.ADD_ROSTERS_SUCCESS,
            msg: `Add roster succeed.`
          })
        } else{
          dispatch({
  					type: ActionTypes.ADD_ROSTERS_FAILURE,
  					payload: result,
            msg: `Add roster failed. <b>${result.message}</b>`
  				})
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.ADD_ROSTERS_FAILURE,
					error,
					payload: null,
          msg: `Add roster failed. API issue.`
				})
			});
	};
}

export function getTeamMemberList(criteria) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/Information/TeamMemberList`;

    dispatch({
      type: ActionTypes.GET_TEAM_MEMBER_REQUEST,
    })

    fetch(endpoint, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
        'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
      }
    })
    .then(response => response.json())
    .then((result) => {
      if (result.length > 0) {
        dispatch({
          type: ActionTypes.GET_TEAM_MEMBER_SUCCESS,
          payload: result
        })
      } else{
        dispatch({
          type: ActionTypes.GET_TEAM_MEMBER_FAILURE,
          payload: result,
          msg: `Get team member failed. <b>${result.message}</b>`
        })
      }
    })
    .catch((error) => {
      dispatch({
        type: ActionTypes.GET_TEAM_MEMBER_FAILURE,
        error,
        payload: null,
        msg: `Get team member failed. API issue.`
      })
    });
  };
}

export function clearRosterSearchResult() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CLEAR_ROSTER_SEARCH_RESULT
    })
  }
}
