import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Auth from '../Auth/Auth';
import Select from 'react-select';

import _trim from 'lodash/trim';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _template from 'lodash/template';
const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

const endpoint = _template(`${currentServerURL}/information/Login/<%= string %>`);
const method = 'GET'

class LoginSelect extends Component {
	constructor(props) {
		super(props);
		this.onSelectClick = this.onSelectClick.bind(this);

		this.state = {
			options: [],
			isSearching: false,
			currentObj: '',
		};
	}

	componentDidMount() {
		if (this.props.selectedTypeCode) {
			this.getLoginLabel(this.props.selectedTypeCode)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.selectedTypeCode !== this.props.selectedTypeCode) {
			this.getLoginLabel(this.props.selectedTypeCode);
		}

		// if (
		// 	// this.props.selectedTypeCode &&
		// 	this.props.selectedLogin &&
		// 	(prevProps.selectedLogin !== this.props.selectedLogin) &&
		// 	(_get(this.state.currentObj, 'value') !== this.props.selectedLogin)
		// ) {
		// 	this.getLoginLabel(this.props.selectedTypeCode)
		// }

		if (prevProps.selectedTypeCode && !this.props.selectedTypeCode) {
			this.clearLoginOptions();
		}
	}

	onSelectClick(selectedOption) {
		if (selectedOption) {
			this.setState({
				currentObj: selectedOption
			})
			this.props.handleSelect(selectedOption.value, this.props.name, this.props.index)
		} else {
			this.clearLoginOptions()
		}
	}

	getLoginLabel(string) {
		if (string) {
			fetch(endpoint({'string' : _trim(string)}), {
				method: method,
				headers: {
					Authorization: 'Bearer ' + auth.getAccessToken(),
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			})
				.then(response => response.json())
				.then((result) => {
					if (result.length !== 0) {

						let options = _map(result, (r) => ({
							'value': _trim(r.login),
							'label': _trim(r.staffname)
						}))

						this.setState({
							options: options,
							currentObj: this.props.selectedLogin ? _find(options, {'value': _trim(this.props.selectedLogin)}) : ''
						})
					}
				})
				.catch((error) => {
					console.log('error: ', error)
				});
			}
	}

	// searchLogin(string) {
	// 	console.log('searchLogin')
	// 	if (string) {
	// 		this.setState({
	// 			isSearching: true,
	// 			currentObj: ''
	// 		})
	//
	//     fetch(endpoint({'string' : _trim(string)}), {
	// 			method: method,
	// 			headers: {
	// 				Authorization: 'Bearer ' + auth.getAccessToken(),
	// 				'Content-Type': 'application/x-www-form-urlencoded'
	// 			}
	// 		})
	// 			.then(response => response.json())
	// 			.then((result) => {
	// 				let options = _map(result, (r) => ({
	// 					'value': r.login,
	// 					'label': r.staffname
	// 				}))
	//         this.setState({
	// 					options: options,
	// 					isSearching: false
	// 				})
	// 			})
	// 			.catch((error) => {
	// 				console.log('fetchLoginList error: ', error)
	// 				this.setState({
	// 					isSearching: false
	// 				})
	// 			});
	//
	// 	} else { //clear
	// 		this.clearLoginOptions()
	// 	}
	// }

	clearLoginOptions() {
		this.setState({
			currentObj: ''
		})
		this.props.handleSelect('', this.props.name, this.props.index)
	}

  render() {
		return (
			<div>
				{this.props.readOnly ?
					<p className="py-2 mb-0">{_get(this.state.currentObj, 'label')}</p> :
					<Select
						name="loginSelect"
						value={this.state.currentObj}
						onChange={this.onSelectClick}
						options={this.state.options}
						isDisabled={!this.props.selectedTypeCode}
						isLoading={this.state.isSearching}
						placeholder="Select User"
						isClearable={this.props.isClearable}
					/>
				}
			</div>
    );
  }
}

LoginSelect.propTypes = {
	handleSelect: PropTypes.func,
	selectedTypeCode: PropTypes.string,
	selectedLogin: PropTypes.string,
	isClearable: PropTypes.bool,
	readOnly: PropTypes.bool,
	index: PropTypes.number,
	name: PropTypes.string,
}

export default LoginSelect;
