import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

const Loading = () => {
	return (
		<div className="loading-wrapper">
		  <div className="loading__icon">
				<FontAwesomeIcon icon={faSpinner} size="3x" spin />
			</div>
		</div>
  );
}

export default Loading;
