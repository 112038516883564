import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, Accordion, Button, Row, Col, Badge, OverlayTrigger, Tooltip} from 'react-bootstrap';

import CustomDatePicker from '../CustomDatePicker';
import CustomDateRangePicker from '../CustomDateRangePicker';
import AveragePriceComponent from '../BookingDetailPage/AveragePriceComponent';
import TypeCodeSelect from '../TypeCodeSelect';
import LoginSelect from '../LoginSelect';
import SupplierCodeSelect from '../SupplierCodeSelect';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt, faAngleDoubleRight, faEdit, faUndo, faExclamation} from '@fortawesome/free-solid-svg-icons';

import {getAge, getTitle, getFirstName, getLastName, noOfAdult, isChild} from '../../util/transformName.js';
import {prettyDateRange, dateFormatToSend, noOfDays} from '../../util/transformDate.js';
import {getFlightDetail} from '../../util/bookingDetailUtil.js';
import {paxTitle, status, itemType} from '../../util/constances.js';

import _get from 'lodash/get';
import _extend from 'lodash/extend';
import _map from 'lodash/map';
import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';
import _split from 'lodash/split';
import _padEnd from 'lodash/padEnd';
import _join from 'lodash/join';
import _pullAt from 'lodash/pullAt';
import _trim from 'lodash/trim';
import _findIndex from 'lodash/findIndex';
import _find from 'lodash/find';
import _remove from 'lodash/remove';

class TabGeneral extends Component {
	constructor(props) {
		super(props);
		this.onAddPaxClick = this.onAddPaxClick.bind(this)
		this.onAddServiceClick = this.onAddServiceClick.bind(this)
		this.onPaxesChange = this.onPaxesChange.bind(this)
		this.onDateSelect = this.onDateSelect.bind(this)
		this.onFieldChange = this.onFieldChange.bind(this)
		this.onFlightChange = this.onFlightChange.bind(this)
		this.onServiceActionChange = this.onServiceActionChange.bind(this)
		this.onTypeCodeSelect = this.onTypeCodeSelect.bind(this)
		this.onSupplierCodeSelect = this.onSupplierCodeSelect.bind(this)
		this.onLoginSelectClick = this.onLoginSelectClick.bind(this)
		this.onRemoveItemClick = this.onRemoveItemClick.bind(this)
		this.onRemovePaxClick = this.onRemovePaxClick.bind(this)
		this.onEditPaxClick = this.onEditPaxClick.bind(this)
		this.onSubmitClick = this.onSubmitClick.bind(this)
		this.onHideCancelClick = this.onHideCancelClick.bind(this)
		this.onAverageChange = this.onAverageChange.bind(this)
		this.onUndoClick = this.onUndoClick.bind(this)

		this.state = {
			form: this.props.bookingDetail.toJS(),
			showTerms: false,
			serviceAction: [],
			flightDetailEta: ['Flight', '', ''],
			flightDetailEtd: ['Flight', '', ''],
			isAmendmentNotes: false,
			isHideCancel: false,
			editedPax: []
		};

	}

	componentDidMount() {
		this.checkFlightDetail();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.flightDetailEta !== this.state.flightDetailEta) {
			this.combineFlight('eta');
		}
		if (prevState.flightDetailEtd !== this.state.flightDetailEtd) {
			this.combineFlight('etd');
		}
		if (prevProps.shouldUpdateBooking && !this.props.shouldUpdateBooking) {
			this.updateBookingDetail();
		}
		if (prevProps.bookingDetail.get('txno') !== this.props.bookingDetail.get('txno') || prevProps.bookingDetail.get('seqnum') !== this.props.bookingDetail.get('seqnum')) {
			this.updateFormDetail();
		}
		if (prevState.form.eta !== this.state.form.eta ||
			prevState.form.etd !== this.state.form.etd) {
			this.checkFlightDetail();
		}


	}

	onPaxesChange(e, i, isChild) {
		let famNameArr = [
			e.target.name === 'lastName' ? e.target.value : getLastName(this.state.form.paxes[i].famName),
			e.target.name === 'firstName' ? e.target.value : getFirstName(this.state.form.paxes[i].famName),
			e.target.name === 'title' ? e.target.value : getTitle(this.state.form.paxes[i].famName),
			e.target.name === 'age' ? e.target.value : getAge(this.state.form.paxes[i].famName)
		]

		let newFamName = []
		if (isChild) { // Adult
			newFamName = famNameArr[0] + '/' + famNameArr[1] + ' ' + famNameArr[2] + ' Age ' + famNameArr[3]
		} else{
			newFamName = famNameArr[0] + '/' + famNameArr[1] + ' ' + famNameArr[2]
		}

		var deepForm = _cloneDeep(this.state.form);
		this.setState({
			form: _set(deepForm, ['paxes', i, 'famName'], newFamName)
		})
	}

	onServiceChange(e, i) {
		const key = e.target.name
		const value = e.target.value

		var deepForm = _cloneDeep(this.state.form);
		this.setState({
			form: _set(deepForm, ['items', i, key], value)
		})

	}

	onPriceChange(e, i) {
		const key = e.target.name
		const value = e.target.value
		const splitKey = _split(key, '-')
		var deepForm = _cloneDeep(this.state.form);

		if(splitKey[1] === 'currencyCode') {
			this.setState({
				form: _set(deepForm, ['items', i, splitKey[0], splitKey[1]], value),
			})
		} else {
			this.setState({
				form: _set(deepForm, ['items', i, splitKey[0], splitKey[1]], value)
			})
		}
	}

	onAverageChange(e, i) {
		const key = e.target.name
		const value = e.target.value
		const price = value * noOfDays(this.state.form.items[i].startDate, this.state.form.items[i].endDate)
		var deepForm = _cloneDeep(this.state.form);

		this.setState({
			form: _set(deepForm, ['items', i, [key], 'value'], price)
		})
	}

	onFieldChange(e) {
		this.setState({
			form: _extend(this.state.form, {
				[e.target.name]: e.target.value
			})
		})
	}

	onFlightChange(e, index) {
		if (e.target.name === 'eta') {
			var deepEta = _cloneDeep(this.state.flightDetailEta);
			this.setState({
				flightDetailEta: _set(deepEta, [index], e.target.value)
			})
		} else if (e.target.name === 'etd') {
			var deepEtd = _cloneDeep(this.state.flightDetailEtd);
			this.setState({
				flightDetailEtd: _set(deepEtd, [index], e.target.value)
			})
		}
	}

	onDateSelect(name, i, startDateValue, endDateValue) {
		if(name === "deadline") {
			this.setState({
				form: _extend(this.state.form, {
					"deadline": dateFormatToSend(startDateValue)
				})
			})
		} else if (name === "itemDate") {
			var deepForm = _cloneDeep(this.state.form);
			_set(deepForm, ['items', i, 'startDate'], dateFormatToSend(startDateValue))
			_set(deepForm, ['items', i, 'endDate'], dateFormatToSend(endDateValue))

			this.setState({
				form: deepForm,
			})

		}
	}

	onServiceActionChange(e, i) {
		let str;
		let serviceActionArr
		if (e.target.value) {
			str = e.target.value
			serviceActionArr = this.state.serviceAction.filter(arr => arr.id !== i).concat({id: i, value: str})
		} else {
			str = this.props.bookingDetail.getIn(['items', i, 'status'])
			serviceActionArr = this.state.serviceAction.filter(arr => arr.id !== i)

		}

		var deepForm = _cloneDeep(this.state.form);
		_set(deepForm, ['items', i, 'status'], str)

		this.setState({
			form: deepForm,
			serviceAction: serviceActionArr
		})
	}

	onTypeCodeSelect(typeCode, loginCode) {
		this.setState({
			form: _extend(this.state.form, {
				'typeCode': typeCode,
				'loginCode': loginCode
			})
		})
	}

	onSupplierCodeSelect(code) {
		this.setState({
			form: _extend(this.state.form, {
				'operatorCode': code
			})
		})
	}

	onLoginSelectClick(login) {
		this.setState({
			form: _extend(this.state.form, {
				'loginCode': login
			})
		})
	}

	onRemoveItemClick(i) {
		var deepForm = _cloneDeep(this.state.form);
		_pullAt(deepForm.items, [i]);

		this.setState({
			form: deepForm
		})
	}

	onRemovePaxClick(i) {
		var deepForm = _cloneDeep(this.state.form);
		_pullAt(deepForm.paxes, [i]);

		this.setState({
			form: deepForm
		})
	}

	onEditPaxClick(paxNum) {
		this.setState({
			editedPax: this.state.editedPax.concat(paxNum)
		})
	}

	onUndoClick(num) {
		var cloneEditedPax = _cloneDeep(this.state.editedPax);
		_remove(cloneEditedPax, v => v === num)

		var deepForm = _cloneDeep(this.state.form);
		const originalName = _find(this.props.bookingDetail.toJS().paxes, pax => pax.paxNum === num).famName
		console.log('originalName', originalName)


		this.setState({
			editedPax: cloneEditedPax,
			form: _set(deepForm, ['paxes', _findIndex(this.state.form.paxes, pax => pax.paxNum === num), 'famName'], originalName)
		})
	}

	onSubmitClick() {
		if (!this.state.form.amendNote) {
			this.setState({
				isAmendmentNotes: true
			})
			window.scrollTo(0,document.body.scrollHeight);
			this.amendNoteInput.focus();
		} else {
			this.setState({
				isAmendmentNotes: false
			})
			this.props.handleSubmit(this.state.form);
		}
	}

	onAddPaxClick(isChild) {
		let newPaxName = ""

		var deepForm = _cloneDeep(this.state.form);
		let row = deepForm.paxes

		if (isChild) {
			newPaxName = '/  MR Age '
		} else{
			newPaxName = '/  MR'
		}

		row.push({
			paxNum: 'newPax',
			famName: newPaxName
		})

		this.setState({
			form: deepForm,
			editedPax: this.state.editedPax.concat('newPax')
		})
	}

	onAddServiceClick() {
		var item = {
      "roomSeqNum": '',
      "status": "RQ",
      "itemName": "",
      "itemType": "R",
      "itemTypeDesc": "",
      "roomCode": "",
      "startDate": "",
      "endDate": "",
      "qty": 1,
      "sell": {
          "currencyCode": "HKD",
          "value": ''
      },
      "cost": {
          "currencyCode": "HKD",
          "value": ''
      },
      "engRefNo": "",
      "itemName_ZhTW": "",
      "itemName_ZhCN": ""
		}

		var deepForm = _cloneDeep(this.state.form);
		let itemRow = deepForm.items
		itemRow.push(item)

		this.setState({
			form: deepForm,
		})
	}

	onKeyDown(e) {
		if (e.key === 'Enter') {
			this.onSubmitClick()
		}
	}

	onHideCancelClick() {
		this.setState({
			isHideCancel: !this.state.isHideCancel
		})
	}

	updateBookingDetail() {
		this.setState({
			form: this.props.bookingDetail.toJS(),
			serviceAction: [],
			editedPax: []
		})
	}

	combineFlight(key) {
		if (key === 'eta') {
			if(this.state.flightDetailEta[1] || this.state.flightDetailEta[2]) {
				this.setState({
					form: _extend(this.state.form, {
						eta: _join([
							_padEnd(this.state.flightDetailEta[0], 8),
							_padEnd(this.state.flightDetailEta[1], 6),
							this.state.flightDetailEta[2]
						], '')
					})
				})
			} else if (!this.state.flightDetailEta[1] && !this.state.flightDetailEta[2]) {
				this.setState({
					form: _extend(this.state.form, {
						eta: ""
					})
				})
			}
		} else if (key === 'etd') {
			if(this.state.flightDetailEtd[1] || this.state.flightDetailEtd[2]) {
				this.setState({
					form: _extend(this.state.form, {
						etd: _join([
							_padEnd(this.state.flightDetailEtd[0], 8),
							_padEnd(this.state.flightDetailEtd[1], 6),
							this.state.flightDetailEtd[2]
						], '')
					})
				})
			} else if (!this.state.flightDetailEtd[1] && !this.state.flightDetailEtd[2]) {
				this.setState({
					form: _extend(this.state.form, {
						etd: ""
					})
				})
			}
		}
	}

	checkFlightDetail() {
		this.setState({
			flightDetailEta: getFlightDetail(this.state.form.eta),
			flightDetailEtd: getFlightDetail(this.state.form.etd)
		})
	}

	updateFormDetail() {
		if (this.props.bookingDetail) {
			this.setState({
				form: this.props.bookingDetail.toJS(),
				editedPax: []
			})
		}
	}

	render() {
		const bookingDetail = this.state.form
		const noOfChild = bookingDetail.paxes.length - noOfAdult(bookingDetail.paxes)
		const hasCancel = this.props.bookingDetail.get('items').map(item => item.get('status')).includes('XX') || this.props.bookingDetail.get('items').map(item => item.get('status')).includes('UC')

		return (
			<div>
				<div onKeyDown={(e) => this.onKeyDown(e)}>
					<div className="border-bottom px-2 py-4">
				    <div className="row p-1">
				      <div className="col-2 detail-label py-2">
				        Guest
				      </div>
				      <div className="col">
				        <div className="small mb-2">
									{noOfAdult(bookingDetail.paxes)} Adult {noOfChild !== 0 ? `- ${noOfChild} Child` : ''}
									{_get(bookingDetail, ['extraInfo', "roomCapacity"]) &&

											<OverlayTrigger
												key="right"
												placement="right"
												overlay={
													<Tooltip id="tooltip-top" className="service-detail__tooltip">
														<div className="text-left">
															<div className="font-weight-bold">
																Room Capacity
															</div>
															<div>
																{_get(bookingDetail, ['extraInfo', "roomCapacity", "adult"])} Adults
															</div>
															<div>
																{_get(bookingDetail, ['extraInfo', "roomCapacity", "child"])} Child (Ages: {_get(bookingDetail, ['extraInfo', "roomCapacity", "childAges"]) ?  _join(_get(bookingDetail, ['extraInfo', "roomCapacity", "childAges"]), ', ') : '-'})
															</div>
														</div>
													</Tooltip>
												}
											>
												<div className="inline text-info px-2 border rounded-circle border-info"><FontAwesomeIcon icon={faExclamation} /></div>
											</OverlayTrigger>

									}
								</div>
				        <table className="table table-bordered mb-0">
				          <thead>
				            <tr className="small">
				              <th width="43">#</th>
				              <th width='23%'>Last Name</th>
				              <th width='23%'>First Name</th>
				              <th>Title</th>
				              <th>Age(yr)</th>
				              <th width="229"></th>
				            </tr>
				          </thead>
				          <tbody>
										{_map(bookingDetail.paxes, (pax, i) =>

												this.state.editedPax.find(num => num === pax.paxNum || num.paxNum === 'newPax') ?
													<tr key={i}>
														<td>{i + 1}</td>
														<td>
															{this.state.editedPax.find(num => num === pax.paxNum || num.paxNum === 'newPax') ?
																<Form.Control
																	type="text"
																	value={getLastName(pax.famName)}
																	name="lastName"
																	autoComplete="new-password"
																	onChange={(e) => this.onPaxesChange(e, i, isChild(pax.famName))}
																/>:
																getLastName(pax.famName)
															}
														</td>
														<td>
															{this.state.editedPax.find(num => num === pax.paxNum) ?
																<Form.Control
																	type="text"
																	value={getFirstName(pax.famName)}
																	name="firstName"
																	autoComplete="new-password"
																	onChange={(e) => this.onPaxesChange(e, i, isChild(pax.famName))}
																/>:
																getFirstName(pax.famName)
															}
														</td>
														<td>
															{this.state.editedPax.find(num => num === pax.paxNum) ?
																<Form.Control
																	as="select"
																	name="title"
																	value={getTitle(pax.famName)}
																	onChange={(e) => this.onPaxesChange(e, i, isChild(pax.famName))}
																>
																	{_map(paxTitle, (title, i)=>
																		<option key={i} value={title}>{title}</option>
																	)}
																</Form.Control>:
																getTitle(pax.famName)
															}
														</td>
														<td>
															{this.state.editedPax.find(num => num === pax.paxNum) ?
																isChild(pax.famName) &&
																	<Form.Control
																		type="text"
																		value={(isChild(pax.famName)) ? getAge(pax.famName) : ''}
																		className="form-control--short"
																		name="age"
																		onChange={(e) => this.onPaxesChange(e, i, isChild(pax.famName))}
																	/>
																:
																getAge(pax.famName)
															}
														</td>
														<td className="text-center">
															{pax.paxNum !== 'newPax' &&
																<Button
																	variant="outline-secondary"
																	className="mr-2"
																	onClick={() => this.onUndoClick(pax.paxNum)}
																><FontAwesomeIcon icon={faUndo} className="mr-2" />Undo</Button>
															}
															<Button
																variant="secondary"
																onClick={() => this.onRemovePaxClick(i)}
																disabled={this.state.form.paxes.length === 1}
															><FontAwesomeIcon icon={faTrashAlt} className="mr-2" />Remove</Button>
							              </td>
													</tr>:
													<tr key={i}>
														<td>{i + 1}</td>
														<td colSpan="4">
															{pax.famName}
														</td>
														<td className="text-center">
															{pax.paxNum &&
																<Button
																	variant="secondary"
																	onClick={() => this.onEditPaxClick(pax.paxNum)}
																	className="mr-2"
																><FontAwesomeIcon icon={faEdit} className="mr-2" />Edit</Button>
															}
															<Button
																variant="secondary"
																onClick={() => this.onRemovePaxClick(i)}
																disabled={this.state.form.paxes.length === 1}
															><FontAwesomeIcon icon={faTrashAlt} className="mr-2" />Remove</Button>
							              </td>
													</tr>
										)}
				            <tr>
				              <td className="text-center" colSpan="6">
				                <button className="btn btn-outline-info" onClick={() => this.onAddPaxClick(false)}>+ Add Adult</button>
				                <button className="btn btn-outline-info ml-3" onClick={() => this.onAddPaxClick(true)}>+ Add Child</button>
				              </td>
				            </tr>
				          </tbody>
				        </table>
				      </div>
				    </div>
						<div className="row p-1">
							<div className="col-2 detail-label py-2">
								Arr-Dep
							</div>
							<div className="col-4 py-2">
								{prettyDateRange(this.props.bookingDetail.get('arr'), this.props.bookingDetail.get('dep'))}
							</div>
							<div className="col-2 detail-label py-2">
								Cxl deadline
							</div>
							<div className="col-4">
								<CustomDatePicker
									handleSelect={this.onDateSelect}
									name="deadline"
									value={_get(bookingDetail, 'deadline')}
								/>
							</div>
						</div>
						<div className="row p-1">
							<div className="col-2 detail-label py-2">
								Booking owner
							</div>
							{this.props.isWBooking ?
								<div className="col-4 py-2">{bookingDetail.companyName}{bookingDetail.staffname && ` / ${bookingDetail.staffname}`}</div> :
								<div className="col-4">
									<Row className="mb-1">
										<Col>
											<TypeCodeSelect
												handleSelect={this.onTypeCodeSelect}
												selectedTypeCode={bookingDetail.typeCode}
											/>
										</Col>
									</Row>
									<Row>
										<Col xs="1" className="pt-1 pr-0 text-right">
											<span>/</span>
										</Col>
										<Col xs="11">
											<LoginSelect
												handleSelect={this.onLoginSelectClick}
												selectedTypeCode={bookingDetail.typeCode}
												selectedLogin={bookingDetail.loginCode}
											/>
										</Col>
									</Row>
								</div>
							}

							<div className="col-2 detail-label py-2">
								Booking owner email
							</div>
							<div className="col-4 py-2">
								{_get(bookingDetail, 'bookingOwnerEmail')}
							</div>
						</div>
						<div className="row p-1">
							<div className="col-2 detail-label py-2">
								Agent Name
							</div>
							<div className="col-4 py-2">
								{_get(bookingDetail, ['agentInfo', 'agentCode'])}{_get(bookingDetail, ['agentInfo', 'agentName']) ? ` - ${_get(bookingDetail, ['agentInfo', 'agentName'])}` : ''}
							</div>
							<div className="col-2 detail-label py-2">
								Supplier code
							</div>
							<div className="col-4">
								<SupplierCodeSelect
									handleSelect={this.onSupplierCodeSelect}
									selectedSupplierCode={bookingDetail.operatorCode}
									isClearable={!_trim(this.props.bookingDetail.get('operatorCode'))}
								/>
							</div>
						</div>
						<div className="row p-1">
							<div className="col-2 detail-label py-2">
								Create By
							</div>
							<div className="col-4 py-2">
								{_get(bookingDetail, 'createBy')}
							</div>
							<div className="col-2 detail-label py-2">
								Booking confirmation email
							</div>
							<div className="col-4 py-2">
								{_get(bookingDetail, 'bookingConfirmationEmail')}
							</div>
						</div>
						<div className="row p-1">
							<div className="col-2 detail-label py-2">
								Tel
							</div>
							<div className="col-4 py-2">
								{_get(bookingDetail, 'agentContact')}
							</div>
						</div>
						<div className="row p-1">
							<div className="col-2 detail-label py-2">
								Terms
							</div>
							<div className="col-10">
								<Accordion defaultActiveKey="0">
									<Accordion.Toggle as={Button} variant="outline-info" eventKey="0" onClick={() => this.setState({showTerms : !this.state.showTerms})}>
										Show Terms
									</Accordion.Toggle>
									<Accordion.Collapse in={this.state.showTerms} eventKey="0">
										<div className="py-4">
											<h6 className="card-header">Period of Stay:</h6>
											<div className="card-body">
												{prettyDateRange(_get(bookingDetail, ['termsByBkgs', 0, 'startDate']), _get(bookingDetail, ['termsByBkgs', 0, 'endDate']))}
											</div>

											<h6 className="card-header">Cancellation Policy:</h6>
											<div className="card-body">
												<div dangerouslySetInnerHTML={{__html: _get(bookingDetail, ['termsByBkgs', 0, 'cxlPolicy'])}} />
											</div>

											<h6 className="card-header">Notes:</h6>
											<div className="card-body pre-line">
												<div dangerouslySetInnerHTML={{__html: _get(bookingDetail, ['termsByBkgs', 0, 'additionalNotes'])}} />
											</div>
										</div>
			            </Accordion.Collapse>
								</Accordion>
							</div>
						</div>
				  </div>
					<div className="border-bottom px-2 py-4">
				    <div className="row p-1">
				      <div className="col-2 detail-label py-2">
				        Service Details
								{hasCancel &&
									<div className="mt-1">
										<button className="btn btn-sm btn-link px-0" onClick={() => this.onHideCancelClick()}>{this.state.isHideCancel ? 'Show' : 'Hide'} UC/XX</button>
									</div>
								}
				      </div>
				      <div className="col">
				        <table className="table table-bordered mb-0 table-striped">
				          <thead>
				            <tr className="small">
				              <th width="40">#</th>
				              <th colSpan={4}></th>
				            </tr>
				          </thead>
									<tbody >
										{_map(bookingDetail.items, (item, i) =>
											{
												const isConfirm = this.props.bookingDetail.getIn(['items', i, 'status']) === 'HK'
												const isCancel = this.props.bookingDetail.getIn(['items', i, 'status']) === 'XX' ||
																				this.props.bookingDetail.getIn(['items', i, 'status']) === 'UC' ||
																				this.props.bookingDetail.getIn(['items', i, 'status']) === 'HX'
												const isRequest = this.props.bookingDetail.getIn(['items', i, 'status']) === 'RQ' ||
													this.props.bookingDetail.getIn(['items', i, 'status']) === 'HN'
												const staObj = _find(status, (sta) => sta.code.includes(this.props.bookingDetail.getIn(['items', i, 'status'], 'RQ')))
												return (
													<tr key={i} className={this.state.isHideCancel && isCancel ? 'hidden' : ''}>
														<td >{i + 1}</td>
														<td className="pb-0 pt-2">
															<div className="row pb-2 border-bottom">
																<Col xs={'2'} className="border-right">
																	{this.props.bookingDetail.getIn(['items', i, 'roomSeqNum']) ?
																		<div className="pt-2">{this.props.bookingDetail.getIn(['items', i, 'itemTypeDesc'])}</div> :
																		<Form.Control
																			as="select"
																			value={item.itemType}
																			name="itemType"
																			onChange={(e) => this.onServiceChange(e, i)}
																		>
																			{_map(itemType, (type)=>
																				<option key={type.value} value={type.value}>{type.key}</option>
																			)}
																		</Form.Control>
																	}
																</Col>
																<Col xs={'auto'}>
																	<Form.Control
																		type="text"
																		value={item.qty}
																		name="qty"
																		className="form-control--short"
																		onChange={(e) => this.onServiceChange(e, i)}
																	/>
																</Col>
																<Col>
																	<span className="slash">x</span>
																	{this.props.bookingDetail.getIn(['items', i, 'roomSeqNum']) ?
																		<p className="service-detail__desc">{item.itemName}</p> :
																		<Form.Control
																			type="text"
																			value={item.itemName}
																			name="itemName"
																			onChange={(e) => this.onServiceChange(e, i)}
																			placeholder="Item Name"
																		/>
																	}
																</Col>
															</div>

															<div className="row pt-2">
																<Col xs={'auto'} className="mb-2">
																	<Form.Label className="mr-2 mt-0 small font-weight-bold">Status</Form.Label>
																	<Badge variant={staObj.variant} className="text-normal">{this.props.bookingDetail.getIn(['items', i, 'status'], 'RQ')}</Badge>
																	{this.props.bookingDetail.getIn(['items', i, 'roomSeqNum']) &&
																		<div className="inline">
																			<FontAwesomeIcon icon={faAngleDoubleRight} className="mx-2 text-secondary" size="sm" />
																			<Form.Control
																				as="select"
																				className={`custom-select-sm form-control-inline service-status-${_get(this.state.serviceAction, [_findIndex(this.state.serviceAction, ['id', i]), 'value'], '')} mr-0`}
																				name="status"
																				onChange={(e) => this.onServiceActionChange(e, i)}
																				ref={ref => this.statusEl = ref}
																				value={_get(this.state.serviceAction, [_findIndex(this.state.serviceAction, ['id', i]), 'value'], '')}
																			>
																				<option value=''>Change to...</option>
																				<option value='HK' disabled={isConfirm} className="option-confirm">Confirm</option>
																				<option value='XX' disabled={isCancel} className="option-cancel">Cancel</option>
																				<option value='RQ' disabled={isRequest} className="option-request">Request</option>
																			</Form.Control>
																		</div>
																	}
																</Col>
																<Col xs={'auto'} className="mb-2">
																	<div className="form-inline">
																		<Form.Label className="mr-2 mt-0 small font-weight-bold">Date</Form.Label>
																		<CustomDateRangePicker
																			handleSelect={this.onDateSelect}
																			name="itemDate"
																			startDateValue={item.startDate}
																			endDateValue={item.endDate}
																			index={i}
																			isSmall
																		/>
																	</div>
																</Col>

																<Col xs={'auto'} className=" mb-2">
																	<div className="form-inline">
															      <Form.Label className="mr-2 mt-0 small font-weight-bold">Selling</Form.Label>

																		<Form.Control
																			as="select"
																			className="custom-select-sm mr-1"
																			value={item.sell.currencyCode}
																			name="sell-currencyCode"
																			onChange={(e) => this.onPriceChange(e, i)}
																		>
																			{this.props.currencyList && _map(this.props.currencyList, (cur, i) =>
																				<option key={i} value={cur}>{cur}</option>
																			)}
															      </Form.Control>

																		<Form.Control
																			type="text"
																			onChange={(e) => this.onPriceChange(e, i)}
																			name="sell-value"
																			value={item.sell.value}
																			className="form-control-sm form-control--w70 mr-2"
																			min="0"
																		/>

																		<AveragePriceComponent
																			item={item}
																			i={i}
																			name="sell"
																			handleAvgPrivceChange={this.onAverageChange}
																		/>

															    </div>
																</Col>

																<Col xs={'auto'} className="mb-2">
																	<div className="form-inline">
															      <Form.Label className="mr-2 mt-0 small font-weight-bold">Net</Form.Label>

																		<Form.Control
																			as="select"
																			className="custom-select-sm mr-1 red"
																			value={item.cost.currencyCode}
																			name="cost-currencyCode"
																			onChange={(e) => this.onPriceChange(e, i)}
																		>
																			{this.props.currencyList && _map(this.props.currencyList, (cur, i) =>
																				<option key={i} value={cur}>{cur}</option>
																			)}
																		</Form.Control>

																		<Form.Control
																			type="text"
																			onChange={(e) => this.onPriceChange(e, i)}
																			name="cost-value"
																			value={item.cost.value}
																			className="form-control-sm form-control--w70 mr-2 red"
																			min="0"
																		/>

																		<AveragePriceComponent
																			item={item}
																			i={i}
																			name="cost"
																			handleAvgPrivceChange={this.onAverageChange}
																		/>
															    </div>
																</Col>

																{!this.props.bookingDetail.getIn(['items', i, 'roomSeqNum']) &&
																	<Col xs={'auto'} className="ml-auto text-right mb-2">
																		<Button
																			variant="secondary"
																			size="sm"
																			onClick={() => this.onRemoveItemClick(i)}
																		><FontAwesomeIcon icon={faTrashAlt} /> Remove</Button>
																	</Col>
																}
															</div>
														</td>
													</tr>
												)
											}
										)}
				            <tr className="bg-white">
				              <td className="text-center" colSpan="6">
				                <button className="btn btn-outline-info" id="add-guest-adult" onClick={() => this.onAddServiceClick()}>+ Add Item</button>
				              </td>
				            </tr>
				          </tbody>
				        </table>
				      </div>
				    </div>
						<div className="row p-1">
							<div className="col-2 detail-label py-2">
								Total Selling
							</div>
							<div className="col-4 py-2">
								{_get(bookingDetail, ['sell', 'currencyCode'])} {_get(bookingDetail, ['sell', 'value'])}
							</div>
							<div className="col-2 detail-label py-2">
								Total Profit
							</div>
							<div className="col-4 py-2">
								{_get(bookingDetail, ['profit', 'currencyCode'])} {_get(bookingDetail, ['profit', 'value'])}
							</div>
						</div>
						<div className="row p-1">
							<div className="col-2 detail-label py-2">
								Hotel confirmed by
							</div>
							<div className="col-4">
								<Form.Control
									type="text"
									value={_get(bookingDetail, 'confirmedBy')}
									name="confirmedBy"
									onChange={(e) => this.onFieldChange(e)}
								/>
							</div>
							<div className="col-2 detail-label py-2">
								Hotel reference
							</div>
							<div className="col-4">
								<Form.Control
									type="text"
									value={_get(bookingDetail, 'hotelRef')}
									name="hotelRef"
									onChange={(e) => this.onFieldChange(e)}
								/>
							</div>
						</div>
						<div className="row p-1">
							<div className="col-2 detail-label py-2">
								Other Reference
							</div>
							<div className="col-4">
								<Form.Control
									type="text"
									value={_get(bookingDetail, 'otherRef')}
									name="otherRef"
									onChange={(e) => this.onFieldChange(e)}
								/>
							</div>
							<div className="col-2 detail-label py-2">
								Supplier Reference
							</div>
							<div className="col-4 py-2">
								{_get(bookingDetail, 'supplierReference')}
							</div>
						</div>
						<div className="row p-1">
							<div className="col-2 detail-label py-2">
								Arrival Details
							</div>
							<div className="col-4">
								<div className="row">
									<div className='col'>
										<Form.Control
											as="select"
											onChange={(e) => this.onFlightChange(e, 0)}
											name="eta"
											value={this.state.flightDetailEta[0]}
										>
											<option>Flight</option>
											<option>Ferry</option>
											<option>Rail</option>
											<option>Surface</option>
										</Form.Control>
									</div>
									<div className='col'>
										<Form.Control
											type="text"
											onChange={(e) => this.onFlightChange(e, 1)}
											name="eta"
											value={this.state.flightDetailEta[1]}
											maxLength="6"
											placeholder="eg. CX123"
										/>
									</div>
									<div className='col'>
										<Form.Control
											type="text"
											onChange={(e) => this.onFlightChange(e, 2)}
											name="eta"
											value={this.state.flightDetailEta[2]}
											maxLength="5"
											placeholder="eg. 1200"
										/>
									</div>
								</div>
							</div>
							<div className="col-2 detail-label py-2">
								Departure Details
							</div>
							<div className="col-4">
								<div className="row">
									<div className='col'>
										<Form.Control as="select" onChange={(e) => this.onFlightChange(e, 0)} name="etd" value={this.state.flightDetailEtd[0]}>
											<option>Flight</option>
											<option>Ferry</option>
											<option>Rail</option>
											<option>Surface</option>
										</Form.Control>
									</div>
									<div className='col'>
										<Form.Control
											type="text"
											onChange={(e) => this.onFlightChange(e, 1)}
											name="etd"
											value={this.state.flightDetailEtd[1]}
											maxLength="6"
											placeholder="eg. CX123"
										/>
									</div>
									<div className='col'>
										<Form.Control
											type="text"
											onChange={(e) => this.onFlightChange(e, 2)}
											name="etd"
											value={this.state.flightDetailEtd[2]}
											maxLength="5"
											placeholder="eg. 1200"
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="row p-1">
							<div className="col-2 detail-label py-2">
								Special Request
							</div>
							<div className="col-10 py-2">
								{!!this.props.bookingDetail.get('specialRequest') &&
									<ul>
										{_map(bookingDetail.specialRequest, (req, i) =>
											<li key={i}>{req}</li>
										)}
									</ul>
								}
							</div>
						</div>
						<div className="row p-1">
							<div className="col-2 detail-label py-2">
								Booking Remarks
							</div>
							<div className="col-10">
								<Form.Control
									type="text"
									onChange={(e) => this.onFieldChange(e)}
									name="note1"
									value={_get(bookingDetail, 'note1') || ''}
									className="mb-2"
								/>
								<Form.Control
									type="text"
									onChange={(e) => this.onFieldChange(e)}
									name="note2"
									value={_get(bookingDetail, 'note2') || ''}
								/>
							</div>
						</div>
					</div>

			    <div className="p-4">
		        <div className="row justify-content-center">
	            <div className="col-3 text-center">
	              <p>Amendment Notes</p>
								<Form.Control
									type="text"
									name="amendNote"
									value={bookingDetail.amendNote || ''}
									onChange={(e) => this.onFieldChange(e)}
									isInvalid={this.state.isAmendmentNotes}
									ref={(input) => { this.amendNoteInput = input; }}
								/>

								<Button
									variant="info"
									className="mt-5"
									type="button"
									size="lg"
									onClick={() => this.onSubmitClick()}
								>Submit</Button>
	            </div>
		        </div>
			    </div>
		    </div>
			</div>
	  );
	}
}

TabGeneral.propTypes = {
	bookingDetail: PropTypes.object,
	handleSubmit: PropTypes.func,
	shouldUpdateBooking: PropTypes.bool,
	isWBooking: PropTypes.bool,
	currencyList: PropTypes.array,
};

export default TabGeneral;
