import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Auth from '../Auth/Auth';
import Select from 'react-select';

import _debounce from 'lodash/debounce';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _template from 'lodash/template';
const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

const endpoint = _template(`${currentServerURL}/information/TypeCodeList?keywords=<%= string %>`);
const method = 'GET'

class TypeCodeSelect extends Component {
	constructor(props) {
		super(props);
		this.onSelectInputChange = this.onSelectInputChange.bind(this);
		this.onSelectClick = this.onSelectClick.bind(this);

		this.state = {
			options: [],
			isSearching: false,
			currentObj: '',
		};

	}

	componentDidMount() {
		if (this.props.selectedTypeCode) {
			this.getTypeCodeLabel(this.props.selectedTypeCode)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.selectedTypeCode !== this.props.selectedTypeCode) {
			console.log('changed')
			this.getTypeCodeLabel(this.props.selectedTypeCode)
		}
	}

	getTypeCodeLabel(string) {
		if (string) {
			fetch(endpoint({'string' : string}), {
				method: method,
				headers: {
					Authorization: 'Bearer ' + auth.getAccessToken(),
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			})
				.then(response => response.json())
				.then((result) => {
					if (result.length !== 0) {
						let options = _map(result, (r) => ({
							'value': r.typecode,
							'label': r.company_name
						}))
						this.setState({
							currentObj: _find(options, {'value': string})
						})
					}
				})
				.catch((error) => {
					console.log('error: ', error)
				});
		} else {
			console.log('no')
			this.clearLoginOptions()
		}
	}

	onSelectInputChange = _debounce((string, action) => {
		const act = action.action
		if (act === "input-change") {
			if (string) {
				this.setState({
					isSearching: true
				})

		    fetch(endpoint({'string' : string}), {
					method: method,
					headers: {
						Authorization: 'Bearer ' + auth.getAccessToken(),
						'Content-Type': 'application/x-www-form-urlencoded'
					}
				})
					.then(response => response.json())
					.then((result) => {
						let options = _map(result, (r) => ({
							'value': r.typecode,
							'label': r.company_name
						}))
						this.setState({
							options: options,
							isSearching: false
						})

					})
					.catch((error) => {
						console.log('error: ', error)
						this.setState({
							isSearching: false
						})
					});

			} else {
				this.clearLoginOptions()
			}
		}
	 }, 1000)

	onSelectClick(selectedOption) {
		if (selectedOption) {
			this.props.handleSelect(selectedOption.value, '', this.props.name, this.props.index)
			this.setState({
				currentObj: selectedOption
			})
		} else { // clear
			this.clearLoginOptions()
		}
	}

	clearLoginOptions() {
		this.setState({
			options: [],
			currentObj: ''
		});
		this.props.handleSelect('', '', this.props.name, this.props.index)
	}

  render() {
		console.log(this.props.selectedTypeCode)
		return (
			<div>
				{this.props.readOnly ?
					<p className="py-2 mb-0">{_get(this.state.currentObj, 'label')}</p> :
					<Select
						name="typeCodeSelect"
						value={this.state.currentObj}
						onChange={this.onSelectClick}
						options={this.state.options ? this.state.options : []}
						onInputChange={(string, action) => this.onSelectInputChange(string, action)}
						placeholder="Search Company"
						isClearable={this.props.isClearable}
						isLoading={this.state.isSearching}
					/>
				}
			</div>
    );
  }
}

TypeCodeSelect.propTypes = {
	isClearable: PropTypes.bool,
	handleSelect: PropTypes.func,
	selectedTypeCode: PropTypes.string,
	readOnly: PropTypes.bool,
	index: PropTypes.number,
	name: PropTypes.string,
}

export default TypeCodeSelect;
