import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, Button} from 'react-bootstrap';
import ROPreviewModal from '../BookingDetailPage/ROPreviewModal';
import ROHistoryToSelect from '../BookingDetailPage/ROHistoryToSelect';
import Auth from '../../Auth/Auth';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import JoditEditor from "jodit-react";

import _extend from 'lodash/extend';
import _find from 'lodash/find';
import _padEnd from 'lodash/padEnd';
import _split from 'lodash/split';
import _map from 'lodash/map';
import _trim from 'lodash/trim';

const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

class TabSendRo extends Component {
	constructor(props) {
		super(props);
		this.onTypeClick = this.onTypeClick.bind(this)
		this.onFieldChange = this.onFieldChange.bind(this)
		this.onFormChange = this.onFormChange.bind(this)
		this.onResetClick = this.onResetClick.bind(this)
		this.onPreviewClick = this.onPreviewClick.bind(this)
		this.closeModal = this.closeModal.bind(this)
		this.onSendClick = this.onSendClick.bind(this)
		this.onHeaderTypeChange = this.onHeaderTypeChange.bind(this)
		this.onEmailSelect = this.onEmailSelect.bind(this)
		this.onEditorStateChange = this.onEditorStateChange.bind(this)

		this.state = {
			form: {
				to: this.props.bookingDetail.get('roRsvnEmail') || '',
				from: '',
				cc: '',
				content: '',
			},
			itineraryType: 'RO', // default Hotel RO
			headerType: '',
			roContent: '',
			roHeader: '',
			isModalOpen: false,
			isLoading: false,
			loadingContent: false
		};

	}

	componentDidMount() {
		if (!this.props.roHeader) {
			this.props.handleGetRoHeader(this.props.bookingDetail.get('txno'), this.props.bookingDetail.get('seqnum'));
		}

		if (this.props.roHeader && !this.state.roHeader) {
			// change tab
			this.getHeader();
		}

		this.getRoContent('RO');

	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.roHeader && this.props.roHeader) {
			this.getHeader();
		}

		// if (prevState.roContent !== this.state.roContent) {
		// 	this.setEmailContent()
		// }
	}

	onTypeClick(type) {
		this.setState({
			itineraryType: type,
			loadingContent: true,
		})
		this.getRoContent(type);
	}

	onFieldChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onHeaderTypeChange(e) {
		let headerObj = _find(this.props.roHeader, ['heading_Name', e.target.value]);

		this.setState({
			headerType: e.target.value,
			roHeader: headerObj.heading
		})
	}

	onFormChange(e) {
		let key = e.target.name
		let value = e.target.value

		this.setState({
			form: _extend(this.state.form, {
				[key]: value
			})
		})
	}

	onResetClick() {
		this.setState({
			form: {
				to: this.props.bookingDetail.get('roRsvnEmail') || '',
				from: '',
				cc: '',
				content: this.state.roContent,
			}
		})
	}

	onPreviewClick() {
		this.setState({
			isModalOpen: true
		})
	}

	onSendClick(isWysiwyg) {
		this.props.handleSendRO(
			this.props.bookingDetail.get('txno'),
			this.props.bookingDetail.get('seqnum'),
			{
				to: this.state.form.to,
				from: this.state.form.from,
				cc: this.state.form.cc,
				content: isWysiwyg ? '<html><body>' + this.state.form.content + '</body></html>' : this.state.roHeader.concat('\n', this.state.form.content),
			}
		);
	}

	onEmailSelect(emailValue) {
		this.setState({
			form: _extend(this.state.form, {
				to: emailValue
			})
		})
	}

	onEditorStateChange(content) {
	this.setState({
		form: _extend(this.state.form, {
			'content': content
		})
	})

	}

	getRoContent(itineraryType) {
		const endpoint = `${currentServerURL}/RO/GetContent`;
		const body = {
      "BkgRef" : this.props.bookingDetail.get('txno'),
      "SegNum" : this.props.bookingDetail.get('seqnum'),
      "ItineraryType" : itineraryType,
      "IsAuto" : false
    }
		fetch(endpoint, {
			method: 'POST',
			body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
				if (result.status === 'Success') {
					this.setState({
						roContent: result.content,
						loadingContent: false,
						form: _extend(this.state.form, {
							'content': result.content
						})
					})
				}
			})
			.catch((error) => {
				this.setState({
					roContent: '',
					loadingContent: false
				})
			});
	}

	getHeader() {
		const headerType = this.props.bookingDetail.get('fulfillmentTeam') === "" ?  this.props.roHeader[0].heading_Name : _padEnd(this.props.bookingDetail.get('fulfillmentTeam'), 10)
		let headerObj = _find(this.props.roHeader, ['heading_Name', headerType]);

		if (headerObj) {
			this.setState({
				roHeader: headerObj.heading,
				isLoading: false,
				headerType: headerType
			})
		}
	}

	closeModal() {
		this.setState({isModalOpen: false})
	}


	render() {
		const isWysiwyg = this.props.typeCode === 'LTS1' && (this.state.itineraryType === 'AGENT' || this.state.itineraryType === 'CUSTOMER')
		return (
			<div>
			  <div className="px-2 pb-4">
			    <div className="row justify-content-center">
			      <div className="col-lg-8 pt-4">
			        <div className="row text-center pb-4 border-bottom mb-4">
			          <div className="col-4 border-right">
									<Button
										variant={this.state.itineraryType === 'RO' ? 'secondary' : 'outline-secondary'}
										onClick={() => this.onTypeClick('RO')}
									>
										Hotel RO
									</Button>
			          </div>
			          <div className="col-4 border-right">
			            <Button
										variant={this.state.itineraryType === 'AGENT' ? 'secondary' : 'outline-secondary'}
										onClick={() => this.onTypeClick('AGENT')}
									>
										Agent Itinerary
									</Button>
			          </div>
			          <div className="col-4">
									<Button
										variant={this.state.itineraryType === 'CUSTOMER' ? 'secondary' : 'outline-secondary'}
										onClick={() => this.onTypeClick('CUSTOMER')}
									>
										Customer Itinerary
									</Button>
			          </div>
			        </div>
			        <form>
			          <div className="form-group row">
			            <div className="col-1">
										<Form.Label htmlFor="headerType">Email</Form.Label>
			            </div>
			            <div className="col-11">
										{this.props.roHeader &&
											<Form.Control
												as="select"
												name="headerType"
												id="headerType"
												value={this.state.headerType}
												onChange={(e) => this.onHeaderTypeChange(e)}
											>
												{this.props.roHeader.map((h, i) =>
													<option key={i} value={h.heading_Name}>{h.heading_Name}</option>
												)}
											</Form.Control>
										}
			            </div>
			          </div>
			          <div className="form-group row">
			            <div className="col-1">
										<Form.Label htmlFor="from">From</Form.Label>
			            </div>
			            <div className="col-11">
			              <div className="input-group">
											<Form.Control
												type="text"
												value={this.state.form.from}
												id="from"
												name="from"
												placeholder="eg. elvis.chan@hkwtl.com"
												onChange={(e) => this.onFormChange(e)}
											/>
			              </div>
			            </div>
			          </div>
			          <div className="form-group row">
			            <div className="col-1">
										<Form.Label htmlFor="to">To</Form.Label>
			            </div>
			            <div className="col-11">
										<ROHistoryToSelect
											handleSelect={this.onEmailSelect}
											value={this.state.form.to}
										/>
			            </div>
			          </div>
			          <div className="form-group row">
			            <div className="col-1">
										<Form.Label htmlFor="cc">CC</Form.Label>
			            </div>
			            <div className="col-11">
										<Form.Control
											type="text"
											value={this.state.form.cc}
											id="cc"
											name="cc"
											placeholder="eg. abc@gmail.com,123@hotmail.com"
											onChange={(e) => this.onFormChange(e)}
										/>
			            </div>
			          </div>
			          <div>
									{!isWysiwyg &&
				            <div className="p-3 small">
											<pre className="email-header text-center">
												{this.state.roHeader ?
													_map(_split(this.state.roHeader, '\n'), (line, i) =>
														<p key={i} className="mb-0">{_trim(line)}</p>
													):
													'Loading header...'
												}
											</pre>
										</div>
									}
									{this.state.loadingContent ? <p className="text-center p-4">Loading content...</p>:
										isWysiwyg ?
											<div>
												<JoditEditor
													value={this.state.form.content}
													tabIndex={1}
													onChange={newContent => this.onEditorStateChange(newContent)}
												/>
											</div>
											:
											<Form.Control
												as="textarea"
												rows="15"
												value={this.state.form.content}
												name="content"
												className="form-control email-content"
												onChange={(e) => this.onFormChange(e)}
											/>
									}
			          </div>
			          <div className="text-center mt-4">
			            <Button
										variant="secondary"
										className="mr-4"
										type="button"
										onClick={() => this.onSendClick(isWysiwyg)}
										size="lg"
										disabled={!this.state.form.to || !this.state.form.from}
									>Send</Button>
			            <Button variant="outline-secondary" className="mr-4" type="button" onClick={() => this.onResetClick()} size="lg">Reset</Button>
			            <Button variant="outline-secondary" type="button" onClick={() => this.onPreviewClick()} size="lg">Preview</Button>
			          </div>
			        </form>
			      </div>
			    </div>
			  </div>
				<ROPreviewModal
					isOpen={this.state.isModalOpen}
					handleCloseModal={this.closeModal}
					roHeader={this.state.roHeader}
					content={this.state.form.content}
					isWysiwyg={isWysiwyg}
				/>
			</div>

	  );
	}
}

TabSendRo.propTypes = {
	bookingDetail: PropTypes.object,
	handleGetRoHeader: PropTypes.func,
	handleSendRO: PropTypes.func,
	roHeader: PropTypes.array,
	typeCode: PropTypes.string,
};

export default TabSendRo;
