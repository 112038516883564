import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';

const ROPreviewModal = (props) => {
	return (
		<Modal
			show={props.isOpen}
			centered
			onHide={props.handleCloseModal}
			size="lg"
		>
		  <Modal.Header closeButton>
		    <Modal.Title>Preview</Modal.Title>
		  </Modal.Header>

		  <Modal.Body>
				<div className="email-preview">
					{!props.isWysiwyg && <pre>{props.roHeader}</pre>}
					{props.isWysiwyg ? <div dangerouslySetInnerHTML={{__html: props.content}} /> : <pre>{props.content}</pre>}
				</div>
		  </Modal.Body>
		</Modal>
  );
}

ROPreviewModal.propTypes = {
	isOpen: PropTypes.bool,
	handleCloseModal: PropTypes.func,
	roHeader: PropTypes.string,
	content: PropTypes.string,
	isWysiwyg: PropTypes.bool,
};

export default ROPreviewModal;
