import * as ActionTypes from '../constants/ActionTypes';

// import alertMsg from '../mock/alertMsg.json'; // Import mock data

const initialState = {
	details: null, //alertMsg.details,
	cityCode: null, //'HKG', // null,
	shouldUpdateAlertMsg: false
};

const msg = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.GET_ALERT_MSG_SUCCESS:
		return Object.assign({}, state, {
			details: action.payload,
			cityCode: action.cityCode,
			shouldUpdateAlertMsg: false
		});
	case ActionTypes.GET_ALERT_MSG_FAILURE:
		return Object.assign({}, state, {
			details: action.payload,
			cityCode: action.cityCode
		});
	case ActionTypes.DELETE_ALERT_MSG_SUCCESS:
		return Object.assign({}, state, {
			shouldUpdateAlertMsg: true
		});
	case ActionTypes.CREATE_ALERT_MSG_SUCCESS:
		return Object.assign({}, state, {
			shouldUpdateAlertMsg: true
		});
	case ActionTypes.UPDATE_ALERT_MSG_SUCCESS:
		return Object.assign({}, state, {
			shouldUpdateAlertMsg: true
		});
	case ActionTypes.CLEAR_ALERT_MSG_RECORDS:
		return Object.assign({}, state, {
			details: null,
			cityCode: null,
		});
	default:
		return state;
	}
};

export default msg;
