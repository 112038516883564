export const paxTitle=["MR","MS","MRS","MISS","MSTR","MDM","DR","PROF"]

export const status = [
  {name: 'Confirmed', variant: 'success', code: ['HK', 'AA', 'hK_CNT']},
  {name: 'Confirm', variant: 'info', code: ['KK', 'kK_CNT']},
  {name: 'Request', variant: 'warning', code: ['RQ', 'HN', 'rQ_CNT']},
  {name: 'Cancel', variant: 'danger', code: ['XX', 'UC', 'HX', 'xX_CNT']},
]

export const miniStatus = [
  {name: 'HK', variant: 'success', code: ['hK_CNT']},
  {name: 'KK', variant: 'info', code: ['kK_CNT']},
  {name: 'RQ', variant: 'warning', code: ['rQ_CNT']},
  {name: 'XX', variant: 'danger', code: ['xX_CNT']},
]

export const itemType = [
  {key: 'ROOM', value: 'R'},
  {key: 'SUITE', value: 'S'},
  {key: 'MEAL', value: 'M'},
  {key: 'EXTRA ITEM', value: 'X'},
  {key: 'TRANSPORT', value: 'T'},
]
