import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {Row, Col, Button} from 'react-bootstrap';

import Pagination from '../components/Pagination';
import _get from 'lodash/get';
import _chunk from 'lodash/chunk';
import _trim from 'lodash/trim';

import {getUnreadMsg} from '../actions/index';
import {prettyDateTime} from '../util/transformDate.js';

const chunkSize = 20;

class Home extends Component {
	constructor(props) {
		super(props);

    this.onDetailClick = this.onDetailClick.bind(this);
    this.onPageChange = this.onPageChange.bind(this);

    this.state = {
      currentPage: 0
    }
	}

  componentDidMount() {
    this.props.getUnreadMsg();
  }

  onDetailClick(txNo, seqnum) {
    this.props.history.push(`/${_trim(txNo)}-${seqnum}`)
  }

  onPageChange(val) {
    if (this.props.currentPage !== val) {
      this.setState({
        currentPage: val
      })
    }
  }

  render() {
    const hasMsg = this.props.unreadMsg.length > 0

    return (
      <div className="home-page pt-4">
        <title>Hotel Fulfillment System</title>

        <Row className="row justify-content-center">
          <Col xs="10">
            <div className="jumbotron">
              <h1 className="display-5"><span className="jumbotron__text">Hi, {this.props.profileInfo && `${this.props.profileInfo.typeCode}/${this.props.profileInfo.loginCode}`}</span></h1>
              <p className="lead mb-0"><span className="jumbotron__text">Welcome to Hotel Fulfillment System</span></p>
            </div>
          </Col>
        </Row>


        <div className="px-4 mb-5">
          <h3>Unread Message</h3>
  				<p>{this.props.unreadMsg.length} record(s)</p>
  				<table className={`table table-bordered ${hasMsg ? 'table-striped' : ''}`}>
  					<thead>
  						<tr>
  							<th width="45"></th>
  							<th width="200">Booking No.</th>
  							<th width="350">Subject</th>
  							<th>Detail</th>
  							<th width="230">Send Date</th>
  							<th width="100"></th>
  						</tr>
  					</thead>
  					<tbody>
  						{hasMsg ?
  							_chunk(this.props.unreadMsg, chunkSize)[this.state.currentPage].map((msg, i) =>
                  <tr key={i}>
                    <td>{this.props.unreadMsg.indexOf(msg) + 1}</td>
                    <td>{_get(msg, 'bkgref')} ({_get(msg, 'seqnum')})</td>
                    <td>{_get(msg, 'subject')}</td>
                    <td>
                      <div className="pre-line" dangerouslySetInnerHTML={{__html:_get(msg, 'detail')}}></div>
                    </td>
                    <td>{prettyDateTime(_get(msg, 'senddate'))}</td>
                    <td>
                      <Button variant="outline-info" onClick={() => this.onDetailClick(msg.bkgref, msg.seqnum)}>Detail</Button>
                    </td>
                  </tr>
  							) :
  							<tr><td colSpan="8">
  								<div className="text-center py-5">
                    {this.props.loading ?
                      <FontAwesomeIcon icon={faSpinner} size="2x" spin /> :
                      <div>No messages.</div>
                    }
  								</div>
  							</td></tr>
  						}
  					</tbody>
  				</table>
  				{_chunk(this.props.unreadMsg, chunkSize).length > 1 &&
  					<Pagination
  						handlePageChange={this.onPageChange}
  						noOfPages={_chunk(this.props.unreadMsg, chunkSize).length}
  						currentPage={this.state.currentPage}
  					/>
  				}
  			</div>

      </div>
    );
  }
}

Home.propTypes = {
	getUnreadMsg: PropTypes.func,
	unreadMsg: PropTypes.array,
};

function mapStateToProps(state) {
	return {
    unreadMsg: state.general.unreadMsg,
    loading: state.loading.componentLoading,
		profileInfo: state.general.profileInfo,
	}
}

const mapDispatchToProps = {
  getUnreadMsg,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
