import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
	rosters: null,
	addRosterSuccess: false,
	teamMemberList: null,
	}

const roster = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.GET_ROSTERS_SUCCESS:
		return Object.assign({}, state, {
			rosters: action.payload,
		});
	case ActionTypes.GET_TEAM_MEMBER_SUCCESS:
		return Object.assign({}, state, {
			teamMemberList: action.payload,
		});
	case ActionTypes.ADD_ROSTERS_REQUEST:
		return Object.assign({}, state, {
			addRosterSuccess: false,
		});
	case ActionTypes.ADD_ROSTERS_SUCCESS:
		return Object.assign({}, state, {
			addRosterSuccess: true,
		});
	case ActionTypes.CLEAR_ROSTER_SEARCH_RESULT:
		return Object.assign({}, state, {
			rosters: null,
			addRosterSuccess: false
		});
	default:
		return state;
	}
};

export default roster;
