import * as ActionTypes from '../constants/ActionTypes';

// import searchResult from '../mock/searchResult.json'; // Import mock data

const initialState = {
	searchResult: null, //searchResult, // null, // searchResult
	currentPage: 0,
	criteria: null,
	}

const search = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.SEARCH_BOOKING_SUCCESS:
		return Object.assign({}, state, {
			searchResult: action.payload,
			criteria: action.criteria
		});
	case ActionTypes.SEARCH_BOOKING_FAILURE:
		return Object.assign({}, state, {
			searchResult: action.payload,
		});
	case ActionTypes.CLEAR_BOOKING_SEARCH_RESULT:
		return Object.assign({}, state, {
			searchResult: null,
			currentPage: 0,
			criteria: null,
		});
	case ActionTypes.CHANGE_PAGE:
		return Object.assign({}, state, {
			currentPage: action.payload
		});
	default:
		return state;
	}
};

export default search;
