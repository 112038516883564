import fetch from 'node-fetch';
import Auth from '../Auth/Auth';
import * as ActionTypes from '../constants/ActionTypes';

import _pull from 'lodash/pull';
import _split from 'lodash/split';

const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

export function getBookingDetail(txNo, seqnum) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/Bookings/BookingDetail/Get`;
    const body = {
      "Txno": txNo,
      "Seqnum": seqnum
    }

		dispatch({
			type: ActionTypes.GET_BOOKING_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.status === "Success") {
          dispatch({
            type: ActionTypes.GET_BOOKING_SUCCESS,
            payload: result
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_BOOKING_FAILURE,
  					payload: null,
            msg: `Get booking ${txNo} (${seqnum}) failed. <b>${result.message}</b>`
  				})
        }
			})
			.catch((error) => {
				console.log('getBookingDetail error: ', error)
				dispatch({
					type: ActionTypes.GET_BOOKING_FAILURE,
					error,
					payload: null,
          msg: `Get booking ${txNo} (${seqnum}) failed. API issue.`
				})
			});
	};
}

export function clearBookingDetail() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CLEAR_BOOKING,
      payload: null
    })
  }
}

export function updateBooking(obj) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/Bookings/BookingDetail/Update`;
    const body = {
      "BookingDetailObj": obj
    }

		dispatch({
			type: ActionTypes.UPDATE_BOOKING_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if(result.status === "Success") {
          dispatch({
            type: ActionTypes.UPDATE_BOOKING_SUCCESS,
            payload: result,
            msg: `Booking ${obj.txno} (${obj.seqnum}) is successfully updated.`,
          })
        } else {
          dispatch({
            type: ActionTypes.UPDATE_BOOKING_FAILURE,
            payload: result,
            msg:`Update Booking ${obj.txno} (${obj.seqnum}) failed. <b>${result.message}</b>`
          })
        }
			})
			.catch((error) => {
				console.log('updateBooking error: ', error)
				dispatch({
					type: ActionTypes.UPDATE_BOOKING_FAILURE,
					error,
					payload: null,
          msg: `Update Booking failed. API error. ${error.message}`
				})
			});
	};
}

export function getRoHeader(txno, seqnum) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/RO/GetHeader`;
    const body = {
      "BkgRef" : txno,
      "SegNum" : seqnum,
      "IsAuto" : false
    }

		dispatch({
			type: ActionTypes.GET_HEADER_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if(result.status === "Success") {
          dispatch({
            type: ActionTypes.GET_HEADER_SUCCESS,
            payload: result
          })
        } else {
          dispatch({
            type: ActionTypes.GET_HEADER_FAILURE,
            payload: result,
            msg:`Get header failed. <b>${result.message}</b>`
          })
        }
			})
			.catch((error) => {
				console.log('updateBooking error: ', error)
				dispatch({
					type: ActionTypes.GET_HEADER_FAILURE,
					error,
					payload: null,
          msg: `Get header failed. API error. ${error.message}`
				})
			});
	};
}

export function sendRO(txno, seqnum, form) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/RO/Send`;
    const body = {
      "Txno" : txno,
      "Seqnum": seqnum,
      "Type": "EMAIL",
      "From": form.from,
      "To": _pull(_split(form.to, ','), ''),
      "Cc": _pull(_split(form.cc, ','), ''),
      "Bcc": null,
      "IsHTMLFormat": false,
      "Content": form.content
    }

		dispatch({
			type: ActionTypes.SEND_RO_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if(result.status === "Success") {
          dispatch({
            type: ActionTypes.SEND_RO_SUCCESS,
            payload: result,
            msg: 'Successfully Sent'
          })
        } else {
          dispatch({
            type: ActionTypes.SEND_RO_FAILURE,
            payload: result,
            msg:`Send RO failed. <b>${result.message}</b>`
          })
        }
			})
			.catch((error) => {
				console.log('send RO error: ', error)
				dispatch({
					type: ActionTypes.SEND_RO_FAILURE,
					error,
					payload: null,
          msg: `Get content failed. API error. ${error.message}`
				})
			});
	};
}

export function readMsg(typeCode, login, txno, seqnum, autonum) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/Bookings/ManageHotelMessage/Read`;
    const body = {
    	"TypeCode": typeCode,
    	"Login": login,
    	"Txno": txno,
    	"Seqnum": seqnum,
    	"Message": {
    		"autonum": autonum
    	}
    }

		dispatch({
			type: ActionTypes.READ_MSG_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if(result.status === "Success") {
          dispatch({
            type: ActionTypes.READ_MSG_SUCCESS
          })
        } else {
          dispatch({
            type: ActionTypes.READ_MSG_FAILURE,
            payload: result,
            msg: `Read message failed. <b>${result.message}</b>`
          })
        }
			})
			.catch((error) => {
				console.log('readMsg error: ', error)
				dispatch({
					type: ActionTypes.READ_MSG_FAILURE,
					error,
					payload: null,
          msg: `Read message failed. API error. ${error.message}`
				})
			});
	};
}

export function deleteMsg(typeCode, login, txno, seqnum, autonum) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/Bookings/ManageHotelMessage/DELETE`;
    const body = {
    	"TypeCode": typeCode,
    	"Login": login,
    	"Txno": txno,
    	"Seqnum": seqnum,
    	"Message": {
    		"autonum": autonum
    	}
    }

		dispatch({
			type: ActionTypes.DELETE_MSG_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if(result.status === "Success") {
          dispatch({
            type: ActionTypes.DELETE_MSG_SUCCESS
          })
        } else {
          dispatch({
            type: ActionTypes.DELETE_MSG_FAILURE,
            payload: result,
            msg: `Delete message failed. <b>${result.message}</b>`
          })
        }
			})
			.catch((error) => {
				console.log('deleteMsg error: ', error)
				dispatch({
					type: ActionTypes.DELETE_MSG_FAILURE,
					error,
					payload: null,
          msg: `Delete message failed. API error. ${error.message}`
				})
			});
	};
}

export function createMsg(txno, seqnum, form) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/Bookings/ManageHotelMessage/NEW`;
    const body = {
      "Txno" : txno,
      "Seqnum": seqnum,
      "Message": {
    		"subject": form.subject,
    		"detail": form.detail,
    		"replynum": 0
    	}
    }

		dispatch({
			type: ActionTypes.CREATE_MSG_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if(result.status === "Success") {
          dispatch({
            type: ActionTypes.CREATE_MSG_SUCCESS,
            payload: result,
            msg: `Create msg succeed.`
          })
        } else {
          dispatch({
            type: ActionTypes.CREATE_MSG_FAILURE,
            payload: result,
            msg:`Create msg failed. <b>${result.message}</b>`
          })
        }
			})
			.catch((error) => {
				console.log('Create msg error: ', error)
				dispatch({
					type: ActionTypes.CREATE_MSG_FAILURE,
					error,
					payload: null,
          msg: `Create msg failed. API error. ${error.message}`
				})
			});
	};
}

export function addHistory(txno, seqnum, msg) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/Bookings/AddRemark`;
    const body = {
      "txno" : txno,
      "seqnum": seqnum,
      "message": msg
    }

		dispatch({
			type: ActionTypes.ADD_HISTORY_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if(result.status === "Success") {
          dispatch({
            type: ActionTypes.ADD_HISTORY_SUCCESS,
            payload: result,
            msg: 'History note added.'
          })
        } else {
          dispatch({
            type: ActionTypes.ADD_HISTORY_FAILURE,
            payload: result,
            msg:`Add history note failed. <b>${result.message}</b>`
          })
        }
			})
			.catch((error) => {
				console.log('Add history error: ', error)
				dispatch({
					type: ActionTypes.ADD_HISTORY_FAILURE,
					error,
					payload: null,
          msg: `Add history note failed. API error. ${error.message}`
				})
			});
	};
}

export function getROHistoryContent(autonum) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/RO/GetROHistoryContent/${autonum}`;

		dispatch({
			type: ActionTypes.GET_RO_HISTORY_CONTENT_REQUEST,
		})

    fetch(endpoint, {
			method: 'GET',
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if(result.status === "Success") {
          dispatch({
            type: ActionTypes.GET_RO_HISTORY_CONTENT_SUCCESS,
            payload: result,
          })
        } else {
          dispatch({
            type: ActionTypes.GET_RO_HISTORY_CONTENT_FAILURE,
            payload: result,
            msg:`Get RO history content failed. <b>${result.message}</b>`
          })
        }
			})
			.catch((error) => {
				console.log('Get RO history content  error: ', error)
				dispatch({
					type: ActionTypes.GET_RO_HISTORY_CONTENT_FAILURE,
					error,
					payload: null,
          msg: `Get RO history content failed. API error. ${error.message}`
				})
			});
	};
}

export function clearROHistoryContent() {
  return (dispatch) => {
		dispatch({
			type: ActionTypes.CLEAR_RO_HISTORY_CONTENT,
		})
	};
}

export function verifyBooking(txno, seqnum, form) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/InvVch/Verify`;
    const body = {
      "Txno" : txno,
      "Seqnum": seqnum,
      "UserRemark": form.userRemark,
      "COD1": form.COD1,
      "COD2": form.COD2,
      "COD3": "",
      "CltCode": form.cltCode,
      "PNR": form.pnr,
      "VchEmail": form.vchEmail,
      "AdtQty": form.adtQty,
      "ChdQty": form.chdQty,
      "IsShowMap": form.isShowMap,
      "IsShowCustRef": form.isShowCustRef,
      "ReqInv": form.reqInv,
      "SelectedPax": form.selectedPax,
      "ProvideCoupon": form.isProvideCoupon,
      "IsPromotion": form.isPromotion,
      "Night": form.night,
      "Package": form.package,
    }

		dispatch({
			type: ActionTypes.VERIFY_BOOKING_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if(result.status === "Success") {
          dispatch({
            type: ActionTypes.VERIFY_BOOKING_SUCCESS,
      			msg: `Booking ${txno} (${seqnum}) is processing to verify.`,
            payload: result
          })
        } else {
          dispatch({
            type: ActionTypes.VERIFY_BOOKING_FAILURE,
            payload: result,
            msg:`Verify booking failed. <b>${result.message}</b>`
          })
        }
			})
			.catch((error) => {
				console.log('Verify booking error: ', error)
				dispatch({
					type: ActionTypes.VERIFY_BOOKING_FAILURE,
					error,
					payload: null,
          msg: `Verify booking failed. API error. ${error.message}`
				})
			});
	};
}

export function unverifyBooking(txno, seqnum) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/InvVch/Unverify`;
    const body = {
      "Txno" : txno,
      "Seqnum": seqnum
    }

		dispatch({
			type: ActionTypes.UNVERIFY_BOOKING_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if(result.status === "Success") {
          dispatch({
            type: ActionTypes.UNVERIFY_BOOKING_SUCCESS,
            payload: result,
            msg: `Booking ${txno} (${seqnum}) is processing to unverify.`
          })
        } else {
          dispatch({
            type: ActionTypes.UNVERIFY_BOOKING_FAILURE,
            payload: result,
            msg:`Unverify booking failed. <b>${result.message}</b>`
          })
        }
			})
			.catch((error) => {
				console.log('Verify booking error: ', error)
				dispatch({
					type: ActionTypes.UNVERIFY_BOOKING_FAILURE,
					error,
					payload: null,
          msg: `Unverify booking failed. API error. ${error.message}`
				})
			});
	};
}

export function cloneSegment(txno, seqnum) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/Bookings/CopySegment`;
    const body = {
      "Txno" : txno,
      "Seqnum": seqnum,
      "CloneFlag":"2"
    }

		dispatch({
			type: ActionTypes.CLONE_SEGMENT_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if(result.status === "Success" && result.newSeqnum !== '') {
          dispatch({
            type: ActionTypes.CLONE_SEGMENT_SUCCESS,
            payload: result,
            msg: `Booking ${txno} (${seqnum}) is successfully cloned to ${txno}-${result.newSeqnum}.`
          })
        } else {
          dispatch({
            type: ActionTypes.CLONE_SEGMENT_FAILURE,
            payload: result,
            msg:`Clone Segment failed. <b>${result.message}</b>`
          })
        }
			})
			.catch((error) => {
				console.log('Verify booking error: ', error)
				dispatch({
					type: ActionTypes.CLONE_SEGMENT_FAILURE,
					error,
					payload: null,
          msg: `Clone Segment failed. API error. ${error.message}`
				})
			});
	};
}

export function copyWTo1Booking(txno, seqnum) {
  const oneTnxo = txno.replace('W', 1)

  return (dispatch) => {
    const endpoint = `${currentServerURL}/Bookings/CopyBooking?txno=${txno}&seqnum=${seqnum}`;

		dispatch({
			type: ActionTypes.COPY_W_TO_ONE_BOOKING_REQUEST
		})

    fetch(endpoint, {
			method: 'GET',
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if(result.status === "Success") {
          dispatch({
            type: ActionTypes.COPY_W_TO_ONE_BOOKING_SUCCESS,
            msg: `Booking ${txno} (${seqnum}) is successfully copied to ${oneTnxo} (${seqnum}).`,
            newTxno: oneTnxo
          })
        } else {
          dispatch({
            type: ActionTypes.COPY_W_TO_ONE_BOOKING_FAILURE,
            payload: result,
            msg:`Copy to 1 booking failed. <b>${result.message}</b>`
          })
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.COPY_W_TO_ONE_BOOKING_FAILURE,
					error,
					payload: null,
          msg: `Copy to 1 booking failed. API error. ${error.message}`
				})
			});
	};
}

export function copy1ToWBooking(txno, seqnum, typeCode, loginCode) {
  const wTnxo = txno.replace('1', 'W')

  return (dispatch) => {
    const endpoint = `${currentServerURL}/Bookings/ChangeBookingOwner`;
    const body = {
      "Txno": txno,
      "Seqnum": seqnum,
      "BookingOwner_TypeCode": typeCode,
      "BookingOwner_Login": loginCode
    }

		dispatch({
			type: ActionTypes.COPY_ONE_TO_W_BOOKING_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if(result.status === "Success") {
          dispatch({
            type: ActionTypes.COPY_ONE_TO_W_BOOKING_SUCCESS,
            msg: `Booking ${txno} (${seqnum}) is successfully copied to ${wTnxo} (${seqnum}).`,
            newTxno: wTnxo
          })
        } else {
          dispatch({
            type: ActionTypes.COPY_ONE_TO_W_BOOKING_FAILURE,
            payload: result,
            msg:`Copy to W booking failed. <b>${result.message}</b>`
          })
        }
			})
			.catch((error) => {
				dispatch({
					type: ActionTypes.COPY_ONE_TO_W_BOOKING_FAILURE,
					error,
					payload: null,
          msg: `Copy to W booking failed. API error. ${error.message}`
				})
			});
	};
}

export function cancelBooking(txNo, seqnum, amendnote) {
  const criteria = {
    params: [
      {
        Txno: txNo,
        Seqnum: seqnum,
        Amendnotes: amendnote
      }
    ]
  }

  return batchCancelBooking(criteria)
}

export function batchCancelBooking(criteria, succeedFun, errorFun) {
  return dispatch => {
    const endpoint = `${currentServerURL}/Bookings/BatchCancel`
    const body = {
      cancelParams: criteria.params,
      message: criteria.msg
    }

    dispatch({
      type: ActionTypes.CANCEL_BOOKING_REQUEST,
      body: body
    })

    fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
        'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
      }
    })
      .then(response => response.json())
      .then(result => {
        if (result.status === 'Success') {
          dispatch({
            type: ActionTypes.CANCEL_BOOKING_SUCCESS,
            payload: result,
            msg: `Cancel booking succeed.`
          })
          if (succeedFun) {
            succeedFun(result)
          }
        } else {
          dispatch({
            type: ActionTypes.CANCEL_BOOKING_FAILURE,
            payload: result,
            msg: `Cancel booking failed. <b>${result.message}</b>`
          })
        }
      })
      .catch(error => {
        console.log('Cancel booking error: ', error)
        dispatch({
          type: ActionTypes.CANCEL_BOOKING_FAILURE,
          error,
          payload: null,
          msg: `Cancel booking failed. API error. ${error.message}`
        })
      })
  }
}

export function createManualBooking(form, txno) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/Bookings/CreateManualBooking`;
    const body = {
      "BookingInfo": form,
      "Txno": txno
    }

		dispatch({
			type: ActionTypes.CREATE_MANUAL_BOOKING_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.status === "Success") {
          dispatch({
            type: ActionTypes.CREATE_MANUAL_BOOKING_SUCCESS,
            payload: result,
            msg: `Create manual booking succeed. ${result.txno} (${result.seqnum}) is created.`,
          })
        } else {
          dispatch({
  					type: ActionTypes.CREATE_MANUAL_BOOKING_FAILURE,
  					payload: null,
            msg: `Create manual booking failed. <b>${result.message}</b>`
  				})
        }
			})
			.catch((error) => {
				console.log('getBookingDetail error: ', error)
				dispatch({
					type: ActionTypes.CREATE_MANUAL_BOOKING_FAILURE,
					error,
					payload: null,
          msg: `Create manual booking failed. API issue.`
				})
			});
	};
}
