import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Auth from '../Auth/Auth';
import Select from 'react-select';

import _debounce from 'lodash/debounce';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _template from 'lodash/template';
const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

const endpoint = _template(`${currentServerURL}/information/countrylist?keywords=<%= keyword %>`);
const method = 'GET'

class CountrySelect extends Component {
	constructor(props) {
		super(props);
		this.onSelectInputChange = this.onSelectInputChange.bind(this);
		this.onSelectClick = this.onSelectClick.bind(this);

		this.state = {
			options: [],
			isSearching: false,
			currentObj: null,
		};
	}

	componentDidMount() {
		this.getLabel(this.props.selectedCode)
	}

	componentDidUpdate(prevProps) {
		if(prevProps.selectedCode !== this.props.selectedCode) {
			this.getLabel(this.props.selectedCode)
		}
	}

	onSelectInputChange = _debounce((string, action) => {
		const act = action.action
		if (act === "input-change") {

			if (string) {
				this.setState({
					isSearching: true
				})

		    fetch(endpoint({'keyword' : string}), {
					method: method,
					headers: {
						Authorization: 'Bearer ' + auth.getAccessToken(),
						'Content-Type': 'application/x-www-form-urlencoded'
					}
				})
					.then(response => response.json())
					.then((result) => {
						if (result.length !== 0) {
							let options = _map(result, (r) => ({
								'value': r.countrycode,
								'label': r.countrycode + ' - ' + r.countryname
							}))

							this.setState({
								options: options,
								isSearching: false
							})
						} else {
							this.setState({
								isSearching: false
							})
						}

					})
					.catch((error) => {
						console.log('error: ', error)
						this.setState({
							isSearching: false
						})
					});

			} else {
				this.clearLoginOptions()
			}
		}
	 }, 1000)

	onSelectClick(selectedOption) {
		if (selectedOption) {
			this.props.handleSelect('countryCode', selectedOption.value)
			this.setState({
				currentObj: selectedOption
			})
		} else { // clear
			this.clearLoginOptions()
		}
	}

	getLabel(string) {
		if (string) {
			fetch(endpoint({'keyword' : string}), {
				method: method,
				headers: {
					Authorization: 'Bearer ' + auth.getAccessToken(),
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			})
				.then(response => response.json())
				.then((result) => {
					let options = _map(result, (r) => ({
						'value': r.citycode,
						'label': r.citycode + ' - ' + r.cityname
					}))
					this.setState({
						currentObj: _find(options, {'value': string})
					})
				})
				.catch((error) => {
					console.log('error: ', error)
				});
		} else {
			this.setState({
				currentObj: null
			})
		}

	}

	clearLoginOptions() {
		this.setState({
			options: [],
			currentObj: null
		});
		this.props.handleSelect('countryCode', '')
	}

  render() {
		return (
			<Select
				name="countryCode"
				value={this.state.currentObj}
				onChange={this.onSelectClick}
				options={this.state.options ? this.state.options : []}
				onInputChange={(string, action) => this.onSelectInputChange(string, action)}
				placeholder="Search Country"
				isClearable
				isLoading={this.state.isSearching}
			/>
    );
  }
}

CountrySelect.propTypes = {
	handleSelect: PropTypes.func,
	selectedCode: PropTypes.string,
}

export default CountrySelect;
