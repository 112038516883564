import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Auth from '../Auth/Auth';
import Select from 'react-select';

import _debounce from 'lodash/debounce';
import _find from 'lodash/find';
import _template from 'lodash/template';
const auth = new Auth();

const endpoint = _template(`https://portal.adholidays.com/AutoComplete/api/autosearch/?lang=en&q=<%= cityCode %><%= space %><%= keyword %>`);
const method = 'GET'

class HotelNameSelect extends Component {
	constructor(props) {
		super(props);
		this.onSelectInputChange = this.onSelectInputChange.bind(this);
		this.onSelectClick = this.onSelectClick.bind(this);

		this.state = {
			options: [],
			isSearching: false,
			currentObj: '',
		};
	}

	componentDidMount() {
		if (this.props.selectedHotelName) {
			this.getLabel(this.props.selectedHotelName)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.selectedHotelName !== this.props.selectedHotelName) {
			this.getLabel(this.props.selectedHotelName)
		}
	}

	onSelectInputChange = _debounce((string, action) => {
		const act = action.action
		if (act === "input-change") {

			if (string) {
				this.setState({
					isSearching: true
				})
		    fetch(endpoint({cityCode: (this.props.cityCode || ''), keyword: string, space: (this.props.cityCode && string)?' ':''}), {
					method: method,
					headers: {
						Authorization: 'Bearer ' + auth.getAccessToken(),
						'Content-Type': 'application/x-www-form-urlencoded'
					}
				})
					.then(response => response.json())
					.then((result) => {
						var hotelList = result.filter(record => record.Type === "HOTEL");
						if (result.length !== 0) {
							this.setState({
								options: hotelList,
								isSearching: false
							})
						} else {
							this.setState({
								isSearching: false
							})
						}

					})
					.catch((error) => {
						console.log('error: ', error)
						this.setState({
							isSearching: false
						})
					});

			} else {
				this.clearLoginOptions()
			}
		}
	 }, 1000)

	onSelectClick(selectedOption) {
		if (selectedOption) {
			this.props.handleSelect(selectedOption.Code, selectedOption.Name)
			this.setState({
				currentObj: selectedOption
			})
		} else { // clear
			this.clearLoginOptions()
		}
	}

	getLabel(string) {
		if (string) {
			fetch(endpoint({ cityCode: (this.props.cityCode || ''), keyword: string, space: (this.props.cityCode && string) ? ' ' : ''}), {
				method: method,
				headers: {
					Authorization: 'Bearer ' + auth.getAccessToken(),
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			})
				.then(response => response.json())
				.then((result) => {
					var hotelList = result.filter(record => record.Type === "HOTEL");
					this.setState({
						currentObj: _find(hotelList, {'Code': string})
					})
				})
				.catch((error) => {
					console.log('error: ', error)
				});
			} else {
				this.clearLoginOptions()
			}
	}

	clearLoginOptions() {
		this.setState({
			options: [],
			currentObj: ''
		});
		this.props.handleSelect('')
	}

  render() {
		return (
			<Select
				name="customerCodeSelect"
				value={this.state.currentObj}
				onChange={this.onSelectClick}
				options={this.state.options ? this.state.options : []}
				onInputChange={(string, action) => this.onSelectInputChange(string, action)}
				placeholder="Search Hotel"
				getOptionValue={option => option['Code']}
				getOptionLabel ={option => option['Name']}
				isClearable={this.props.isClearable}
				isLoading={this.state.isSearching}
				className={this.props.isInvalid && "invalid-select"}
			/>
    );
  }
}

HotelNameSelect.propTypes = {
	handleSelect: PropTypes.func,
	selectedHotelName: PropTypes.string,
	cityCode: PropTypes.string,
	isClearable: PropTypes.bool,
	isInvalid: PropTypes.bool,
}

export default HotelNameSelect;
