import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBusinessTime, faSave} from '@fortawesome/free-solid-svg-icons';
import {Form, Button, Row, Col} from 'react-bootstrap';
import Auth from '../Auth/Auth';

import Title from '../components/Title';
import CustomDateRangePicker from '../components/CustomDateRangePicker';
import CustomDatePicker from '../components/CustomDatePicker';
import LoginSelect from '../components/LoginSelect';
import NewRosterModal from '../components/Roster/NewRosterModal';

import {dateFormatToSend, dateTimeFormatToSend} from '../util/transformDate.js'

import _get from 'lodash/get';
import _extend from 'lodash/extend';
import _omitBy from 'lodash/omitBy';
import _map from 'lodash/map';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import _find from 'lodash/find';

import {
  getRosters,
  updateRoster,
  addNewRoster,
  clearRosterSearchResult,
  getTeamMemberList
} from '../actions/roster';

const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

class Rosters extends Component {
  constructor(props) {
    super(props);
    this.onDateSelect = this.onDateSelect.bind(this);
    this.onRosterDateSelect = this.onRosterDateSelect.bind(this);
		this.onLoginSelect = this.onLoginSelect.bind(this);
    this.onGetRosterClick = this.onGetRosterClick.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onToggleModal = this.onToggleModal.bind(this);

    this.state = {
      criteria: {
        Duty:{
          DateFrom: '', //'2019-02-09',
          DateTo: '', //'2019-03-09',
        },
        Option: 'ALL',
        TypeCode: '',
        Login: '',
      },
      newRoster: [],
      editRosters: null,
      isModalOpen: false,
      errorMsg: null,
      typeCodeName: null,
    };
  }

  componentDidMount() {
    if (_get(this.props.profileInfo, 'typeCode')) {
      this.getTypeCodeName(_get(this.props.profileInfo, 'typeCode'))
    }
  }

  componentWillUnmount() {
    if (this.props.rosters) {
      this.props.clearRosterSearchResult()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.rosters !== this.props.rosters) {
      this.setRosterState()
    }
    if (!prevProps.addRosterSuccess && this.props.addRosterSuccess) {
      this.onToggleModal(false)
    }
    if (!_get(prevProps.profileInfo, 'typeCode') && _get(this.props.profileInfo, 'typeCode')) {
      this.getTypeCodeName(_get(this.props.profileInfo, 'typeCode'))
    }
	}

  onFieldChange(e, i) {
    if (e.target.name === "Option") {
      this.setState({
        criteria: _extend(this.state.criteria, {
    			[e.target.name]: e.target.value
        })
  		})
    } else {
      var deepForm = _cloneDeep(this.state.editRosters);
      _set(deepForm, [i, e.target.name], e.target.value === "true" ? true : false)

      this.setState({
        editRosters: deepForm,
  		})
    }
	}

  onLoginSelect(login, name, i) {
    if (name === "edit-login") {
      var deepForm = _cloneDeep(this.state.editRosters);
      _set(deepForm, [i, 'login'], login)

      this.setState({
        editRosters: deepForm,
  		})
    } else {
      this.setState({
        criteria: _extend(this.state.criteria, {
          "Login": login ? login : ''
        })
      });
    }
	}

  onDateSelect(name, i, startDateValue, endDateValue) {
    this.setState({
      criteria: _extend(this.state.criteria, {
        Duty: {
          DateFrom: startDateValue ? dateFormatToSend(startDateValue) : '',
          DateTo: endDateValue ? dateFormatToSend(endDateValue) : '',
        }
      })
    })
	}

  onRosterDateSelect(name, i, startDateValue, endDateValue) {
    var deepForm = _cloneDeep(this.state.editRosters);
    _set(deepForm, [i, name], dateTimeFormatToSend(startDateValue))

    this.setState({
      editRosters: deepForm,
    })
	}

  onGetRosterClick() {
    let isValid = this.state.criteria.Duty.DateFrom || this.state.criteria.TypeCode
    if (isValid) {
      this.setState({
        errorMsg: null
      })

      const criteriaWithValue = _omitBy(this.state.criteria, cri => (cri == null || cri === "" || cri.DateFrom === ""))
      this.props.getRosters(criteriaWithValue)
    } else {
      this.setState({
        errorMsg: 'Please input search criteria.'
      })
    }
  }

  onSaveClick() {
    this.props.updateRoster(this.state.editRosters)
  }

  onToggleModal(bool) {
    if (bool) {
      if (!this.props.teamMemberList) {
        this.props.getTeamMemberList()
      }
    }

    this.setState({
      isModalOpen: bool,
    })
  }

  onKeyDown(e) {
		if (e.key === 'Enter') {
			this.onGetRosterClick()
		}
	}


  setRosterState() {
    this.setState({
      editRosters: _map(this.props.rosters, roster => ({
        "rosterID": roster.rosterID,
        "typeCode": roster.typeCode,
        "login": roster.login,
        "dutyFrom": roster.dutyFrom,
        "dutyTo": roster.dutyTo,
        "enabled": roster.enabled
      }))
    })
  }

  getTypeCodeName(typecode) {
    fetch(`${currentServerURL}/information/TypeCodeList?keywords=${typecode}`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + auth.getAccessToken(),
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(response => response.json())
      .then((result) => {
        this.setState({
          typeCodeName: _find(result, {typecode: typecode}).company_name,
          criteria: _extend(this.state.criteria, {
            "TypeCode": typecode,
          })
        })

      })
      .catch((error) => {
        console.log('error: ', error)
      });
  }

  render () {
    const hasRecord = this.props.rosters && this.props.rosters.length > 0

    return (
      <div>
        <Title title="Rosters" browserTitle="Rosters"/>
        <div className="mt-5"  onKeyDown={(e) => this.onKeyDown(e)}>
          <Row className="justify-content-center">
            <Col xs="6" className={`${this.props.rosters ? 'border-bottom' : ''} p-4 mb-4`}>
              <Form>
                <Form.Group>
                  <Row>
                    <Col xs="3"><Form.Label>Company</Form.Label></Col>
                    <Col xs="9">
                      {_get(this.props.profileInfo, 'typeCode')} - {this.state.typeCodeName}
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col xs="3"><Form.Label>User</Form.Label></Col>
                    <Col xs="9">
                      <LoginSelect
                        handleSelect={this.onLoginSelect}
                        selectedTypeCode={this.state.criteria.TypeCode}
                        selectedLogin={this.state.criteria.Login}
                        isClearable
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col xs="3"><Form.Label>Option</Form.Label></Col>
                    <Col xs="9">
                      <Form.Control
                        as="select"
                        name="Option"
                        value={this.state.criteria.Option}
                        onChange={(e) => this.onFieldChange(e)}
                      >
                        <option value="ALL">All</option>
                        <option value="ENABLE">Enable</option>
                        <option value="DISABLE">Disable</option>
                      </Form.Control>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col xs="3"><Form.Label>Duty Period</Form.Label></Col>
                    <Col xs="9">
                      <CustomDateRangePicker
                        handleSelect={this.onDateSelect}
                        name="Duty"
                        startDateValue={_get(this.state.criteria, ['Duty', 'DateFrom'])}
                        endDateValue={_get(this.state.criteria, ['Duty', 'DateTo'])}
                        isClearAvailable
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <div className="text-center pt-3">
                  <Button variant="outline-info" className="mr-3" onClick={() => this.onToggleModal(true)}>+ New Rosters for {_get(this.props.profileInfo, 'typeCode')}</Button>

                  <Button onClick={() => this.onGetRosterClick()}><FontAwesomeIcon icon={faBusinessTime} /> Get Rosters</Button>
                  {this.state.errorMsg &&
                    <p className="small red mb-0 pt-1">
                      {this.state.errorMsg}
                    </p>
                  }
                </div>
              </Form>
            </Col>
          </Row>

          {this.state.editRosters && this.state.editRosters.length > 0 &&
            <div className="px-2">
              <p>{this.props.rosters.length} record(s)</p>
              <table className={`table table-bordered ${hasRecord && this.props.rosters.length > 1 ? 'table-striped' : ''}`}>
                <thead>
                  <tr>
                    <th width="10">#</th>
                    <th >Company</th>
                    <th width="250">User</th>
                    <th width="230">Duty From</th>
                    <th width="230">Duty To</th>
                    <th width="150">Enabled</th>
                  </tr>
                </thead>
                <tbody>
                  {hasRecord ?
                  this.state.editRosters.map((roster, i) =>
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>
                        {this.state.typeCodeName}
                      </td>
                      <td>
                        <LoginSelect
                          handleSelect={this.onLoginSelect}
                          selectedTypeCode={roster.typeCode}
                          selectedLogin={roster.login}
                          index={i}
                          name="edit-login"
                        />
                      </td>
                      <td>
                        <CustomDatePicker
        									handleSelect={this.onRosterDateSelect}
                          index={i}
        									name="dutyFrom"
                          value={_get(roster, 'dutyFrom')}
                          hasTimePicker
        								/>
                      </td>
                      <td>
                        <CustomDatePicker
        									handleSelect={this.onRosterDateSelect}
                          index={i}
        									name="dutyTo"
                          value={_get(roster, 'dutyTo')}
                          hasTimePicker
        								/>
                      </td>
                      <td>
                        <Form.Control
                          as="select"
                          name="enabled"
                          value={roster.enabled}
                          onChange={(e) => this.onFieldChange(e, i)}
                        >
                          <option value={true}>Enable</option>
                          <option value={false}>Disable</option>
                        </Form.Control>
                      </td>
                    </tr>) :
                    <tr><td colSpan="5">
                      <div className="text-center py-5">
                        No detail.
                      </div>
                    </td></tr>
                  }
                </tbody>
              </table>

              <div className="text-center py-3">
                <Button onClick={() => this.onSaveClick()}><FontAwesomeIcon icon={faSave} /> Save Rosters</Button>
              </div>
            </div>
          }

          {this.state.editRosters && !hasRecord &&
            <div className="text-center p-5">No record.</div>
          }
        </div>

          <NewRosterModal
            typeCode={_get(this.props.profileInfo, 'typeCode')}
            typeCodeName={this.state.typeCodeName}
            teamMemberList={this.props.teamMemberList}
            handleToggleModal={this.onToggleModal}
            handleAddRoster={this.props.addNewRoster}
            isModalOpen={this.state.isModalOpen}
          />

      </div>
    );
  }
}

Rosters.propTypes = {
	getRosters: PropTypes.func,
	updateRoster: PropTypes.func,
	addNewRoster: PropTypes.func,
	clearRosterSearchResult: PropTypes.func,
	profileInfo: PropTypes.object,
	teamMemberList: PropTypes.array,
};

function mapStateToProps(state) {
	return {
		rosters: state.roster.rosters,
		addRosterSuccess: state.roster.addRosterSuccess,
		profileInfo: state.general.profileInfo,
		teamMemberList: state.roster.teamMemberList,
	}
}

const mapDispatchToProps = {
	getRosters,
	updateRoster,
	addNewRoster,
	clearRosterSearchResult,
	getTeamMemberList,
};

export default connect(mapStateToProps, mapDispatchToProps)(Rosters);
