import moment from 'moment';
import _toNumber from 'lodash/toNumber';
import _split from 'lodash/split';

// Jun 05, 2019
export function prettySingleDate(date) {
  return date ? moment(date).format("MMM DD, YYYY") : ''
}

// Jun 05, 2019 09:30 AM
export function prettySingleDateTime(date) {
  return date ? moment(date).format("MMM DD, YYYY hh:mm A") : ''
}

// Jun 05 - Jun 07, 2019
export function prettyDateRange(startDate, endDate) {
  return moment(startDate).format("MMM DD")+ ' - '+ moment(endDate).format("MMM DD, YYYY")
}

// MAY 24, 2019 16:33:22
export function prettyDateTime(date) {
  return moment(date).format('MMM DD, YYYY HH:mm:ss');
}

// 2019-07-17
export function dateFormatToSend(date) {
  return moment(date).format("YYYY-MM-DD")
}

// 2019-07-17
export function dateTimeFormatToSend(date) {
  // return moment(date).format("YYYY-MM-DD")
  return _split(date, '+', 1)[0]
}

// 1/12/2019
export function dateFormat(date) {
  // return moment(date).format('l')
  return moment(date)
}
// 1/12/2019
export function dateFormatToday() {
  // return moment().format('l')
  return moment().startOf('hour')
}

export function isExpired(date) {
  return moment(date).isBefore(moment(), 'day')
}

export function noOfDays(a, b) {
  var c = moment([
    _toNumber(dateFormatToSend(a).split('-')[0]),
    _toNumber(dateFormatToSend(a).split('-')[1]) - 1,
    _toNumber(dateFormatToSend(a).split('-')[2])
  ]);
  var d = moment([
    _toNumber(dateFormatToSend(b).split('-')[0]),
    _toNumber(dateFormatToSend(b).split('-')[1]) - 1,
    _toNumber(dateFormatToSend(b).split('-')[2])
  ]);
  const days = d.diff(c, 'days')
  return days
}
