import fetch from 'node-fetch';
import Auth from '../Auth/Auth';
import * as ActionTypes from '../constants/ActionTypes';

const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

export function getAlertMsg(cityCode) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/information/alertmsg/get?citycode=${cityCode}`;

		dispatch({
			type: ActionTypes.GET_ALERT_MSG_REQUEST,
      endpoint
		})

    fetch(endpoint, {
			method: 'GET',
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if(result.status === "Success") {
          dispatch({
            type: ActionTypes.GET_ALERT_MSG_SUCCESS,
            payload: result.details ? result.details : [],
            cityCode: cityCode
          })
        } else {
          dispatch({
            type: ActionTypes.GET_ALERT_MSG_FAILURE,
            payload: null,
            msg:`Get alert message failed. note${result.message}</b>`,
            cityCode: cityCode
          })
        }
			})
			.catch((error) => {
				console.log('Get Alert Message error: ', error)
				dispatch({
					type: ActionTypes.GET_ALERT_MSG_FAILURE,
					error,
					payload: null,
          msg: `Get alert message failed. API error. ${error.message}`,
          cityCode: null
				})
			});
	};
}

export function deleteAlertMsg(autoNum) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/information/ManageAlertMsg/Delete`;
    const body = {
      "Detail":{
    		"autoNum": autoNum
    	}
    }

		dispatch({
			type: ActionTypes.DELETE_ALERT_MSG_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if(result.status === "Success") {
          dispatch({
            type: ActionTypes.DELETE_ALERT_MSG_SUCCESS,
            msg:`Delete alert message succeed.`,
          })
        } else {
          dispatch({
            type: ActionTypes.DELETE_ALERT_MSG_FAILURE,
            msg:`Delete alert message failed. <b>${result.message}</b>`,
          })
        }
			})
			.catch((error) => {
				console.log('Delete alert message error. ', error)
				dispatch({
					type: ActionTypes.DELETE_ALERT_MSG_FAILURE,
					error,
          msg: `Delete alert message failed. API error. ${error.message}`,
				})
			});
	};
}

export function createMsg(cityCode, form) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/information/ManageAlertMsg/Create`;
    const body = {
      "Detail":{
        "CityCode": cityCode,
      	"StartDate": form.StartDate,
      	"EndDate": form.EndDate,
      	"Heading": form.Heading,
      	"Content": form.Content
    	}
    }

		dispatch({
			type: ActionTypes.CREATE_ALERT_MSG_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if(result.status === "Success") {
          dispatch({
            type: ActionTypes.CREATE_ALERT_MSG_SUCCESS,
            msg:`Create alert succeed.`,
          })
        } else {
          dispatch({
            type: ActionTypes.CREATE_ALERT_MSG_FAILURE,
            msg:`Create alert message failed. <b>${result.message}</b>`,
          })
        }
			})
			.catch((error) => {
				console.log('Delete alert message error. ', error)
				dispatch({
					type: ActionTypes.CREATE_ALERT_MSG_FAILURE,
					error,
          msg: `Create alert message failed. API error. ${error.message}`,
				})
			});
	};
}

export function updateAlertMsg(cityCode, autoNum, form) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/information/ManageAlertMsg/Update`;
    const body = {
      "Detail":{
        "autoNum": autoNum,
        "cityCode": cityCode,
      	"startDate": form.startDate,
      	"endDate": form.endDate,
      	"heading": form.heading,
      	"content": form.content
    	}
    }

		dispatch({
			type: ActionTypes.UPDATE_ALERT_MSG_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if(result.status === "Success") {
          dispatch({
            type: ActionTypes.UPDATE_ALERT_MSG_SUCCESS,
            msg:`Update alert message succeed.`,
          })
        } else {
          dispatch({
            type: ActionTypes.UPDATE_ALERT_MSG_FAILURE,
            msg:`Update alert message failed. <b>${result.message}</b>`,
          })
        }
			})
			.catch((error) => {
				console.log('Update alert message error: ', error)
				dispatch({
					type: ActionTypes.UPDATE_ALERT_MSG_FAILURE,
					error,
          msg: `Update alert message failed. API error. ${error.message}`,
				})
			});
	};
}

export function clearAlertMsgRecords() {
  return (dispatch) => {
    dispatch({
			type: ActionTypes.CLEAR_ALERT_MSG_RECORDS
		})
	};
}
