import React, {Component} from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownload, faBan} from '@fortawesome/free-solid-svg-icons';
import {Form, Button, Row, Col, Tab, ListGroup} from 'react-bootstrap';

import Title from '../components/Title';
import CustomDateRangePicker from '../components/CustomDateRangePicker';
import TypeCodeMultiSelect from '../components/TypeCodeMultiSelect';
import CountrySelect from '../components/CountrySelect';
import CityNameSelect from '../components/CityNameSelect';
import Loading from '../components/Loading';
import {dateFormatToSend} from '../util/transformDate.js'
import FileSaver from 'file-saver';
import Auth from '../Auth/Auth';

import cogoToast from 'cogo-toast';

import _get from 'lodash/get';
import _extend from 'lodash/extend';
import _concat from 'lodash/concat';
import _pull from 'lodash/pull';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';

const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

class Report extends Component {
  constructor(props) {
    super(props);
    this.onDateSelect = this.onDateSelect.bind(this);
    this.onCheckboxClick = this.onCheckboxClick.bind(this);
    this.onTypeClick = this.onTypeClick.bind(this);
    this.onTypeCodeSelect = this.onTypeCodeSelect.bind(this);
    this.onSelectClick = this.onSelectClick.bind(this);
    this.onDownloadClick = this.onDownloadClick.bind(this);
    this.onCompanyTypeReserveSelect = this.onCompanyTypeReserveSelect.bind(this);
    this.onCompanyTypeCancelSelect = this.onCompanyTypeCancelSelect.bind(this);

    let cType = this.getDftCompanyType();
    this.state = {
      criteria: {
        dateFrom: '', //'2019-02-09',
        dateTo: '', //'2019-03-09',
        type: 'DEADLINE',
        typeCode: [],
        countryCode: '',
        cityCode: '',
        status: [1, 3],
        companyTypeReserve: cType,
        companyTypeCancel: cType,
      },
      isConfirm: true,
      isRequest: true,
      isLoading: false,
      currentTab: 'arrival',
    };
  }

  componentDidUpdate(prevProps, prevState) {
		if (!this.state.isConfirm &&!this.state.isRequest) {
			this.setBookingStatus()
		}
	}

  onTypeClick(type) {
    this.setState({
      criteria: _extend(this.state.criteria, {
        type: type
      })
    })
  }

  onTypeCodeSelect(typeCode) {
    let typeCodes = _map(typeCode, (r) => r.value)

    this.setState({
      criteria: _extend(this.state.criteria, {
        typeCode: typeCodes
      })
    })
  }

  onCompanyTypeReserveSelect(e) {
    this.setState({
      criteria: _extend(this.state.criteria, {
        companyTypeReserve: e.target.value
      })
    })
  }

  onCompanyTypeCancelSelect(e) {
    this.setState({
      criteria: _extend(this.state.criteria, {
        companyTypeCancel: e.target.value
      })
    })
  }

  onSelectClick(code, name) {
    this.setState({
      criteria: _extend(this.state.criteria, {
        [name]: code
      })
    })
  }

  onCheckboxClick(e, val) {
		let fieldName = e.target.name
		let isFieldChecked = e.target.checked

		let BookingStatusArr = this.state.criteria.status
		let newBokingStatusArr = []
		if (isFieldChecked) {
			newBokingStatusArr = _concat(BookingStatusArr, val)
		} else {
			newBokingStatusArr = _pull(BookingStatusArr, val)
		}

		this.setState({
			[fieldName]: isFieldChecked,
			criteria: _extend(this.state.criteria, {
				"status": newBokingStatusArr
			})
		})

	}

  onDateSelect(name, i, startDateValue, endDateValue) {
		this.setState({
			criteria: _extend(this.state.criteria, {
        dateFrom: dateFormatToSend(startDateValue),
				dateTo: dateFormatToSend(endDateValue),
			})
		})
	}

  onDownloadClick() {
    let reportName = 'ArrivalReport'
    let apiName = 'ArrivalReport'
    if (this.state.currentTab === 'arrival') {
      reportName = 'ArrivalReport'
      apiName = 'ArrivalReport'
    } else if (this.state.currentTab === 'prepayment') {
      reportName = 'PrepaymentReport'
      apiName = 'PrepaymentReport'
    } else if (this.state.currentTab === 'pendingBooking') {
      reportName = 'PendingBookingQueueReport'
      apiName = 'PendingBookingQueue'
    } else if (this.state.currentTab === 'pendingVoucher') {
      reportName = 'PendingVoucherQueueReport'
      apiName = 'PENDINGVOUCHERQUEUE'
    } else if (this.state.currentTab === 'instanceAllotment') {
      reportName = 'InstanceAllotment'
      apiName = 'InstanceAllotment'
    } else if (this.state.currentTab === 'problemBookingAlertReserve') {
      reportName = 'ProblemBookingAlertReserve'
      apiName = 'PROBLEMBOOKINGALERTRESERVE'
    } else if (this.state.currentTab === 'problemBookingAlertCancel') {
      reportName = 'ProblemBookingAlertCancel'
      apiName = 'PROBLEMBOOKINGALERTCANCEL'
    }
    const endpoint = `${currentServerURL}/Reports/AD/${apiName}`;

    let body = {}
    let isValid = true

    if (this.state.currentTab === 'arrival') {
      isValid = this.state.criteria.dateFrom && this.state.criteria.dateTo && this.state.criteria.typeCode.length > 0

      let fromWord
      let toWord
      if (this.state.criteria.type === 'DEADLINE') {
        fromWord = 'DeadlineFrom'
        toWord = 'DeadlineTo'
      } else if (this.state.criteria.type === 'BOOKINGDATE') {
        fromWord = 'BookingDateFrom'
        toWord = 'BookingDateTo'
      } else if (this.state.criteria.type === 'ARRIVALDATE') {
        fromWord = 'ArrivalDateFrom'
        toWord = 'ArrivalDateTo'
      }

      body = {
        "TypeCodes": this.state.criteria.typeCode,
        [fromWord] : this.state.criteria.dateFrom,
        [toWord] : this.state.criteria.dateTo,
        "Status": this.state.criteria.status,
        "Type": this.state.criteria.type,
        "CountryCode": this.state.criteria.countryCode,
        "CityCode": this.state.criteria.cityCode,
      }
    } else if (this.state.currentTab === 'prepayment') {
      isValid = this.state.criteria.dateFrom && this.state.criteria.dateTo && this.state.criteria.typeCode.length > 0
      body = {
        "TypeCodes": this.state.criteria.typeCode,
        "ArrFrom": this.state.criteria.dateFrom,
        "ArrTo": this.state.criteria.dateTo,
        "Type": this.state.criteria.type
      }
    } else if (this.state.currentTab === 'pendingVoucher') {
      isValid = this.state.criteria.dateFrom && this.state.criteria.dateTo
      body = {
        "ArrFrom": this.state.criteria.dateFrom,
        "ArrTo": this.state.criteria.dateTo
      }
    } else if (this.state.currentTab === 'instanceAllotment') {
      isValid = this.state.criteria.dateFrom && this.state.criteria.dateTo
      body = {
        "BookingDateFrom": this.state.criteria.dateFrom,
        "BookingDateTo": this.state.criteria.dateTo
      }
    } else if (this.state.currentTab === 'problemBookingAlertReserve') {
      isValid = this.state.criteria.dateFrom && this.state.criteria.dateTo && this.state.criteria.companyTypeReserve !== ''
      body = {        
        "StartDate": this.state.criteria.dateFrom,
        "EndDate": this.state.criteria.dateTo,
        "TypeCode": this.state.criteria.companyTypeReserve
      }
    } else if (this.state.currentTab === 'problemBookingAlertCancel') {
      isValid = this.state.criteria.dateFrom && this.state.criteria.dateTo && this.state.criteria.companyTypeCancel !== ''
      body = {        
        "StartDate": this.state.criteria.dateFrom,
        "EndDate": this.state.criteria.dateTo,
        "TypeCode": this.state.criteria.companyTypeCancel
      }
    } 

    if (isValid) {
      this.setState({
        isLoading: true,
      })

      fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          Authorization: 'Bearer ' + auth.getAccessToken(),
          'Content-Type': 'application/json',
          'Accept-Encoding': 'zip, deflate, br'
        }
      }).then(res => {
          this.setState({ isLoading: false });
          if (res.status === 401) {
            cogoToast.info(`${res.status} + 'Error'`)
          }
          return res.blob();
      }).then(
          rs => {
              var blob = new Blob([rs], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
              if (
                this.state.currentTab === 'pendingBooking'
                || (this.state.currentTab !== 'pendingBooking' && blob.size !== 0)
              ) {
                FileSaver.saveAs(blob, `${reportName}_${this.state.criteria.dateFrom}_${this.state.criteria.dateTo}_${this.state.criteria.countryCode}_${this.state.criteria.typeCode.join('_')}.xls`);
              } else {
                cogoToast.info('No contents for this criteria.')
              }

          },
          er => {
              this.setState({
                isLoading: false,
              })
              cogoToast.error(`Error:  + ${er}`)
          }
      )
      .catch((error) => {
        // console.log('search Report error: ', error)
        this.setState({
          isLoading: false,
        })
        cogoToast.error(`search Report error: , ${error}`)

      });
    } else {
      cogoToast.error('Please input search criteria.')
    }
  }

  setBookingStatus() {
    this.setState({
			isConfirm: true,
			isRequest: true,
			criteria: _extend(this.state.criteria, {
				"status": [1, 3],
			})
		})
	}

  onTabSelect(eventKey) {
    let cType = this.getDftCompanyType();
    this.setState({
      criteria: {
        dateFrom: '', //'2019-02-09',
        dateTo: '', //'2019-03-09',
        type: eventKey === 'prepayment' ? 'PREPAY' : 'DEADLINE',
        countryCode: '',
        typeCode: [],
        status: [1, 3],
        companyTypeReserve: cType,
        companyTypeCancel: cType,
      },
      isConfirm: true,
      isRequest: true,
      currentTab: eventKey
    })
  }

  getDftCompanyType() {
    if (this.props.profileInfo && this.props.profileInfo.typeCode) {
      if (this.props.profileInfo.typeCode.toUpperCase() === 'HKG1') {
        return 'HWP'
      }
      else if (this.props.profileInfo.typeCode.toUpperCase() === 'LTS1') {
        return 'LTS'
      }
    }
    return 'ALL';
  }

  render () {
    let navInfo = []
    if (this.props.profileInfo) {
			navInfo = [
				{ name: 'Arrival Report', eventKey: 'arrival', isShow: this.props.profileInfo.accessControl.HFS_RPT_ARRIVAL === 'Y' },
				{ name: 'Prepayment Report', eventKey: 'prepayment', isShow: this.props.profileInfo.accessControl.HFS_RPT_PREPAYMENT === 'Y' },
				{ name: 'Pending Booking Queue Report', eventKey: 'pendingBooking', isShow: this.props.profileInfo.accessControl.HFS_RPT_PENDINGBKGQUEUE === 'Y' },
				{ name: 'Pending Voucher Queue Report', eventKey: 'pendingVoucher', isShow: this.props.profileInfo.accessControl.HFS_RPT_PENDINGVCHQUEUE === 'Y' },
				{ name: 'Instance Allotment Report', eventKey: 'instanceAllotment', isShow: this.props.profileInfo.accessControl.HFS_RPT_INSTANCEALLOTMENT === 'Y' },
        { name: 'Problem Booking Alert Reserve Report', eventKey: 'problemBookingAlertReserve', isShow: this.props.profileInfo.accessControl.HFS_RPT_PROBLEMBOOKINGRESERVE === 'Y' },
        { name: 'Problem Booking Alert Cancel Report', eventKey: 'problemBookingAlertCancel', isShow: this.props.profileInfo.accessControl.HFS_RPT_PROBLEMBOOKINGCANCEL === 'Y' },
			]
    }
    let companyTypes = [
      {value: 'ALL', label: 'ALL'},
			{value: 'HWP', label: 'HWP'},
			{value: 'LTS', label: 'LTS'},
      {value: 'JTB', label: 'JTB'},
      {value: 'HHT', label: 'HHT'},
		]
    return (
      <div>
        <Title title="Report" browserTitle="Report"/>
        <div className="mt-5">
          {this.props.profileInfo && _findIndex(navInfo, 'isShow') !== -1 ?
            <Tab.Container id="report-tabs" defaultActiveKey={this.props.profileInfo && _find(navInfo, 'isShow').eventKey} onSelect={(eventKey ) => this.onTabSelect(eventKey )}>
              <Row className="justify-content-center">
                <Col xs="auto" className="pr-0">
                  <ListGroup defaultActiveKey={this.props.profileInfo && _find(navInfo, 'isShow').eventKey} className="text-right vertical-tab-menu">
                    {navInfo.map((nav, i) =>
                      nav.isShow &&
                      <ListGroup.Item action eventKey={nav.eventKey} className="border-right-0" key={i} >
                        {nav.name}
                      </ListGroup.Item>
                    )}
                  </ListGroup>
                </Col>
                <Col xs="6" className="border">
                  <Tab.Content className="p-4">
                    <Tab.Pane eventKey="arrival">
                      <Form>
                        <Form.Group>
                          <Row>
                            <Col xs="3"><Form.Label>Type</Form.Label></Col>
                            <Col xs="9">
                              <Form.Check
                                type="radio"
                                id="checkbox-type-deadline"
                                checked={this.state.criteria.type === 'DEADLINE'}
                                label="Deadline"
                                className="mr-5"
                                onChange={() => this.onTypeClick('DEADLINE')}
                                name="type"
                              />
                              <Form.Check
                                type="radio"
                                id="checkbox-type-bookingdate"
                                checked={this.state.criteria.type === 'BOOKINGDATE'}
                                label="Booking date"
                                onChange={() => this.onTypeClick('BOOKINGDATE')}
                                name="type"
                              />
                              <Form.Check
                                type="radio"
                                id="checkbox-type-arrivaldate"
                                checked={this.state.criteria.type === 'ARRIVALDATE'}
                                label="Arrival date"
                                onChange={() => this.onTypeClick('ARRIVALDATE')}
                                name="type"
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group>
                          <Row>
                            <Col xs="3"><Form.Label>
                            *{this.state.criteria.type === 'DEADLINE' && 'Deadline'}{this.state.criteria.type === 'BOOKINGDATE' && 'Booking'}{this.state.criteria.type === 'ARRIVALDATE' && 'Arrival'} Period</Form.Label></Col>
                            <Col xs="9">
                              <CustomDateRangePicker
                                handleSelect={this.onDateSelect}
                                name={this.state.type}
                                startDateValue={_get(this.state.criteria, 'dateFrom')}
                                endDateValue={_get(this.state.criteria, 'dateTo')}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group>
                          <Row>
                            <Col xs="3"><Form.Label>*Company</Form.Label></Col>
                            <Col xs="9">
                              <TypeCodeMultiSelect
                                handleSelect={this.onTypeCodeSelect}
                                preValue={_get(this.state.criteria, 'typeCode')}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group>
                          <Row>
                            <Col xs="3"><Form.Label>Country Code</Form.Label></Col>
                            <Col xs="9">
                              <CountrySelect
                                handleSelect={this.onSelectClick}
                                selectedCode={this.state.criteria.countryCode}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group>
                          <Row>
                            <Col xs="3"><Form.Label>City Code</Form.Label></Col>
                            <Col xs="9">
                              <CityNameSelect
                                handleSelect={this.onSelectClick}
                                selectedCode={this.state.criteria.cityCode}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group>
                          <Row>
                            <Col xs="3"><Form.Label>Status</Form.Label></Col>
                            <Col xs="9">
                              <Form.Check
                                type="checkbox"
                                id="checkbox-confirm"
                                label="Confirm (KK-HK-AA)"
                                className="mr-5"
                                checked={this.state.isConfirm}
                                onChange={(e) => this.onCheckboxClick(e, 1)}
                                name="isConfirm"
                              />
                              <Form.Check
                                type="checkbox"
                                id="checkbox-request"
                                label="Request (RQ-HN)"
                                checked={this.state.isRequest}
                                onChange={(e) => this.onCheckboxClick(e, 3)}
                                name="isRequest"
                              />
                            </Col>
                          </Row>
                        </Form.Group>

                        <div className="text-center py-3">
                          <Button onClick={() => this.onDownloadClick()}><FontAwesomeIcon icon={faDownload} className="mr-2" />Search & Download</Button>
                        </div>
                      </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="prepayment">
                      <Form>
                        <Form.Group>
                          <Row>
                            <Col xs="3"><Form.Label>Type</Form.Label></Col>
                            <Col xs="9">
                              <Form.Check
                                type="radio"
                                id="checkbox-type-prepay"
                                checked={this.state.criteria.type === 'PREPAY'}
                                label="Prepay"
                                className="mr-5"
                                onChange={() => this.onTypeClick('PREPAY')}
                                name="type"
                              />
                              <Form.Check
                                type="radio"
                                id="checkbox-type-credit"
                                checked={this.state.criteria.type === 'CREDIT'}
                                label="Credit"
                                onChange={() => this.onTypeClick('CREDIT')}
                                name="type"
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group>
                          <Row>
                            <Col xs="3"><Form.Label>Arrival Period</Form.Label></Col>
                            <Col xs="9">
                              <CustomDateRangePicker
                                handleSelect={this.onDateSelect}
                                name={this.state.type}
                                startDateValue={_get(this.state.criteria, 'dateFrom')}
                                endDateValue={_get(this.state.criteria, 'dateTo')}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group>
                          <Row>
                            <Col xs="3"><Form.Label>Company</Form.Label></Col>
                            <Col xs="9">
                              <TypeCodeMultiSelect
                                handleSelect={this.onTypeCodeSelect}
                                preValue={_get(this.state.criteria, 'typeCode')}
                              />
                            </Col>
                          </Row>
                        </Form.Group>

                        <div className="text-center py-3">
                          <Button onClick={() => this.onDownloadClick()}><FontAwesomeIcon icon={faDownload} className="mr-2" />Search & Download</Button>
                        </div>
                      </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="pendingBooking">
                      <div className="text-center py-5">
                        <Button onClick={() => this.onDownloadClick()}><FontAwesomeIcon icon={faDownload} />  Download</Button>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="pendingVoucher">
                      <Form className="pt-3">
                        <Form.Group>
                          <Row>
                            <Col xs="3" className="col-form-label"><Form.Label>Arrival Period</Form.Label></Col>
                            <Col xs="9">
                              <CustomDateRangePicker
                                handleSelect={this.onDateSelect}
                                name={this.state.type}
                                startDateValue={_get(this.state.criteria, 'dateFrom')}
                                endDateValue={_get(this.state.criteria, 'dateTo')}
                              />
                            </Col>
                          </Row>
                        </Form.Group>

                        <div className="text-center py-3">
                          <Button onClick={() => this.onDownloadClick()}><FontAwesomeIcon icon={faDownload} className="mr-2" />Search & Download</Button>
                        </div>
                      </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="instanceAllotment">
                      <Form className="pt-3">
                        <Form.Group>
                          <Row>
                            <Col xs="3" className="col-form-label"><Form.Label>Date Period</Form.Label></Col>
                            <Col xs="9">
                              <CustomDateRangePicker
                                handleSelect={this.onDateSelect}
                                name={this.state.type}
                                startDateValue={_get(this.state.criteria, 'dateFrom')}
                                endDateValue={_get(this.state.criteria, 'dateTo')}
                              />
                            </Col>
                          </Row>
                        </Form.Group>

                        <div className="text-center py-3">
                          <Button onClick={() => this.onDownloadClick()}><FontAwesomeIcon icon={faDownload} className="mr-2" />Search & Download</Button>
                        </div>
                      </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="problemBookingAlertReserve">
                      <Form>
                        <Form.Group>
                          <Row>
                            <Col xs="3"><Form.Label>Start/End Period</Form.Label></Col>
                            <Col xs="9">
                              <CustomDateRangePicker
                                handleSelect={this.onDateSelect}
                                name={this.state.type}
                                startDateValue={_get(this.state.criteria, 'dateFrom')}
                                endDateValue={_get(this.state.criteria, 'dateTo')}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group>
                          <Row>
                            <Col xs="3"><Form.Label>Company Type</Form.Label></Col>
                            <Col xs="9">
                              <Form.Control
                                as="select"
                                name="companyTypeReserve"
                                onChange={this.onCompanyTypeReserveSelect}
                                value={this.state.criteria.companyTypeReserve}
                              >
                                {companyTypes.map((name, i) =>
                                  <option key={i} value={name.value}>{name.label}</option>
                                )}
                              </Form.Control>
                            </Col>
                          </Row>
                        </Form.Group>

                        <div className="text-center py-3">
                          <Button onClick={() => this.onDownloadClick()}><FontAwesomeIcon icon={faDownload} className="mr-2" />Search & Download</Button>
                        </div>
                      </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="problemBookingAlertCancel">
                      <Form>
                        <Form.Group>
                          <Row>
                            <Col xs="3"><Form.Label>Start/End Period</Form.Label></Col>
                            <Col xs="9">
                              <CustomDateRangePicker
                                handleSelect={this.onDateSelect}
                                name={this.state.type}
                                startDateValue={_get(this.state.criteria, 'dateFrom')}
                                endDateValue={_get(this.state.criteria, 'dateTo')}
                              />
                            </Col>
                          </Row>
                        </Form.Group>
                        <Form.Group>
                          <Row>
                            <Col xs="3"><Form.Label>Company Type</Form.Label></Col>
                            <Col xs="9">
                              <Form.Control
                                as="select"
                                name="companyTypeCancel"
                                onChange={this.onCompanyTypeCancelSelect}
                                value={this.state.criteria.companyTypeCancel}
                              >
                                {companyTypes.map((name, i) =>
                                  <option key={i} value={name.value}>{name.label}</option>
                                )}
                              </Form.Control>
                            </Col>
                          </Row>
                        </Form.Group>

                        <div className="text-center py-3">
                          <Button onClick={() => this.onDownloadClick()}><FontAwesomeIcon icon={faDownload} className="mr-2" />Search & Download</Button>
                        </div>
                      </Form>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>:
            <div className="text-center"><FontAwesomeIcon icon={faBan} className="mr-2" />Report is not available.</div>
          }
        </div>

        {this.state.isLoading && <Loading />}
      </div>
    );
  }
}

function mapStateToProps(state) {
	return {
		profileInfo: state.general.profileInfo,
	}
}

export default connect(mapStateToProps, null)(Report);
