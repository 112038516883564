import fetch from 'node-fetch';
import Auth from '../Auth/Auth';
import * as ActionTypes from '../constants/ActionTypes';

const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

export function getProfile() {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/Information/GetProfile?containAccessControl=true`;

		dispatch({
			type: ActionTypes.GET_PROFILE_REQUEST,
		})

    fetch(endpoint, {
			method: 'GET',
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.status === "Success") {
          dispatch({
            type: ActionTypes.GET_PROFILE_SUCCESS,
            payload: result
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_PROFILE_FAILURE,
  					payload: null,
            msg: `Get profile failed. <b>${result.message}</b>`
  				})
        }
			})
			.catch((error) => {
				console.log('getProfile error: ', error)
				dispatch({
					type: ActionTypes.GET_PROFILE_FAILURE,
					error,
					payload: null,
          msg: `Get profile failed. API issue.`
				})
			});
	};
}

export function getUnreadMsg() {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/Bookings/GetHotelMessage/UNREAD`;

		dispatch({
			type: ActionTypes.GET_UNREAD_MSG_REQUEST,
		})

    fetch(endpoint, {
			method: 'GET',
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        dispatch({
          type: ActionTypes.GET_UNREAD_MSG_SUCCESS,
          payload: result
        })
			})
			.catch((error) => {
				console.log('getUnreadMsg error: ', error)
				dispatch({
					type: ActionTypes.GET_UNREAD_MSG_FAILURE,
					error,
					payload: null,
          msg: `Get unread message failed. API issue.`
				})
			});
	};
}

export function clearMsg() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CLEAR_MSG,
      payload: null
    })
  }
}

export function getCurrencyList(txNo, seqnum) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/information/GetCurrencyList`;

		dispatch({
			type: ActionTypes.GET_CURRENCY_LIST_REQUEST,
		})

    fetch(endpoint, {
			method: 'GET',
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result) {
          dispatch({
            type: ActionTypes.GET_CURRENCY_LIST_SUCCESS,
            payload: result
          })
        } else {
          dispatch({
  					type: ActionTypes.GET_CURRENCY_LIST_FAILURE,
  					payload: null,
            msg: `Get currency list failed. <b>${result.message}</b>`
  				})
        }
			})
			.catch((error) => {
				console.log('getBookingDetail error: ', error)
				dispatch({
					type: ActionTypes.GET_CURRENCY_LIST_FAILURE,
					error,
					payload: null,
          msg: `Get currency list failed. API issue.`
				})
			});
	};
}
