import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Row, Col} from 'react-bootstrap';

import _find from 'lodash/find';
import {prettySingleDate, prettyDateRange} from '../../util/transformDate.js';

const ConfirmBookingModal = (props) => {
	const isAppendingBooking = (props.txno !== '')
	return (
		<Modal show={props.isOpen} centered onHide={props.handleCloseModal} size="lg">
		  <Modal.Header closeButton>
		    <Modal.Title>Confirm Manual Booking</Modal.Title>
		  </Modal.Header>

		  <Modal.Body>

				<Row className="form-group">
					<Col xs={3} className="detail-label">Type</Col>
					<Col xs={9}>
						{isAppendingBooking ? 'Appending Booking' : 'New Booking'}
					</Col>
				</Row>

				{isAppendingBooking &&
					<Row className="form-group">
						<Col xs={3} className="detail-label">Booking Number</Col>
						<Col xs={9}>
							{props.txno}
						</Col>
					</Row>
				}

				<hr />

				{!isAppendingBooking &&
					<Row className="form-group">
						<Col xs={3} className="detail-label">Guest</Col>
						<Col xs={9}>
							<table className="table table-bordered mb-0">
								<thead>
									<tr className="small">
										<th>#</th>
										<th>Last Name</th>
										<th>First Name</th>
										<th>Title</th>
										<th>Age(yr)</th>
									</tr>
								</thead>
								<tbody>
									{props.bookingInfo.passengers.map((pax, i) =>
										<tr key={i}>
											<td>{i+1}</td>
											<td>{pax.lastName}</td>
											<td>{pax.firstName}</td>
											<td>{pax.prefix}</td>
											<td>{!pax.isAdult && pax.age}</td>
										</tr>
									)}
								</tbody>
							</table>
						</Col>
					</Row>
				}

				<Row className="form-group">
					<Col xs={3} className="detail-label">Cancel Deadline</Col>
					<Col xs={9}>
						{prettySingleDate(props.bookingInfo.xxDeadline)}
					</Col>
				</Row>

				<Row className="form-group">
					<Col xs={3} className="detail-label">Supplier code</Col>
					<Col xs={9}>
						{props.selectedSupplierCodeName}
					</Col>
				</Row>

				<Row className="form-group">
					<Col xs={3} className="detail-label">Rate Type</Col>
					<Col xs={9}>
						{props.bookingInfo.rateType}
					</Col>
				</Row>

				<hr />

				<Row className="form-group">
					<Col xs={3} className="detail-label">Hotel</Col>
					<Col xs={9}>
						{props.selectedHotelCodeName}
					</Col>
				</Row>

				<Row className="form-group">
					<Col xs={3} className="detail-label">Service Details</Col>
					<Col xs={9}>
						<div>
							<table className="table table-bordered mb-0">
								{props.bookingInfo.items.map((item, i) =>
                  <tbody key={i}>
										<tr>
											<td width="43" rowSpan="2">#{i + 1}</td>
											<td><div className="small text-muted">Item Type</div>{_find(props.itemType, {'value' : item.itemType}).key}</td>
											<td colSpan="2"><div className="small text-muted">Description</div>{item.qty} x {item.itemName}</td>
										</tr>
										<tr>
											<td><div className="small text-muted">Date</div>{prettyDateRange(item.startDate, item.endDate)}</td>
											<td><div className="small text-muted">Sell</div>{item.sell.currencyCode} {item.sell.value}</td>
											<td><div className="small text-muted">Cost</div>{item.cost.currencyCode} {item.cost.value}</td>
										</tr>
									</tbody>
								)}
							</table>
						</div>
					</Col>
				</Row>

				<Row className="form-group">
					<Col xs={3} className="detail-label">Hotel reference</Col>
					<Col xs={9}>
						{props.bookingInfo.hotelRef}
					</Col>
				</Row>

				<Row className="form-group">
					<Col xs={3} className="detail-label">Booking Remarks</Col>
					<Col xs={9}>
						<div>{props.bookingInfo.note1}</div>
						<div>{props.bookingInfo.note2}</div>
					</Col>
				</Row>

		  </Modal.Body>

		  <Modal.Footer>
				<div className="text-center w-100">
					<Button variant="info" size="lg" onClick={props.handleSubmitClick}>Confirm Create Manual Booking</Button>
				</div>
		  </Modal.Footer>
		</Modal>
  );
}

ConfirmBookingModal.propTypes = {
	bookingInfo: PropTypes.object,
	txno: PropTypes.string,
	isOpen: PropTypes.bool,
	handleCloseModal: PropTypes.func,
	handleSubmitClick: PropTypes.func,
	selectedSupplierCodeName: PropTypes.string,
	selectedHotelCodeName: PropTypes.string,
	itemType: PropTypes.array,
};

export default ConfirmBookingModal;
