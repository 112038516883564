import fetch from 'node-fetch';
import Auth from '../Auth/Auth';
import * as ActionTypes from '../constants/ActionTypes';

const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

export function searchBookingNotIssue(criteria) {
  return (dispatch) => {
    const endpoint = `${currentServerURL}/Bookings/GetBookingSummary_NotIssue`;
    const body = {
      "Criteria": criteria
    }

		dispatch({
			type: ActionTypes.SEARCH_BOOKING_NOTISSUE_REQUEST,
      body: body
		})

    fetch(endpoint, {
			method: 'POST',
      body: JSON.stringify(body),
			headers: {
				Authorization: 'Bearer ' + auth.getAccessToken(),
				'Content-Type': 'application/json',
        'Accept-Encoding': 'zip, deflate, br'
			}
		})
			.then(response => response.json())
			.then((result) => {
        if (result.status === "Success") {
          dispatch({
            type: ActionTypes.SEARCH_BOOKING_NOTISSUE_SUCCESS,
            payload: result,
            criteria: criteria
          })
        } else{
          dispatch({
  					type: ActionTypes.SEARCH_BOOKING_NOTISSUE_FAILURE,
  					payload: result,
            msg: `Search booking failed. <b>${result.message}</b>`
  				})
        }
			})
			.catch((error) => {
				console.log('searchBooking_NotIssue error: ', error)
				dispatch({
					type: ActionTypes.SEARCH_BOOKING_NOTISSUE_FAILURE,
					error,
					payload: null,
          msg: `Search booking(Not Issue) failed. API issue.`
				})
			});
	};
}

export function changeNotIssuePage(val) {
  return (dispatch) => {
		dispatch({
			type: ActionTypes.CHANGE_NOTISSUE_PAGE,
      payload: val
		})
	};
}

export function clearBookingSearchNotIssueResult() {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CLEAR_BOOKING_SEARCH_NOTISSUE_RESULT
    })
  }
}
