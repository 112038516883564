import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckDouble, faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import TextareaAutosize from 'react-autosize-textarea';

import Pagination from '../Pagination';
import _extend from 'lodash/extend';
import _get from 'lodash/get';
import _chunk from 'lodash/chunk';

import {prettyDateTime} from '../../util/transformDate.js';

const chunkSize = 20;

class TabMsg extends Component {
	constructor(props) {
		super(props);
		this.onFormChange = this.onFormChange.bind(this)
		this.onReadClick = this.onReadClick.bind(this)
		this.onReplyClick = this.onReplyClick.bind(this)
		this.onDeleteClick = this.onDeleteClick.bind(this)
		this.onPageChange = this.onPageChange.bind(this)
		this.onRestClick = this.onRestClick.bind(this)
		this.onSendClick = this.onSendClick.bind(this)

		this.state = {
			form: {
				subject: '',
				detail: ''
			},
			currentPage: 0
		};

	}

	onFormChange(e) {
		let key = e.target.name
		let value = e.target.value

		this.setState({
			form: _extend(this.state.form, {
				[key]: (value)
			})
		})
	}

	onKeyDown(e) {
		if (e.key === 'Enter') {
			this.onSendClick()
		}
	}

	onReadClick(id) {
		this.props.handleReadMsg(
			this.props.bookingDetail.get('typeCode'),
			this.props.bookingDetail.get('loginCode'),
			this.props.bookingDetail.get('txno'),
			this.props.bookingDetail.get('seqnum'),
			id
		)
	}

	onDeleteClick(id) {
		this.props.handleDeleteMsg(
			this.props.bookingDetail.get('typeCode'),
			this.props.bookingDetail.get('loginCode'),
			this.props.bookingDetail.get('txno'),
			this.props.bookingDetail.get('seqnum'),
			id
		)
	}

	onRestClick() {
		this.setState({
			form: {
				subject: '',
				detail: ''
			}
		})
	}

	onReplyClick(subject) {
		this.setState({
			form: _extend(this.state.form, {
				subject: `RE: ${subject}`
			})
		})
	}

	onPageChange(page) {
		this.setState({
			currentPage: page
		})
	}

	onSendClick() {
		this.props.handleCreateMsg(
			this.props.bookingDetail.get('txno'),
			this.props.bookingDetail.get('seqnum'),
			this.state.form
		);
	}


	render() {
	  const hasOtherTxno = (this.props.bookingDetail.get('otherTxno') !== null)
		const hasUnDeletedMsg = this.props.htlMessages && this.props.htlMessages.filter(msg => !msg.deleted).length > 0
		const unDeletedMsg = this.props.htlMessages && this.props.htlMessages.filter(msg => !msg.deleted)
		return (
			<div className="mt-4">
				{hasUnDeletedMsg &&
					<p>{unDeletedMsg.length} record(s)</p>
			}
				<table className={`table table-bordered ${hasUnDeletedMsg ? 'table-striped' : ''}`}>
					<thead>
						<tr>
							<th width="45">#</th>
							<th width="110">Sender</th>
							<th width="200">Subject</th>
							<th>Detail</th>
							<th width="230">Send Date</th>
							<th width="70">Read</th>
							{hasOtherTxno && <th width="90">Reply</th>}
							<th width="90"></th>
						</tr>
					</thead>
					<tbody>
						{hasUnDeletedMsg ?
							_chunk(unDeletedMsg, chunkSize)[this.state.currentPage].map((msg, i) =>
								(!_get(msg, 'deleted') &&
										<tr key={i} className={_get(msg, 'read') ? '' : 'font-weight-bold'}>
											<td>{unDeletedMsg.indexOf(msg) + 1}</td>
											<td>{_get(msg, 'from')}</td>
											<td>{_get(msg, 'subject')}</td>
											<td>
												<div className="pre-line" dangerouslySetInnerHTML={{ __html: _get(msg, 'detail')}}></div>
											</td>
											<td>{prettyDateTime(_get(msg, 'sendDate'))}</td>
											<td>
												{_get(msg, 'read') ?
													<div className="pl-2"><FontAwesomeIcon icon={faCheckDouble} size='lg' className="read-blue" /></div>:
													<button className="btn pl-2" onClick={() => this.onReadClick(_get(msg, 'id'))}>
														<FontAwesomeIcon icon={faCheckCircle} size='lg' />
													</button>
												}

											</td>
											{hasOtherTxno &&
												<td>
													<button className="btn btn-outline-secondary" onClick={() => this.onReplyClick(_get(msg, 'subject'))}>Reply</button>
												</td>
											}
											<td>
												<button className="btn btn-outline-secondary" onClick={() => this.onDeleteClick(_get(msg, 'id'))}>Delete</button>
											</td>
										</tr>
								)
							) :
							<tr><td colSpan="8">
								<div className="text-center py-5">
									No messages.
								</div>
							</td></tr>
						}
					</tbody>
				</table>
				{_chunk(unDeletedMsg, chunkSize).length > 1 &&
					<Pagination
						handlePageChange={this.onPageChange}
						noOfPages={_chunk(unDeletedMsg, chunkSize).length}
						currentPage={this.state.currentPage}
					/>
				}
				{hasOtherTxno &&
				  <div className="pb-4 mt-5">
				    <div className="row justify-content-center">
				      <div className="col-lg-6 pt-4">
				        <div>
				          <div className="form-group row">
				            <div className="col-3">
											Booking No.
				            </div>
				            <div className="col-9">
											{this.props.bookingDetail.get('txno')} ({this.props.bookingDetail.get('seqnum')})
				            </div>
				          </div>
				          <div className="form-group row">
				            <div className="col-3">
											Send To
				            </div>
				            <div className="col-9">
											{this.props.bookingDetail.get('msgTo')}
				            </div>
				          </div>
				          <div className="form-group row">
				            <div className="col-3">
											<Form.Label htmlFor="subject">Subject</Form.Label>
				            </div>
				            <div className="col-9">
											<Form.Control
												type="text"
												value={this.state.form.subject}
												id="subject"
												name="subject"
												onChange={(e) => this.onFormChange(e)}
												onKeyDown={(e) => this.onKeyDown(e)}
												maxLength={80}
											/>
				            </div>
				          </div>
				          <div className="form-group row">
				            <div className="col-3">
											<Form.Label htmlFor="detail">Detail</Form.Label>
				            </div>
				            <div className="col-9">
											<TextareaAutosize
												value={this.state.form.detail}
												name="detail"
												rows={10}
												className="form-control"
												onResize={(e) => {}}
												onChange={(e) => this.onFormChange(e)}
											/>
				            </div>
				          </div>

				          <div className="text-center mt-4">
				            <Button
											className="mr-4"
											variant="secondary"
											onClick={() => this.onSendClick()}
											disabled={!this.state.form.subject && !this.state.form.detail}
											size="lg"
										>Submit</Button>
				            <Button variant="outline-secondary" type="button" onClick={() => this.onRestClick()} size="lg">Reset</Button>
				          </div>
				        </div>
				      </div>
				    </div>
				  </div>
				}
			</div>

	  );
	}
}

TabMsg.propTypes = {
	bookingDetail: PropTypes.object,
	htlMessages: PropTypes.array,
	handleReadMsg: PropTypes.func,
	handleDeleteMsg: PropTypes.func,
	handleCreateMsg: PropTypes.func
};

export default TabMsg;
