import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PopUpModal from './PopUpModal';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBed, faUser, faCalendarAlt, faHistory} from '@fortawesome/free-solid-svg-icons';
import {prettyDateRange, prettySingleDate, isExpired} from '../../util/transformDate';
import Pagination from '../Pagination';

import _map from 'lodash/map';
import _size from 'lodash/size';
import {Media, Form} from 'react-bootstrap';
import cogoToast from 'cogo-toast';

class SearchNotIssueResult extends Component {
  constructor(props) {
    super(props);
    this.handleCancelBookingClick = this.handleCancelBookingClick.bind(this);
	this.handleBatchCancelBookingClick = this.handleBatchCancelBookingClick.bind(this);
	this.handleCheckBookingClick = this.handleCheckBookingClick.bind(this);
	this.handleCheckBookingAllClick = this.handleCheckBookingAllClick.bind(this);

  this.state = {
    currentPageData: this.getcurrentPageData(),
    checkAll: false,
    isPopUpModalOpen: false,
    action: null,
    cxlCriteria: {
      params: [],
      msg: null
    },
    cxlIsBatch: false
  }
}

  componentDidUpdate(prevProps, prevState) {
	if(prevState.isPopUpModalOpen){
      this.closeModal()
	}
	if(prevProps.currentPage !== this.props.currentPage
	|| prevProps.searchNotIssueResult !== this.props.searchNotIssueResult){
		this.setState({
			checkAll: false,
			currentPageData: this.getcurrentPageData()
		})
	}
  }

  getcurrentPageData(){
	let curPage = this.props.chunkedBookingRecords[this.props.currentPage];
	_map(curPage, c => c.isCheck = false);
	return curPage;
  }

  closeModal() {
    this.setState({
      isPopUpModalOpen: false,
      action: null
    })
  }

  handleCancelBookingClick(txNo, seqnum) {
    this.setState({
      isPopUpModalOpen: !this.state.isPopUpModalOpen,
      action: 'cancel',
      cxlIsBatch: false,
      cxlCriteria: {
        params: [
          {
            Txno: txNo,
            Seqnum: seqnum
          }
        ],
        msg: null
      }
    })
  }

  handleBatchCancelBookingClick() {
    let criteria = []
    _map(this.state.currentPageData, c => {
      if (c.isCheck) {
        criteria.push({
          "Txno": c.txNo,
          "Seqnum": c.seqnum,
        })
      }
    })
    if (criteria.length > 0) {
      this.setState({
        isPopUpModalOpen: !this.state.isPopUpModalOpen,
        action: 'cancel',
        cxlIsBatch: true,
        cxlCriteria: {
          params: criteria,
          msg: null
        },
      })
    } else {
      cogoToast.info('Please select the record(s)', { position: 'top-right' });
      return;
    }
  }

  handleCheckBookingClick(e, idx) {
	_map(this.state.currentPageData, (c, i) => {
		if(i === idx) c.isCheck = e.target.checked
	})
	this.setState({
		currentPageData: this.state.currentPageData
	})
  }

  handleCheckBookingAllClick(e) {
	_map(this.state.currentPageData, c => c.isCheck = e.target.checked)
	this.setState({
		checkAll: e.target.checked,
		currentPageData: this.state.currentPageData,
	})
  }
  
render () {
	return (
		<div className="px-2 py-4 result-wrapper">
			<h2 className="text-center">Search Results</h2>
			<p>{this.props.recordQuan} record(s) found</p>
			<table className={`table table-bordered`}>
				<thead>
					{this.props.hasResult && _size(this.state.currentPageData) > 0 ?
						<tr>
							<th className="align-middle text-center" title="check all(current page)">
								<Form.Check
									type="checkbox"
									id="checkbox-chkbkg-all"
									className="mr-0"
									checked={this.state.checkAll}
									name="isCheckAll"
									onChange={(e) => this.handleCheckBookingAllClick(e)}									
								/>
							</th>
							<th></th>
							<th>
								<button className="btn btn-outline-secondary btn-block py-3" onClick={() => this.handleBatchCancelBookingClick() } >Batch Cancel Booking</button>
							</th>
						</tr>
						: ""
					}
				</thead>
				<tbody>
					{this.props.hasResult ?
						_map(this.state.currentPageData, (result, i) =>
							{
								return (
									<tr key={i}>
										<td className="align-middle text-center">
											<div className='py-1'>
												{this.props.searchNotIssueResult.bookingDetails.indexOf(result) + 1}
											</div>
											<div>
												<Form.Check
													type="checkbox"
													className="mr-0"												
													checked={result.isCheck}
                                                    onChange={(e) => this.handleCheckBookingClick(e, i)}
													name="isCheck"
												/>
											</div>
										</td>
										<td className="px-0 pb-2 pt-0">

											<div className={`border-bottom h5 px-3 pb-1 pt-1 ${result.txNo.includes('W') ? 'w-booking' : 'one-booking'}`}  >
												{result.txNo} ({result.seqnum})												
											</div>
											<div className="row px-3">
												<div className="col-4">
													<Media>
														<FontAwesomeIcon icon={faBed} className="mt-2 mr-2 text-secondary" size="sm"/>
														<Media.Body>
															<span className="small text-secondary">Hotel</span>
															<div className="font-weight-bold">
																<a href={`http://www.adholidays.com/b2c/B2C/Information.aspx?Code=${result.hotelCode}&ViewType=I&P1=kyeD1E4l48A%3d&P2=hhoMxONrizqUj1DXussuAw%3d%3d`} target="_blank" rel="noopener noreferrer" className="link">{result.hotelName} ({result.cityCode})</a>
															</div>
														</Media.Body>
													</Media>
													<Media>
														<FontAwesomeIcon icon={faUser} className="mt-2 mr-2 text-secondary" size="sm" style={{width: "18px"}}/>
														<Media.Body>
															<span className="small text-secondary">Lead Guest Name</span>
															<div className="font-weight-bold">
																{result.leadGuestName}
															</div>
														</Media.Body>
													</Media>
												</div>
												<div className="col-3">
													<Media>
													  <FontAwesomeIcon icon={faCalendarAlt} className="mt-2 mr-2 text-secondary" size="sm" />
													  <Media.Body>
															<span className="small text-secondary">Arr-Dep</span>
															<div className="font-weight-bold">{prettyDateRange(result.checkIn, result.checkOut)}</div>
															<span className="small text-secondary">Cxl deadline</span>
															<div className={`font-weight-bold ${isExpired(result.bookingDeadline) ? 'red' : ''}`}>{prettySingleDate(result.bookingDeadline)}</div>
													  </Media.Body>
													</Media>
												</div>
												<div className="col-5">
													<Media>
														<FontAwesomeIcon icon={faHistory} className="mt-2 mr-2 text-secondary" size="sm" />
														<Media.Body>
															<span className="small text-secondary">Lastest History</span>
															<div className="font-weight-bold text-break">{result.notes}</div>
														</Media.Body>
													</Media>
												</div>												
											</div>
										</td>
										<td className="align-bottom text-center">
											<button className="btn btn-outline-info btn-block py-2" onClick={() => this.props.handleDetailClick(result.txNo, result.seqnum)}>Detail</button>
											<button className="btn btn-outline-secondary btn-block py-2" onClick={() => this.handleCancelBookingClick(result.txNo, result.seqnum)}>Cancel Booking</button>
										</td>
									</tr>
								)
							}
						) :
						<tr><td colSpan="11">
							<div className="text-center py-5">
								No record.
							</div>
						</td></tr>
					}
				</tbody>
			</table>
			{this.props.chunkedBookingRecords.length > 1 &&
				<Pagination
					handlePageChange={this.props.handlePageChange}
					noOfPages={this.props.chunkedBookingRecords.length}
					currentPage={this.props.currentPage}
				/>
			}
			{this.props.hasResult &&
			  <PopUpModal
              isOpen={this.state.isPopUpModalOpen}
              action={this.state.action}
              handleCloseModal={this.handleCancelBookingClick}
              handleCancelBooking={this.props.handleCancelBooking}
			  cxlCriteria={this.state.cxlCriteria}
			  cxlIsBatch={this.state.cxlIsBatch}
            />
			}
		</div>
  );
 }
}

SearchNotIssueResult.propTypes = {
	recordQuan: PropTypes.number,
	pageSize: PropTypes.number,
	chunkedBookingRecords: PropTypes.array,
	hasResult: PropTypes.bool,
	searchNotIssueResult: PropTypes.object,
	currentPage: PropTypes.number,
	handlePageChange: PropTypes.func,
	handleDetailClick: PropTypes.func,
	handleCancelBooking: PropTypes.func,
};

export default SearchNotIssueResult;
