import React from 'react';
import PropTypes from 'prop-types';

const Title = (props) => {
	return (
		<div className="pt-4">
			<title>{props.browserTitle} | Hotel Fulfillment System</title>
			<h1 className="text-center">{props.title}</h1>
		</div>
  );
}

Title.propTypes = {
	title: PropTypes.string,
	browserTitle: PropTypes.string,
};

export default Title;
