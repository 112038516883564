import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button} from 'react-bootstrap';

import {dateTimeFormatToSend} from '../../util/transformDate.js'

import CustomDatePicker from '../../components/CustomDatePicker';

import _get from 'lodash/get';
import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';
import _find from 'lodash/find';

class NewRosterModal extends Component {
	constructor(props) {
		super(props);

    this.onAddNewClick = this.onAddNewClick.bind(this);
		this.onNewRosterDateSelect = this.onNewRosterDateSelect.bind(this)
		this.onNewRosterLoginSelect = this.onNewRosterLoginSelect.bind(this)
		this.onToggleModal = this.onToggleModal.bind(this)
		this.onBlackdropClick = this.onBlackdropClick.bind(this)

		this.state = {
			newRoster: [],
      errorAddNewMsg: null,
		};

	}

  componentDidUpdate(prevProps) {
    if (!prevProps.teamMemberList && this.props.teamMemberList && this.props.teamMemberList.length > 0) {
      this.listTeamMemberOnModal(this.props.teamMemberList.slice(0, 20))
    }

    if (!prevProps.isModalOpen && this.props.isModalOpen) {
      this.listenBackdrop()

      if (this.props.teamMemberList) {
        this.listTeamMemberOnModal(this.props.teamMemberList.slice(0, 20))
      }
    }


  }

  onNewRosterDateSelect(name, i, startDateValue) {
    var deepForm = _cloneDeep(this.state.newRoster);
    _set(deepForm, [i, name], dateTimeFormatToSend(startDateValue))

    this.setState({
      newRoster: deepForm,
    })
	}

  onNewRosterLoginSelect(login, name, i) {
    var deepForm = _cloneDeep(this.state.newRoster);
    _set(deepForm, [i, 'login'], login)

    this.setState({
      newRoster: deepForm,
    })
	}

  onAddNewClick() {
    let areTheyEmpty = this.state.newRoster.filter(ros =>  ros.dutyFrom !== "" && ros.dutyTo !== "")

    if (areTheyEmpty.length > 0) {
      this.setState({
        errorAddNewMsg: null
      })

      this.props.handleAddRoster(areTheyEmpty)
    } else {
      this.setState({
        errorAddNewMsg: 'Please input roster information.'
      })
    }
  }

  onToggleModal(bool) {
    this.props.handleToggleModal(bool)
    if (!bool) {
      this.setState({
        newRoster: []
      })
      document.getElementById('root').removeEventListener('click', this.onBlackdropClick)
    }
  }

  onBlackdropClick(e) {
    const backdrop = this.backdrop;

    if (backdrop.contains(e.target) && this.props.isModalOpen) {
      this.onToggleModal(false)
    }
  }

  listenBackdrop() {
    document.getElementById('root').addEventListener('click', this.onBlackdropClick)
  }

  listTeamMemberOnModal(teamMemberList) {
    var deepForm = _cloneDeep(this.state.newRoster);

    teamMemberList.slice(0, 20).map(member =>
      deepForm.push({
        typeCode: member.typecode,
        login: member.login,
        dutyFrom: '',
        dutyTo: '',
      })
    )

    this.setState({
      newRoster: deepForm
    })
  }

	render() {
		return (
      <div className={`modal--roster modal fade ${this.props.isModalOpen ? 'show-model' : ''}`} role="dialog">
        <Modal.Dialog centered size="xl">
          <Modal.Header closeButton onHide={() => this.onToggleModal(false)}>
            <Modal.Title>New Rosters</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <table className={`table table-bordered ${this.state.newRoster.length > 1 ? 'table-striped' : ''}`}>
              <thead>
                <tr>
                  <th width="10">#</th>
                  <th >Company</th>
                  <th width="250">User</th>
                  <th width="230">Duty From</th>
                  <th width="230">Duty To</th>
                </tr>
              </thead>
              <tbody>
                {this.state.newRoster.map((ros, i) =>
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      {this.props.typeCodeName}
                    </td>
                    <td>
                      {_find(this.props.teamMemberList, {login: this.state.newRoster[i].login}).staffname}
                    </td>
                    <td>
                      <CustomDatePicker
                        handleSelect={this.onNewRosterDateSelect}
                        index={i}
                        name="dutyFrom"
                        value={_get(this.state.newRoster, [i, 'dutyFrom'])}
                        hasTimePicker
                        isClearAvailable
                      />
                    </td>
                    <td>
                      <CustomDatePicker
                        handleSelect={this.onNewRosterDateSelect}
                        index={i}
                        name="dutyTo"
                        value={_get(this.state.newRoster, [i, 'dutyTo'])}
                        hasTimePicker
                        isClearAvailable
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

          </Modal.Body>

          <Modal.Footer>
            <div className="text-center w-100">
              <Button variant="primary" onClick={this.onAddNewClick}>Add New Rosters</Button>
              {this.state.errorAddNewMsg &&
                <span className="small red mb-0 pt-1 ml-2">
                  {this.state.errorAddNewMsg}
                </span>
              }
            </div>
          </Modal.Footer>
        </Modal.Dialog>

        <div
          className={`overlay ${this.props.isModalOpen ? '' : 'hidden'}`}
          ref={ref => this.backdrop = ref}
        />
      </div>
	  );
	}
}

NewRosterModal.propTypes = {
	handleToggleModal: PropTypes.func,
	handleAddRoster: PropTypes.func,
	typeCode: PropTypes.string,
	typeCodeName: PropTypes.string,
	teamMemberList: PropTypes.array,
	isModalOpen: PropTypes.bool,
};

export default NewRosterModal;
