import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
	pageLoading: false
};

const loading = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.SEARCH_BOOKING_REQUEST:
	case ActionTypes.SEARCH_BOOKING_NOTISSUE_REQUEST:
	case ActionTypes.GET_BOOKING_REQUEST:
	case ActionTypes.UPDATE_BOOKING_REQUEST:
	case ActionTypes.READ_MSG_REQUEST:
	case ActionTypes.CREATE_MSG_REQUEST:
	case ActionTypes.ADD_HISTORY_REQUEST:
	case ActionTypes.VERIFY_BOOKING_REQUEST:
	case ActionTypes.UNVERIFY_BOOKING_REQUEST:
	case ActionTypes.CLONE_SEGMENT_REQUEST:
	case ActionTypes.COPY_ONE_TO_W_BOOKING_REQUEST:
	case ActionTypes.COPY_W_TO_ONE_BOOKING_REQUEST:
	case ActionTypes.SEND_RO_REQUEST:
	case ActionTypes.CANCEL_BOOKING_REQUEST:
	case ActionTypes.GET_ALERT_MSG_REQUEST:
	case ActionTypes.CREATE_ALERT_MSG_REQUEST:
	case ActionTypes.UPDATE_ALERT_MSG_REQUEST:
	case ActionTypes.GET_RO_HISTORY_CONTENT_REQUEST:
	case ActionTypes.DELETE_ALERT_MSG_REQUEST:
	case ActionTypes.GET_ROSTERS_REQUEST:
	case ActionTypes.GET_TEAM_MEMBER_REQUEST:
	case ActionTypes.UPDATE_ROSTERS_REQUEST:
	case ActionTypes.ADD_ROSTERS_REQUEST:
	case ActionTypes.CREATE_MANUAL_BOOKING_REQUEST:
		return Object.assign({}, state, {
			pageLoading: true
		});
	case ActionTypes.SEARCH_BOOKING_SUCCESS:
	case ActionTypes.SEARCH_BOOKING_FAILURE:
	case ActionTypes.SEARCH_BOOKING_NOTISSUE_SUCCESS:
	case ActionTypes.SEARCH_BOOKING_NOTISSUE_FAILURE:
	case ActionTypes.GET_BOOKING_SUCCESS:
	case ActionTypes.GET_BOOKING_FAILURE:
	case ActionTypes.UPDATE_BOOKING_SUCCESS:
	case ActionTypes.UPDATE_BOOKING_FAILURE:
	case ActionTypes.READ_MSG_SUCCESS:
	case ActionTypes.READ_MSG_FAILURE:
	case ActionTypes.CREATE_MSG_SUCCESS:
	case ActionTypes.CREATE_MSG_FAILURE:
	case ActionTypes.ADD_HISTORY_SUCCESS:
	case ActionTypes.ADD_HISTORY_FAILURE:
	case ActionTypes.VERIFY_BOOKING_SUCCESS:
	case ActionTypes.VERIFY_BOOKING_FAILURE:
	case ActionTypes.UNVERIFY_BOOKING_SUCCESS:
	case ActionTypes.UNVERIFY_BOOKING_FAILURE:
	case ActionTypes.CLONE_SEGMENT_SUCCESS:
	case ActionTypes.CLONE_SEGMENT_FAILURE:
	case ActionTypes.COPY_ONE_TO_W_BOOKING_SUCCESS:
	case ActionTypes.COPY_ONE_TO_W_BOOKING_FAILURE:
	case ActionTypes.COPY_W_TO_ONE_BOOKING_SUCCESS:
	case ActionTypes.COPY_W_TO_ONE_BOOKING_FAILURE:
	case ActionTypes.SEND_RO_SUCCESS:
	case ActionTypes.SEND_RO_FAILURE:
	case ActionTypes.CANCEL_BOOKING_SUCCESS:
	case ActionTypes.CANCEL_BOOKING_FAILURE:
	case ActionTypes.GET_ALERT_MSG_SUCCESS:
	case ActionTypes.GET_ALERT_MSG_FAILURE:
	case ActionTypes.CREATE_ALERT_MSG_SUCCESS:
	case ActionTypes.CREATE_ALERT_MSG_FAILURE:
	case ActionTypes.UPDATE_ALERT_MSG_SUCCESS:
	case ActionTypes.UPDATE_ALERT_MSG_FAILURE:
	case ActionTypes.GET_RO_HISTORY_CONTENT_SUCCESS:
	case ActionTypes.GET_RO_HISTORY_CONTENT_FAILURE:
	case ActionTypes.DELETE_ALERT_MSG_SUCCESS:
	case ActionTypes.DELETE_ALERT_MSG_FAILURE:
	case ActionTypes.GET_ROSTERS_SUCCESS:
	case ActionTypes.GET_ROSTERS_FAILURE:
	case ActionTypes.GET_TEAM_MEMBER_SUCCESS:
	case ActionTypes.GET_TEAM_MEMBER_FAILURE:
	case ActionTypes.UPDATE_ROSTERS_SUCCESS:
	case ActionTypes.UPDATE_ROSTERS_FAILURE:
	case ActionTypes.ADD_ROSTERS_SUCCESS:
	case ActionTypes.ADD_ROSTERS_FAILURE:
	case ActionTypes.CREATE_MANUAL_BOOKING_SUCCESS:
	case ActionTypes.CREATE_MANUAL_BOOKING_FAILURE:
		return Object.assign({}, state, {
			pageLoading: false
		});

	case ActionTypes.GET_UNREAD_MSG_REQUEST:
		return Object.assign({}, state, {
			componentLoading: true
		});
	case ActionTypes.GET_UNREAD_MSG_SUCCESS:
	case ActionTypes.GET_UNREAD_MSG_FAILURE:
		return Object.assign({}, state, {
			componentLoading: false
		});
	default:
		return state;
	}
};

export default loading;
