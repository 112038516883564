import React, {Component} from 'react';
import {Router, Route, Switch} from 'react-router-dom';
import {Provider} from 'react-redux';
import Auth from '../Auth/Auth';
import history from '../history';

import '../style/App.scss';
import '../style/style.scss';

import MainMenu from './MainMenu';
import Home from './Home';
import Report from './Report';
import AlertMessage from './AlertMessage';
import Rosters from './Rosters';
import SearchBookingPage from './SearchBookingPage';
import SearchBookingNotIssuePage from './SearchBookingNotIssuePage';
import BookingDetailPage from './BookingDetailPage';
import ManualBookingPage from './ManualBookingPage';
import Loading from '../components/Loading';
import ConnectionChecker from '../components/ConnectionChecker';

import store from '../store'; // Import the store

const auth = new Auth();

const handleAuthentication = ({ location }) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.onBurgerClick = this.onBurgerClick.bind(this);

    this.state = {
			isMenuOpen: false,
      isLogged: false,
      isCheckConnection: false
		};
  }

  componentDidMount() {
    if(history.location.hash.indexOf("checkConnection") > -1){
      this.setState({
        isCheckConnection: true
      })
      return;
    }
    if (auth.isAuthenticated()) {
      auth.renewSession(this.callback);
    } else {
      auth.login(this.callback);
    }

  }

  callback = () => {
    this.setState({
      isLogged: true
    })
  }

	onBurgerClick() {
		this.setState({
			isMenuOpen: !this.state.isMenuOpen
		});
	}

  render() {
    const Callback = () => (
      <div className="text-center mt-5">
        Loading...
      </div>
    )

    const NoMatch = () => (
      <div className="text-center pt-5">
        No Match
      </div>
    )

    return (
      <Provider store={store}>
        <Router history={history}>
          <div className="App">
            {this.state.isLogged &&
              <MainMenu
                isOpen={this.state.isMenuOpen}
                handleBurgerClick={this.onBurgerClick}
                currentPage={history.location.pathname}
              />
            }
            {this.state.isLogged ?
              <main>
                <div className="container">
                  <Switch>
                    <Route
                      path="/callback"
                      render={(props) => {
                        handleAuthentication(props);
                        return <Callback {...props} auth={auth}
                      />
                    }} />
                    <Route
                      exact
                      path="/"
                      render={(props) => <Home {...props} />}
                    />
                    <Route
                      exact
                      path="/search-booking"
                      render={(props) => <SearchBookingPage {...props} />}
                    />
                    <Route
                      exact
                      path="/search-booking-notissue"
                      render={(props) => <SearchBookingNotIssuePage {...props} />}
                    />
                    <Route
                      exact
                      path="/report"
                      render={(props) => <Report {...props} />}
                    />
                    <Route
                      exact
                      path="/alert-message"
                      render={(props) => <AlertMessage {...props} />}
                    />
                    <Route
                      exact
                      path="/rosters"
                      render={(props) => <Rosters {...props} />}
                    />
                    <Route
                      exact
                      path="/manual-booking"
                      render={(props) => <ManualBookingPage {...props} />}
                    />
                    <Route
                      exact
                      path="/:id"
                      render={({ match, props }) => {
                        if (!/^(W|1).+[-][0-9]+/.test(match.params.id)) {
                          return <NoMatch />;
                        }
                        return <BookingDetailPage {...props} />;
                      }}
                    />
                    <Route component={NoMatch} />
                  </Switch>
                </div>
              </main> :
              <Loading />
            }
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
