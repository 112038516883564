import React, {Component} from 'react';
import Sticky from 'react-stickynode';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button, DropdownButton, Dropdown, Nav} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEllipsisV, faFileInvoiceDollar, faAt, faInbox, faHistory, faCertificate, faObjectUngroup, faCopy, faWindowClose, faHandSpock} from '@fortawesome/free-solid-svg-icons';
import {withRouter} from 'react-router-dom';

import BackButton from '../components/BackButton';
import DetailHeader from '../components/BookingDetailPage/DetailHeader';
import TabGeneral from '../components/BookingDetailPage/TabGeneral';
import TabSendRo from '../components/BookingDetailPage/TabSendRo';
import TabMsg from '../components/BookingDetailPage/TabMsg';
import TabHistory from '../components/BookingDetailPage/TabHistory';
import VerifyModal from '../components/BookingDetailPage/VerifyModal';
import PopUpModal from '../components/BookingDetailPage/PopUpModal';

import _split from 'lodash/split';
import _get from 'lodash/get';
import _find from 'lodash/find';
import {
  getBookingDetail,
  updateBooking,
  getRoHeader,
  sendRO,
  readMsg,
  deleteMsg,
  createMsg,
  addHistory,
  verifyBooking,
  unverifyBooking,
  cloneSegment,
  copyWTo1Booking,
  copy1ToWBooking,
  cancelBooking,
  clearBookingDetail,
  getROHistoryContent,
  clearROHistoryContent,
} from '../actions/booking'; // Import your actions
import {getProfile, getCurrencyList} from '../actions/index';

const stickyHeaderHeight = 331;

class BookingDetailPage extends Component {
  constructor(props) {
    super(props);
    this.onTabClick = this.onTabClick.bind(this);
    this.backToResultPage = this.backToResultPage.bind(this);
    this.toggleVerifyModal = this.toggleVerifyModal.bind(this);
    this.togglePopUpModal = this.togglePopUpModal.bind(this);
    this.updateURL = this.updateURL.bind(this);
    this.checkScroll = this.checkScroll.bind(this);

    this.state = {
      currentTab: 'general',
      isVerifyModalOpen: false,
      isPopUpModalOpen: false,
      action: null,
      hideHeaderDetail: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.props.bookingDetail) {
      this.getBookingByParam()
    }

    if (!this.props.currencyList) {
      this.props.getCurrencyList()
    }

    window.addEventListener('scroll', this.checkScroll);
  }

  componentWillUnmount() {
    this.props.clearBookingDetail()
    window.removeEventListener('scroll', this.checkScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.shouldUpdateBooking && this.props.shouldUpdateBooking) {
      if (this.props.newSeqnum) {
        this.updateURL(this.props.bookingDetail.get('txno'), this.props.newSeqnum)
      } else if (this.props.newTxno) {
        this.updateURL(this.props.newTxno, this.props.bookingDetail.get('seqnum'))
      } else {
        this.props.getBookingDetail(
          this.props.bookingDetail.get('txno'),
          this.props.bookingDetail.get('seqnum')
        )
      }
      this.closeModal()

      this.props.getProfile();

    }

    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.getBookingByParam()
      this.closeModal()
    }
  }

  checkScroll() {
    if (window.scrollY >= stickyHeaderHeight) {
      this.setState({
        hideHeaderDetail: true
      })
    }
    if (window.scrollY < stickyHeaderHeight) {
      this.setState({
        hideHeaderDetail: false
      })
    }
  };


  toggleVerifyModal() {
    this.setState({
      isVerifyModalOpen: !this.state.isVerifyModalOpen
    })
  }

  togglePopUpModal(action) {
    this.setState({
      isPopUpModalOpen: !this.state.isPopUpModalOpen,
      action: action
    })
  }

  closeModal() {
    this.setState({
      isVerifyModalOpen: false,
      isPopUpModalOpen: false,
      action: null
    })
  }

  onTabClick(tabName) {
    this.setState({
      currentTab: tabName
    })
  }

  getBookingByParam() {
    const txno = _split(this.props.match.params.id, '-')[0]
    const seqnum = _split(this.props.match.params.id, '-')[1]

    this.props.getBookingDetail(txno, seqnum)
  }

  backToResultPage() {
    this.props.history.push('/search-booking')
  }

  updateURL(txno, seqnum) {
    let isWBooking = txno.includes('W')
    this.setState({
      currentTab: isWBooking && this.state.currentTab === 'msg' ? 'general' : this.state.currentTab
    })
    this.props.history.push(`/${txno}-${seqnum}`)
  }

  render() {

    let isWBooking = false
		let oneTnxo = ''
		let wTnxo = ''

    if (this.props.bookingDetail) {
      isWBooking = this.props.bookingDetail.get('txno').includes('W')
      oneTnxo = this.props.bookingDetail.get('txno').replace('W', '1')
      wTnxo = this.props.bookingDetail.get('txno').replace('1', 'W')
    }

    const txnoParam = _split(this.props.match.params.id, '-')[0]
    const seqnumParam = _split(this.props.match.params.id, '-')[1]

    const verifyObject = [
      {flag: 'N', displayLabel: 'Verify', isVerified: false},
      {flag: 'V', displayLabel: 'Verify', isVerified: false},
      {flag: 'I', displayLabel: 'Unverify', isVerified: true},
      {flag: 'P', displayLabel: 'Processing to verify...', isVerified: null},
      {flag: 'U', displayLabel: 'Processing to unverify...', isVerified: null},
      {flag: 'E', displayLabel: 'Verify Process Error', isVerified: false},
    ]

    return (
      <div className={this.props.bookingDetail ? (isWBooking ? 'w-booking' : 'one-booking') : ''}>
        <title>{`${txnoParam}(${seqnumParam})`} | Hotel Fulfillment System</title>

        <Sticky
          enabled={!!this.props.bookingDetail && this.state.hideHeaderDetail}
          activeClass="stickyactive"
        >
          {this.props.searchResult &&
            <BackButton title="to Result" handleClick={this.backToResultPage} />
          }
          {!this.state.hideHeaderDetail && this.props.bookingDetail &&
            <div className={`big-indicator`}>
              {isWBooking ? 'W' : '1'}
            </div>
          }
          {!this.state.hideHeaderDetail &&
            <div className="pt-4">
        			<h1 className="text-center">Booking Detail</h1>
        		</div>
          }
          {this.props.bookingDetail &&
            <DetailHeader
              bookingDetail={this.props.bookingDetail}
              handleBookingChange={this.updateURL}
              hideHeaderDetail={this.state.hideHeaderDetail}
            />
          }
        </Sticky>


        {this.props.bookingDetail &&
          <div className={this.state.hideHeaderDetail ? 'sticky-header-active' : ''}>
            <div className="py-4 px-3">
              <Nav variant="tabs" defaultActiveKey={this.state.currentTab} className="tabs">
                <Nav.Item>
                  <Nav.Link as={Button} variant="none" eventKey="general" onClick={() => this.onTabClick('general')} active={this.state.currentTab === 'general'}><FontAwesomeIcon icon={faFileInvoiceDollar} /> General</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link as={Button} variant="none" eventKey="sendRO" onClick={() => this.onTabClick('sendRO')} active={this.state.currentTab === 'sendRO'}><FontAwesomeIcon icon={faAt} /> Send RO</Nav.Link>
                </Nav.Item>
                {!isWBooking &&
                  <Nav.Item>
                    <Nav.Link as={Button} variant="none" eventKey="msg" onClick={() => this.onTabClick('msg')} active={this.state.currentTab === 'msg'}><FontAwesomeIcon icon={faInbox} /> Msg</Nav.Link>
                  </Nav.Item>
                }
                <Nav.Item>
                  <Nav.Link as={Button} variant="none" eventKey="history" onClick={() => this.onTabClick('history')} active={this.state.currentTab === 'history'}><FontAwesomeIcon icon={faHistory} /> History</Nav.Link>
                </Nav.Item>

                <DropdownButton
                  title={<div><FontAwesomeIcon icon={faEllipsisV} className="mr-2"/> Action</div>}
                  variant="none"
                  className="tab-action--wrapper"
                  alignRight
                >
                  <Dropdown.Item onClick={() => this.togglePopUpModal('cancel')} >
                    <FontAwesomeIcon icon={faWindowClose} /> Cancel Booking
                  </Dropdown.Item>
                  <Dropdown.Item disabled={_find(verifyObject, {flag: this.props.bookingDetail.get('verifyFlag')}).isVerified == null} onClick={() => this.toggleVerifyModal()} >
                    <FontAwesomeIcon icon={faCertificate} /> {_find(verifyObject, {flag: this.props.bookingDetail.get('verifyFlag')}).displayLabel}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => this.togglePopUpModal('cloneSeg')} >
                    <FontAwesomeIcon icon={faObjectUngroup} /> Clone Segment
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => this.togglePopUpModal('cloneToBooking')}
                    disabled={!isWBooking && this.props.bookingDetail.get('otherTxno')}
                  >
                    <FontAwesomeIcon icon={faCopy} /> Copy to {isWBooking ? oneTnxo : wTnxo} ({this.props.bookingDetail.get('seqnum')})
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => this.props.history.push({
                    pathname: '/manual-booking',
                    state: {txno: this.props.bookingDetail.get('txno')}
                  })} >
                    <FontAwesomeIcon icon={faHandSpock} /> Manual Booking (Appending)
                  </Dropdown.Item>

                </DropdownButton>
              </Nav>

              <div className="tab-content">
                {this.state.currentTab === 'general' &&
                  <TabGeneral
                    bookingDetail={this.props.bookingDetail}
                    handleSubmit={this.props.updateBooking}
                    shouldUpdateBooking={this.props.shouldUpdateBooking}
                    isWBooking={isWBooking}
                    currencyList={this.props.currencyList}
                  />
                }
                {this.state.currentTab === 'sendRO' &&
                  <TabSendRo
                    bookingDetail={this.props.bookingDetail}
                    handleSubmit={this.props.updateBooking}
                    handleGetRoHeader={this.props.getRoHeader}
                    handleSendRO={this.props.sendRO}
                    roHeader={this.props.roHeader}
                    typeCode={_get(this.props.profileInfo, 'typeCode')}
                  />
                }
                {this.state.currentTab === 'msg' &&
                  <TabMsg
                    bookingDetail={this.props.bookingDetail}
                    htlMessages={this.props.htlMessages}
                    handleReadMsg={this.props.readMsg}
                    handleDeleteMsg={this.props.deleteMsg}
                    handleCreateMsg={this.props.createMsg}
                  />
                }
                {this.state.currentTab === 'history' &&
                  <TabHistory
                    bookingDetail={this.props.bookingDetail}
                    histories={this.props.histories}
                    handleAddHistory={this.props.addHistory}
                    handleNoteClick={this.props.getROHistoryContent}
                    reSendROContent={this.props.reSendROContent}
                    handleClearROHistoryContent={this.props.clearROHistoryContent}
                    handleSendRO={this.props.sendRO}
                  />
                }
              </div>
            </div>
            <VerifyModal
              bookingDetail={this.props.bookingDetail}
              isOpen={this.state.isVerifyModalOpen}
              isVerified={_find(verifyObject, {flag: this.props.bookingDetail.get('verifyFlag')}).isVerified}
              handleCloseModal={this.toggleVerifyModal}
              handleVerify={this.props.verifyBooking}
              handleUnverify={this.props.unverifyBooking}
            />
            <PopUpModal
              bookingDetail={this.props.bookingDetail}
              oneTnxo={oneTnxo}
              wTnxo={wTnxo}
              isWBooking={isWBooking}
              isOpen={this.state.isPopUpModalOpen}
              action={this.state.action}
              handleCloseModal={this.togglePopUpModal}
              handleCloneSeg={this.props.cloneSegment}
              handleCopyWTo1Booking={this.props.copyWTo1Booking}
              handleCopy1ToWBooking={this.props.copy1ToWBooking}
              handleCancelBooking={this.props.cancelBooking}
            />
          </div>
        }

        {!this.props.bookingDetail &&
          <div className="text-center pt-30vh">
            <p>Fetching booking detail of {txnoParam} ({seqnumParam})...</p>
          </div>
        }

      </div>
    );
  }
}


BookingDetailPage.propTypes = {
  getBookingDetail: PropTypes.func,
  updateBooking: PropTypes.func,
  getRoHeader: PropTypes.func,
  sendRO: PropTypes.func,
  createMsg: PropTypes.func,
  addHistory: PropTypes.func,
  readMsg: PropTypes.func,
  deleteMsg: PropTypes.func,
  verifyBooking: PropTypes.func,
  cloneSegment: PropTypes.func,
  copyWTo1Booking: PropTypes.func,
  copy1ToWBooking: PropTypes.func,
  cancelBooking: PropTypes.func,
  getProfile: PropTypes.func,
	clearBookingDetail: PropTypes.func,
	getROHistoryContent: PropTypes.func,
	clearROHistoryContent: PropTypes.func,
	getCurrencyList: PropTypes.func,
  bookingDetail: PropTypes.object,
  htlMessages: PropTypes.array,
  histories: PropTypes.array,
  shouldUpdateBooking: PropTypes.bool,
  roHeader: PropTypes.array,
  newSeqnum: PropTypes.string,
  newTxno: PropTypes.string,
  bookingTxno: PropTypes.string,
  bookingSeqnum: PropTypes.string,
};

function mapStateToProps(state) {
	return {
		bookingDetail: state.booking.bookingDetail,
		htlMessages: state.booking.htlMessages,
		histories: state.booking.histories,
    shouldUpdateBooking: state.booking.shouldUpdateBooking,
    roHeader: state.booking.roHeader,
    newSeqnum: state.booking.newSeqnum,
    newTxno: state.booking.newTxno,
    bookingTxno: state.booking.bookingTxno,
    bookingSeqnum: state.booking.bookingSeqnum,
    searchResult: state.search.searchResult,
    reSendROContent: state.booking.reSendROContent,
    currencyList: state.general.currencyList,
    profileInfo: state.general.profileInfo,
	}
}

const mapDispatchToProps = {
  getBookingDetail,
  updateBooking,
  getRoHeader,
  readMsg,
  deleteMsg,
  sendRO,
  createMsg,
  addHistory,
  verifyBooking,
  unverifyBooking,
  cloneSegment,
  copyWTo1Booking,
  copy1ToWBooking,
  cancelBooking,
  getProfile,
  clearBookingDetail,
  getROHistoryContent,
  clearROHistoryContent,
  getCurrencyList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BookingDetailPage));
