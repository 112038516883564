import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, Button, ButtonToolbar, OverlayTrigger, Tooltip} from 'react-bootstrap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFont} from '@fortawesome/free-solid-svg-icons';

import {noOfDays} from '../../util/transformDate.js';

class AveragePriceComponent extends Component {
	constructor(props) {
		super(props);

		this.onAverageChange = this.onAverageChange.bind(this);

		this.state = {
			avgPrice: this.props.item[this.props.name].value / noOfDays(this.props.item.startDate, this.props.item.endDate),
		};
	}

	componentDidUpdate(prevProps) {
		if(prevProps.item[this.props.name].value !== this.props.item[this.props.name].value) {
			this.checkAvgPrice()
		}
		if(prevProps.item.startDate !== this.props.item.startDate) {
			this.checkAvgPrice()
		}
	}

	onAverageChange(e, i) {
		this.setState({
			avgPrice: e.target.value
		})
		this.props.handleAvgPrivceChange(e, i)
	}

	checkAvgPrice() {
		console.log("checkAvgPrice")
    this.setState({
      avgPrice: this.props.item[this.props.name].value /  noOfDays(this.props.item.startDate, this.props.item.endDate)
    })
  }


	render() {
		return (
			<ButtonToolbar>
				<OverlayTrigger
					trigger="click"
					key="top"
					placement="top"
					overlay={
						<Tooltip id="tooltip-top" className="service-detail__tooltip">
							<div className="text-left">
								<div className="font-weight-bold">Average Selling</div>
								<div>
									${this.props.item[this.props.name].value} / {noOfDays(this.props.item.startDate, this.props.item.endDate)} Night(s)
								</div>
								<div className="form-inline">
									<span className="mr-1">= {this.props.item[this.props.name].currencyCode}</span>
									<Form.Control
										type="text"
										onChange={(e) => this.onAverageChange(e, this.props.i)}
										name={this.props.name}
										value={this.state.avgPrice || ''}
										className="form-control-sm form-control--w70"
										min="0"
									/>
								</div>
							</div>
						</Tooltip>
					}
				>
					<Button variant="icon-round" className="text-info" disabled={!this.props.item.startDate} ><FontAwesomeIcon icon={faFont} size="sm" /></Button>
				</OverlayTrigger>
			</ButtonToolbar>
	  );
	}
}

AveragePriceComponent.propTypes = {
	item: PropTypes.object,
	i: PropTypes.number,
	name: PropTypes.string,
	handleAvgPrivceChange: PropTypes.func,
};

export default AveragePriceComponent;
