import React, { useEffect, useState } from 'react';
const ConnectionChecker = () => {
	const [connectionStatus, setConnectionStatus] = useState('Checking...');
	const endpoint = `${process.env.REACT_APP_serverURL}/hi`;
	useEffect(() => {
		const checkAPIAndDBConnection = async () => {
			try {
				fetch(endpoint, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'Accept-Encoding': 'zip, deflate, br'
					}
				})
					.then(response => response.json())
					.then((response) => {
						console.log(response)
						setConnectionStatus(JSON.stringify(response));
					})
					.catch((error) => {
						 console.error('Error fetching data:', error);
					});
			} catch (error) {
				setConnectionStatus('Connection Failed');
				console.error('Error fetching data:', error);
			}

		};
		checkAPIAndDBConnection();
	}, []);
	return (
		<div>
			<h3>UI Info</h3>
			<p>UI created on {process.env.REACT_APP_CREATION_INFO}</p>
			<p>Backend is {process.env.REACT_APP_serverURL}</p>
			<h3>API&DB Info</h3>
			<p>{connectionStatus}</p>
		</div>
	);
};
export default ConnectionChecker;