import React from 'react';
import PropTypes from 'prop-types';
import {DropdownButton, Dropdown, Badge, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarAlt, faBed} from '@fortawesome/free-solid-svg-icons';

import {getStatus} from '../../util/bookingDetailUtil.js';
import {prettyDateRange, prettySingleDate, prettySingleDateTime, isExpired} from '../../util/transformDate.js';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _capitalize from 'lodash/capitalize';

const DetailHeader = (props) => {
	const bookingDetail = props.bookingDetail.toJS()
	return (
		<div className={`border-bottom px-3 ${props.hideHeaderDetail ? 'py-2' : 'py-3'}`}>
			{!props.hideHeaderDetail &&
				<div className="row">
					<div className="col-6">
						<div className="row p-1 justify-content-center">
							<div className="col-4 detail-label pt-2">
								<h6>Booking Number</h6>
							</div>
							<div className={`col-8  ${bookingDetail.validSeqnums.length > 1 ? '' : 'pt-1'}`}>
								{bookingDetail.validSeqnums.length > 1 ?
									<DropdownButton
										id="bookingNoMenuButton"
										title={`${_get(bookingDetail, 'txno')} (${_get(bookingDetail, 'seqnum')})`}
										variant="light"
										className="mb-2 booking-number-dropdown"
									>
										{_map(bookingDetail.validSeqnums, (seq, i) =>
											<Dropdown.Item
												onClick={() => props.handleBookingChange(_get(bookingDetail, 'txno'), seq)}
												key={i}
												className={_get(bookingDetail, 'seqnum') === seq ? "active" : ""}
											>
												{_get(bookingDetail, 'txno')} ({seq})
											</Dropdown.Item>
										)}
									</DropdownButton> :
									<div className="booking-number">{_get(bookingDetail, 'txno')} ({_get(bookingDetail, 'seqnum')})</div>
								}
								{bookingDetail.otherTxno &&
									<button className={`btn btn-link px-2 ${bookingDetail.validSeqnums.length > 1 ? '' : 'pt-0'}`} onClick={() => props.handleBookingChange(_get(bookingDetail, 'otherTxno'), _get(bookingDetail, 'seqnum'))}>Go to {_get(bookingDetail, 'otherTxno')} ({_get(bookingDetail, 'seqnum')})</button>
								}
							</div>

						</div>
						<div>
							<div className="row p-1">
								<div className="col-4 detail-label pt-2">
									<h6>Status</h6>
								</div>
								<div className="col-8 pt-1 h5 mb-0">
									{_map(getStatus(bookingDetail.items), function(value, key) {
										let variant = 'success';
										if (key === 'confirmed') {variant = "success"}
										else if (key === 'confirm') {variant = "info"}
										else if (key === 'cancel') {variant = "danger"}
										else if (key === 'request') {variant = "warning"}
										if (value !== 0) {
											return <Badge variant={variant} key={key} className="mr-2">{value} {_capitalize(key)}</Badge>
										}
									})}
								</div>
							</div>
							<div className="row p-1">
								<div className="col-4 detail-label pt-2">
									<h6>Arr-Dep</h6>
								</div>
								<div className="col-8 pt-2">
									{prettyDateRange(_get(bookingDetail, 'arr'), _get(bookingDetail, 'dep'))}
								</div>
							</div>
							<div className="row p-1">
								<div className="col-4 detail-label pt-2">
									<h6>Cxl deadline</h6>
								</div>
								<div className={`font-weight-bold col-8 pt-2 ${isExpired(_get(bookingDetail, 'deadline')) ? 'red' : ''}`}>
									{prettySingleDate(_get(bookingDetail, 'deadline'))}
								</div>
							</div>
							<div className="row p-1">
								<div className="col-4 detail-label pt-2">
									<h6>Supplier code</h6>
								</div>
								<div className={`col-8 pt-2`}>
									{_get(bookingDetail, 'operatorCode')}
								</div>
							</div>
							{_get(bookingDetail, ['uiReference', 'alertMsg']) && <div className="row p-1">
								<div className="col-4 detail-label pt-2">
									<h6>Booking type</h6>
								</div>
								<div className={`col-8 pt-1 h5 mb-0 detail-badge-value`}>
									<Badge className="mr-2" style={{paddingLeft:_get(bookingDetail, ['uiReference', 'alertBgColor']) ? '' : '0px', backgroundColor: _get(bookingDetail, ['uiReference', 'alertBgColor']), color: _get(bookingDetail, ['uiReference', 'alertFontColor'])}}>{_get(bookingDetail, ['uiReference', 'alertMsg'])}</Badge>
								</div>
							</div>}
						</div>
					</div>
					<div className="col-6">

						<div className="row p-1 justify-content-start">
							<div className="col-4 detail-label pt-2">
								<h6>Hotel</h6>
							</div>
							<div className="col-8 pt-2">
								<p>
									<a href={`http://portal.adholidays.com/b2c/B2C/Information.aspx?Code=${_get(bookingDetail, 'hotelCode')}&ViewType=I&P1=kyeD1E4l48A%3d&P2=hhoMxONrizqUj1DXussuAw%3d%3d`} target="_blank" rel="noopener noreferrer" className="link">
										{_get(bookingDetail, 'hotelName')} ({_get(bookingDetail, 'cityCode')})
									</a>
									{!!bookingDetail.creditTerms && <span className="badge badge-secondary ml-2">{bookingDetail.creditTerms}</span>}
								</p>
								<p>{_get(bookingDetail, 'hotelAddress')}</p>
								<p className="mb-0">Phone: {_get(bookingDetail, 'hotelPhone')}</p>
							</div>
						</div>
						<div className="row p-1 justify-content-start">
							<div className="col-4 detail-label pt-2">
								<h6>Hotel Code</h6>
							</div>
							<div className="col-8 pt-2">
								{bookingDetail.hotelCode}
							</div>
						</div>
						<div className="row p-1 justify-content-start">
							<div className="col-4 detail-label pt-2">
								<h6>Booking Create Date</h6>
							</div>
							<div className="col-8 pt-2">
								{prettySingleDateTime(bookingDetail.createOn)}
							</div>
						</div>
					</div>
				</div>
			}

			{props.hideHeaderDetail &&
				<div className="row p-1 justify-content-center">
					<div className={`col-auto border-right  ${bookingDetail.validSeqnums.length > 1 ? '' : 'pt-2'}`}>
						{bookingDetail.validSeqnums.length > 1 ?
							<DropdownButton
								id="bookingNoMenuButton"
								title={`${_get(bookingDetail, 'txno')} (${_get(bookingDetail, 'seqnum')})`}
								variant="light"
								className="mb-2 booking-number-dropdown"
							>
								{_map(bookingDetail.validSeqnums, (seq, i) =>
									<Dropdown.Item
										onClick={() => props.handleBookingChange(_get(bookingDetail, 'txno'), seq)}
										key={i}
										className={_get(bookingDetail, 'seqnum') === seq ? "active" : ""}
									>
										{_get(bookingDetail, 'txno')} ({seq})
									</Dropdown.Item>
								)}
							</DropdownButton> :
							<div className="booking-number">{_get(bookingDetail, 'txno')} ({_get(bookingDetail, 'seqnum')})</div>
						}
						{bookingDetail.otherTxno &&
							<button className={`btn btn-link px-2 ${bookingDetail.validSeqnums.length > 1 ? '' : 'pt-0'}`} onClick={() => props.handleBookingChange(_get(bookingDetail, 'otherTxno'), _get(bookingDetail, 'seqnum'))}>Go to {_get(bookingDetail, 'otherTxno')} ({_get(bookingDetail, 'seqnum')})</button>
						}
					</div>

					<div className="col-auto h5 pt-2">
						{_map(getStatus(bookingDetail.items), function(value, key) {
							let variant = 'success';
							if (key === 'confirmed') {variant = "success"}
							else if (key === 'confirm') {variant = "info"}
							else if (key === 'cancel') {variant = "danger"}
							else if (key === 'request') {variant = "warning"}
							if (value !== 0) {
								return <Badge variant={variant} key={key} className="mr-2">{value} {_capitalize(key)}</Badge>
							}
						})}
					</div>

					<div className="col-auto pt-1 border-right border-left">
						<OverlayTrigger
							key="bottom"
							placement="bottom"
							overlay={
								<Tooltip className="white">
									<div className="text-left">
										<div className="font-weight-bold">Arr-Dep</div>
										<div>{prettyDateRange(_get(bookingDetail, 'arr'), _get(bookingDetail, 'dep'))}</div>
										<div className="font-weight-bold">Cxl deadline</div>
										<div className={isExpired(_get(bookingDetail, 'deadline')) ? 'red font-weight-bold' : ''}>{prettySingleDate(_get(bookingDetail, 'deadline'))}</div>
									</div>
								</Tooltip>
							}
						>
							<FontAwesomeIcon size="lg" icon={faCalendarAlt} className="mt-2 text-secondary"/>
						</OverlayTrigger>
					</div>

					<div className="col-auto pt-1">
						<OverlayTrigger
							key="bottom"
							placement="bottom"
							overlay={
								<Tooltip className="white">
									<div className="text-left">
										<div className="font-weight-bold">Hotel</div>
										<div className="mb-2">
											{_get(bookingDetail, 'hotelName')} ({_get(bookingDetail, 'cityCode')}) {!!bookingDetail.creditTerms && <span className="badge badge-secondary ml-2">{bookingDetail.creditTerms}</span>}
										</div>
										<div className="mb-2">
											{_get(bookingDetail, 'hotelAddress')}
										</div>
										<div>
											Phone: {_get(bookingDetail, 'hotelPhone')}
										</div>
									</div>
								</Tooltip>
							}
						>
							<FontAwesomeIcon size="lg" icon={faBed} className="mt-2 text-secondary" />
						</OverlayTrigger>
					</div>
				</div>
			}
		</div>
  );
}

DetailHeader.propTypes = {
	bookingDetail: PropTypes.object,
	handleBookingChange: PropTypes.func,
	hideHeaderDetail: PropTypes.bool,
};

export default DetailHeader;
