import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Accordion, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleDown, faChevronCircleUp} from '@fortawesome/free-solid-svg-icons';

import Title from '../components/Title';
import SearchForm from '../components/SearchPage/SearchForm';
import SearchResult from '../components/SearchPage/SearchResult';
import {withRouter} from 'react-router-dom';

import _get from 'lodash/get';
import _chunk from 'lodash/chunk';
import _omitBy from 'lodash/omitBy';

import {searchBooking, changePage, clearBookingSearchResult} from '../actions/search'; // Import your actions
import {getBookingDetail} from '../actions/booking';

const chunkSize = 30;

class SearchBookingPage extends Component {
  constructor(props) {
    super(props);
    this.onCollapseClick = this.onCollapseClick.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onDetailClick = this.onDetailClick.bind(this);

    this.state = {
      isOpen: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.onCollapseClick();
  }

  componentWillUnmount() {
    if (!this.props.bookingTxno) {
      this.props.clearBookingSearchResult();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchResult !== this.props.searchResult) {
      this.onCollapseClick();
      this.props.changePage(0);
    }
    if (!prevProps.bookingTxno && this.props.bookingTxno) {
      // route to detail page
      this.props.history.push(`/${this.props.bookingTxno}-${this.props.bookingSeqnum}`)
    }
  }

  onCollapseClick() {
    if (this.props.searchResult && this.props.searchResult.status === "Success") {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }
  }

  onFormSubmit(criteria) {
    const criteriaWithValue = _omitBy(criteria, cri => (cri == null || cri === ""))
    this.props.searchBooking(criteriaWithValue);
  }

  onPageChange(val) {
    if (this.props.currentPage !== val) {
      this.props.changePage(val);
    }
  }

  onDetailClick(txNo, seqnum) {
    this.props.getBookingDetail(txNo, seqnum)
  }

  render() {
    let recordQuan = 0
    if (this.props.searchResult && this.props.searchResult.bookingDetails) {
      recordQuan = this.props.searchResult.bookingDetails.length
    }
    const bookingDetails = _get(this.props.searchResult, 'bookingDetails', [])
    const hasResult = recordQuan !== 0
    return (
      <div>
        <Title title="Search Booking" browserTitle="Search Booking"/>
        <Accordion defaultActiveKey="0">
          <div>
            <div className="collapse-wrapper text-center">
              <span className="collapse__line"/>
              <Accordion.Toggle as={Button} variant="link" eventKey="0" className="btn py-0 px-5" onClick={() => this.onCollapseClick()}>
                <FontAwesomeIcon icon={this.state.isOpen ? faChevronCircleDown : faChevronCircleUp} size="lg" className="collapse__icon" />
              </Accordion.Toggle>
            </div>
            <Accordion.Collapse in={this.state.isOpen} eventKey="0">
              <SearchForm
                handleFormSubmit={this.onFormSubmit}
                hasResult={this.props.searchResult && this.props.searchResult.status === "Success"}
              />
            </Accordion.Collapse>
          </div>
        </Accordion>
        {this.props.searchResult && this.props.searchResult.status === "Success" &&
          <SearchResult
            recordQuan={recordQuan}
            hasResult={hasResult}
            chunkedBookingRecords={_chunk(bookingDetails, chunkSize)}
            searchResult={this.props.searchResult}
            currentPage={this.props.currentPage}
            handlePageChange={this.onPageChange}
            handleDetailClick={this.onDetailClick}
          />
        }
      </div>
    );
  }
}


SearchForm.propTypes = {
	searchBooking: PropTypes.func,
	changePage: PropTypes.func,
	getBookingDetail: PropTypes.func,
	clearBookingSearchResult: PropTypes.func,
};

function mapStateToProps(state) {
	return {
		searchResult: state.search.searchResult,
		currentPage: state.search.currentPage,
    bookingTxno: state.booking.bookingTxno,
    bookingSeqnum: state.booking.bookingSeqnum,
	}
}

const mapDispatchToProps = {
	searchBooking,
  changePage,
  getBookingDetail,
  clearBookingSearchResult,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchBookingPage));
