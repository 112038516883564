import Immutable from 'immutable';
import * as ActionTypes from '../constants/ActionTypes';

import _reverse from 'lodash/reverse';
// import bookingDetail from '../mock/bookingDetail.json'; // Import mock data

const initialState = {
	bookingDetail: null, // Immutable.fromJS(bookingDetail),
	htlMessages: null, //_reverse(bookingDetail.htlMessages), //null,
	histories: null, //_reverse(bookingDetail.histories), // null,
	bookingTxno: null,
	bookingSeqnum: null,
	shouldUpdateBooking: false,
	roHeader: null,
	newSeqnum: null,
	newTxno: null,
	reSendROContent: null,
	createdTxno: null,
};

const booking = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.GET_BOOKING_SUCCESS:
		return Object.assign({}, state, {
			bookingDetail: Immutable.fromJS(action.payload),
			htlMessages: _reverse(action.payload.htlMessages),
			histories: _reverse(action.payload.histories),
			bookingTxno: action.payload.txno,
			bookingSeqnum: action.payload.seqnum,
			shouldUpdateBooking: false,
			newSeqnum: null,
			newTxno: null
		});
	case ActionTypes.GET_BOOKING_FAILURE:
		return Object.assign({}, state, {
			bookingDetail: action.payload,
			shouldUpdateBooking: false,
			bookingTxno: null,
			bookingSeqnum: null,
			htlMessages: null,
			histories: null,
		});
	case ActionTypes.UPDATE_BOOKING_SUCCESS:
		return Object.assign({}, state, {
			shouldUpdateBooking: true,
		});
	case ActionTypes.UPDATE_BOOKING_FAILURE:
		return Object.assign({}, state, {
			shouldUpdateBooking: false,
		});
	case ActionTypes.GET_HEADER_SUCCESS:
		return Object.assign({}, state, {
			roHeader: action.payload.headers
		});
	case ActionTypes.READ_MSG_SUCCESS:
		return Object.assign({}, state, {
			shouldUpdateBooking: true
		});
	case ActionTypes.DELETE_MSG_SUCCESS:
		return Object.assign({}, state, {
			shouldUpdateBooking: true
		});
	case ActionTypes.CREATE_MSG_SUCCESS:
		return Object.assign({}, state, {
			shouldUpdateBooking: true
		});
	case ActionTypes.ADD_HISTORY_SUCCESS:
		return Object.assign({}, state, {
			shouldUpdateBooking: true,
		});
	case ActionTypes.VERIFY_BOOKING_SUCCESS:
		return Object.assign({}, state, {
			shouldUpdateBooking: true,
		});
	case ActionTypes.UNVERIFY_BOOKING_SUCCESS:
		return Object.assign({}, state, {
			shouldUpdateBooking: true,
		});
	case ActionTypes.CLONE_SEGMENT_REQUEST:
		return Object.assign({}, state, {
			newSeqnum: null
		});
	case ActionTypes.CLONE_SEGMENT_SUCCESS:
		return Object.assign({}, state, {
			shouldUpdateBooking: true,
			newSeqnum: action.payload.newSeqnum
		});
	case ActionTypes.COPY_ONE_TO_W_BOOKING_SUCCESS:
	case ActionTypes.COPY_W_TO_ONE_BOOKING_SUCCESS:
		return Object.assign({}, state, {
			shouldUpdateBooking: true,
			newTxno: action.newTxno
		});
	case ActionTypes.CANCEL_BOOKING_SUCCESS:
		return Object.assign({}, state, {
			shouldUpdateBooking: true
		});
	case ActionTypes.GET_RO_HISTORY_CONTENT_SUCCESS:
		return Object.assign({}, state, {
			reSendROContent: action.payload
		});
	case ActionTypes.CLEAR_RO_HISTORY_CONTENT:
		return Object.assign({}, state, {
			reSendROContent: null
		});
	case ActionTypes.SEND_RO_SUCCESS:
		return Object.assign({}, state, {
			shouldUpdateBooking: true
		});
	case ActionTypes.CREATE_MANUAL_BOOKING_SUCCESS:
		return Object.assign({}, state, {
			createdTxno: action.payload.txno,
			createdSeqnum: action.payload.seqnum,
		});
	case ActionTypes.CLEAR_BOOKING:
		return Object.assign({}, state, {
			bookingDetail: null,
			htlMessages: null,
			histories: null,
			bookingTxno: null,
			bookingSeqnum: null,
			shouldUpdateBooking: false,
			roHeader: null,
			newSeqnum: null
		});
	default:
		return state;
	}
};

export default booking;
