import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Modal, Form, Button, Row, Col} from 'react-bootstrap';
import CustomDateRangePicker from '../CustomDateRangePicker';

import {dateFormatToSend, isExpired} from '../../util/transformDate.js'

import _extend from 'lodash/extend';
import _get from 'lodash/get';

class CreateModal extends Component {
	constructor(props) {
		super(props);

		this.onFieldChange = this.onFieldChange.bind(this)
		this.onSubmitClick = this.onSubmitClick.bind(this)
		this.onCloseClick = this.onCloseClick.bind(this)
    this.onDateSelect = this.onDateSelect.bind(this);

		this.state = {
			form : {
		    StartDate: '',
				EndDate: '',
				Heading: '',
				Content: '',
			},
			isDateInvalid: false
		};
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevProps.isOpen && this.props.isOpen) {
			this.clearState()
		}
	}

	onFieldChange(e) {
		this.setState({
			form: _extend(this.state.form, {
				[e.target.name]: e.target.value
			})
		})
	}

	onSubmitClick() {
		this.props.handleSubmit(
			this.state.form
		)
	}

	onCloseClick() {
		this.props.handleCloseModal()
	}


  onDateSelect(name, i, startDateValue, endDateValue) {
		this.setState({
			form: _extend(this.state.form, {
				StartDate: dateFormatToSend(startDateValue),
				EndDate: dateFormatToSend(endDateValue),
			}),
			isDateInvalid: isExpired(endDateValue)
		})
	}

	clearState() {
		this.setState({
			form : {
		    StartDate: '',
				EndDate: '',
				Heading: '',
				Content: '',
			},
			isDateInvalid: false
		})
	}

	render() {
		let disabled = true
		if (this.state.form.StartDate && this.state.form.EndDate && this.state.form.Heading && this.state.form.Content && !this.state.isDateInvalid) {
			disabled = false
		}
		return (
			<Modal show={this.props.isOpen} centered onHide={this.onCloseClick} size="md">
			  <Modal.Header closeButton>
			    <Modal.Title>Create Alert Message</Modal.Title>
			  </Modal.Header>

				<Modal.Body>
					<div>
						<Row className="form-group">
							<Col xs={3}>City Code</Col>
							<Col xs={9}>
								{this.props.cityCode}
							</Col>
						</Row>
						<Row className="form-group">
							<Col xs={3}>Heading</Col>
							<Col xs={9}>
								<Form.Control
									type="text"
									value={this.state.form.Heading}
									name="Heading"
									onChange={(e) => this.onFieldChange(e)}
									autoComplete="off"
								/>
							</Col>
						</Row>
						<Row className="form-group">
							<Col xs={3}>Content</Col>
							<Col xs={9}>
								<Form.Control
									as="textarea"
									rows="6"
									value={this.state.form.Content}
									name="Content"
									onChange={(e) => this.onFieldChange(e)}
									autoComplete="off"
								/>
							</Col>
						</Row>
						<Row className="form-group">
							<Col xs={3}>Date</Col>
							<Col xs={9}>
								<CustomDateRangePicker
									handleSelect={this.onDateSelect}
									name={this.state.type}
									startDateValue={_get(this.state.form, 'StartDate')}
									endDateValue={_get(this.state.form, 'EndDate')}
									isInvalid={this.state.isDateInvalid}
								/>
							</Col>
						</Row>
					</div>
			  </Modal.Body>

			  <Modal.Footer>
					<div className="text-center w-100">
						<Button
							variant="primary"
							onClick={this.onSubmitClick}
							disabled={disabled}
						>Submit</Button>
					</div>
			  </Modal.Footer>
			</Modal>
	  );
	}
}

CreateModal.propTypes = {
	isOpen: PropTypes.bool,
	handleCloseModal: PropTypes.func,
	handleSubmit: PropTypes.func,
	cityCode: PropTypes.string,
};

export default CreateModal;
