import React from 'react';
import PropTypes from 'prop-types'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faBed, faUser, faCalendarAlt, faIdCardAlt, faDollarSign} from '@fortawesome/free-solid-svg-icons';
import {prettyDateRange, prettySingleDate, isExpired} from '../../util/transformDate';
import {status, miniStatus} from '../../util/constances';
import Pagination from '../Pagination';

import _map from 'lodash/map';
import _find from 'lodash/find';
import _get from 'lodash/get';
import {Badge, Media} from 'react-bootstrap';;

const SearchResult = (props) => {
	return (
		<div className="px-2 py-4 result-wrapper">
			<h2 className="text-center">Search Results</h2>
			<p>{props.recordQuan} record(s) found</p>
			<table className={`table table-bordered`}>
				<tbody>
					{props.hasResult ?
						_map(props.chunkedBookingRecords[props.currentPage], (result, i) =>
							{
								const staObj = _find(status, (sta) => sta.code.includes(result.bookingStatus))
								const statusArry = ['hK_CNT', 'kK_CNT', 'rQ_CNT', 'xX_CNT'  ]
								return (
									<tr key={i}>
										<td className="text-center">
											{props.searchResult.bookingDetails.indexOf(result) + 1}
										</td>
										<td className="px-0 pb-2 pt-0">

											<div className={`border-bottom h5 px-3 pb-1 pt-1 ${result.txNo.includes('W') ? 'w-booking' : 'one-booking'}`}  >
												{result.txNo} ({result.seqnum})

												<Badge variant={staObj.variant} className="ml-2">{staObj.name}</Badge>

												<div className="status-breakdown small text-muted inline ml-1">({_map(statusArry, (sta) =>
													!!result[sta] && <span key={sta}>{result[sta]} {_find(miniStatus, (s) => s.code.includes(sta)).name}</span>
												)})</div>

												<Badge className="ml-2" style={{backgroundColor: _get(result, ['uiReference', 'alertBgColor']), color: _get(result, ['uiReference', 'alertFontColor'])}}>{_get(result, ['uiReference', 'alertMsg'])}</Badge>

												{result.wTxno &&
													<button className="btn btn-link p-0 float-right font-weight-bold" onClick={() => props.handleDetailClick(result.wTxno, result.seqnum)}>Go to {result.wTxno} ({result.seqnum})</button>
												}
											</div>
											<div className="row px-3">
												<div className="col-4">
													<Media>
														<FontAwesomeIcon icon={faBed} className="mt-2 mr-2 text-secondary" size="sm"/>
														<Media.Body>
															<span className="small text-secondary">Hotel</span>
															<div className="font-weight-bold">
																<a href={`http://portal.adholidays.com/b2c/B2C/Information.aspx?Code=${result.hotelCode}&ViewType=I&P1=kyeD1E4l48A%3d&P2=hhoMxONrizqUj1DXussuAw%3d%3d`} target="_blank" rel="noopener noreferrer" className="link">{result.hotelName} ({result.cityCode})</a>{!!result.creditTerms && <Badge variant="secondary" className="ml-2">{result.creditTerms}</Badge>}
															</div>
														</Media.Body>
													</Media>
													<Media>
														<FontAwesomeIcon icon={faUser} className="mt-2 mr-2 text-secondary" size="sm" style={{width: "18px"}}/>
														<Media.Body>
															<span className="small text-secondary">Lead Guest Name</span>
															<div className="font-weight-bold">
																{result.leadGuestName}
															</div>
														</Media.Body>
													</Media>
												</div>
												<div className="col-3">
													<Media>
													  <FontAwesomeIcon icon={faCalendarAlt} className="mt-2 mr-2 text-secondary" size="sm" />
													  <Media.Body>
															<span className="small text-secondary">Arr-Dep</span>
															<div className="font-weight-bold">{prettyDateRange(result.checkIn, result.checkOut)}</div>
															<span className="small text-secondary">Cxl deadline</span>
															<div className={`font-weight-bold ${isExpired(result.bookingDeadline) ? 'red' : ''}`}>{prettySingleDate(result.bookingDeadline)}</div>
													  </Media.Body>
													</Media>
												</div>
												<div className="col-2">
													<Media>
														<FontAwesomeIcon icon={faDollarSign} className="mt-2 mr-2 text-secondary" size="sm"/>
														<Media.Body>
															<span className="small text-secondary">Sell</span>
															<div className="font-weight-bold">
																{result.sell.currencyCode} {result.sell.value}
															</div>

															<span className="small text-secondary">Net</span>
															<div className="font-weight-bold">
																{result.cost.currencyCode} {result.cost.value}
															</div>
														</Media.Body>
													</Media>
												</div>
												<div className="col-3">
													<Media>
														<FontAwesomeIcon icon={faIdCardAlt} className="mt-2 mr-2 text-secondary" size="sm" />
														<Media.Body>
															<span className="small text-secondary">Booking owner</span>
															<div className="font-weight-bold text-break">{result.typeCode}/{result.login}</div>
														</Media.Body>
													</Media>
													{result.verified &&
														<Media className="text-success">
															<FontAwesomeIcon icon={faCheck} className="mt-2 mr-2 text-secondary"/>
															<Media.Body>
																<div className="pt-1 font-weight-bold">Verified</div>
															</Media.Body>
														</Media>
													}
												</div>
											</div>
										</td>
										<td className="align-middle text-center">
											<button className="btn btn-outline-info btn-block py-4" onClick={() => props.handleDetailClick(result.txNo, result.seqnum)}>Detail</button>
										</td>
									</tr>
								)
							}

						) :
						<tr><td colSpan="11">
							<div className="text-center py-5">
								No records.
							</div>
						</td></tr>
					}
				</tbody>
			</table>
			{props.chunkedBookingRecords.length > 1 &&
				<Pagination
					handlePageChange={props.handlePageChange}
					noOfPages={props.chunkedBookingRecords.length}
					currentPage={props.currentPage}
				/>
			}
		</div>
  );
}

SearchResult.propTypes = {
	recordQuan: PropTypes.number,
	chunkedBookingRecords: PropTypes.array,
	hasResult: PropTypes.bool,
	searchResult: PropTypes.object,
	currentPage: PropTypes.number,
	handlePageChange: PropTypes.func,
	handleDetailClick: PropTypes.func
};

export default SearchResult;
