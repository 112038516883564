import _filter from 'lodash/filter';

export function isChild(string) {
  const regex = /\sAge\s/g;
  return regex.test(string)
}

export function getLastName(string) {
  const regex = /(.*?)(?=\/)/;
  return string.match(regex)[0]
}

export function getFirstName(string) {
  const regex = /(\/)(.*)(?=\s(MR|MSTR|MS|MRS|MISS|MDM|DR|PROF))/g;
  return string.match(regex)[0] === ' ' ? '' : string.match(regex)[0].substring(1)
}

export function getTitle(string) {
  const regex = /(MSTR|MRS|MR|MS|MISS|MDM|DR|PROF)/g;
  const match = string.match(regex);
  return match ?  match[match.length - 1] : ''
}

export function getAge(string) {
  const regex = /\d+$/g;
  return string.match(regex) ? string.match(regex)[0] : ''
}

export function noOfAdult(obj) {
  const adultPaxes = _filter(obj, (o) => !isChild(o.famName))
  return adultPaxes.length
}

export function noOfAdultInArr(arr) {
  const childPaxes = arr.filter(a => !isChild(a))
  return childPaxes.length
}
