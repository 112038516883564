import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Row, Col, Form, Button, Badge} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {withRouter} from 'react-router-dom';

import {paxTitle} from '../util/constances.js';
import {dateFormatToSend, noOfDays} from '../util/transformDate.js';
import {itemType} from '../util/constances.js';

import SupplierCodeSelect from '../components/SupplierCodeSelect';
import CustomDateRangePicker from '../components/CustomDateRangePicker';
import CustomDatePicker from '../components/CustomDatePicker';
import HotelNameSelect from '../components/HotelNameSelect';
import AveragePriceComponent from '../components/BookingDetailPage/AveragePriceComponent';
import ConfirmBookingModal from '../components/ManualBooking/ConfirmBookingModal';
import Title from '../components/Title';
import cogoToast from 'cogo-toast';

import _map from 'lodash/map';
import _extend from 'lodash/extend';
import _cloneDeep from 'lodash/cloneDeep';
import _set from 'lodash/set';
import _pullAt from 'lodash/pullAt';
import _split from 'lodash/split';
import _get from 'lodash/get';

import {createManualBooking} from '../actions/booking';
import {getCurrencyList} from '../actions/index';

class ManualBookingPage extends Component {
  constructor(props) {
    super(props);

		this.onAddPaxClick = this.onAddPaxClick.bind(this)
		this.onPaxesChange = this.onPaxesChange.bind(this)
		this.onRemovePaxClick = this.onRemovePaxClick.bind(this)
    this.onHotelNameSelect = this.onHotelNameSelect.bind(this);
    this.onSupplierCodeSelect = this.onSupplierCodeSelect.bind(this);
    this.onAddServiceClick = this.onAddServiceClick.bind(this);
    this.onDateSelect = this.onDateSelect.bind(this);
    this.onAverageChange = this.onAverageChange.bind(this);
    this.onRemoveItemClick = this.onRemoveItemClick.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.toogleReviewModal = this.toogleReviewModal.bind(this)
    this.submitBooking = this.submitBooking.bind(this);
    this.onTypeClick = this.onTypeClick.bind(this);

    this.state = {
      isAlertMsgPopUp: false,
      txno: "",
      form: {
        "hotelCode": "",
        "child": 0,
        "adult": 0,
        "note1": "",
        "note2": "",
        "hotelRef": "",
        "operatorCode": "",
        "rateType": "F",
        "xxDeadline": "",
        "items": [
        ],
        "passengers": [
        ]
      },
      type: 'new-booking',
      isReviewModalOpen: false,
      selectedSupplierCodeName: '',
      selectedHotelCodeName: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (!this.props.currencyList) {
      this.props.getCurrencyList()
    }

    if (_get(this.props.location, ['state', 'txno'])) {
      this.onTypeClick('appending-booking', this.props.location.state.txno)
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.createdTxno !== this.props.createdTxno) {
      // route to detail page
      this.props.history.push(`/${this.props.createdTxno}-${this.props.createdSeqnum}`)
    }
  }

  onTypeClick(type, txno) {
    this.setState({
      "type": type,
      "txno": txno ? txno : '',
    })
  }

  onAddPaxClick(isChild) {
    var deepForm = _cloneDeep(this.state.form);
		let row = deepForm.passengers
		row.push({
      "firstName": "",
      "lastName": "",
      "prefix": "MR",
      "isAdult": !isChild,
      "age": isChild ? "" : 0
    })

    if (isChild) {
      _set(deepForm, 'child', this.state.form.child + 1)
    } else {
      _set(deepForm, 'adult', this.state.form.adult + 1)
    }

		this.setState({
			form: deepForm
		})
	}

  onPaxesChange(e, i) {
		var deepForm = _cloneDeep(this.state.form);
		this.setState({
			form: _set(deepForm, ['passengers', i, e.target.name], e.target.value)
		})
	}

  onRemovePaxClick(i) {
		var deepForm = _cloneDeep(this.state.form);

    if (deepForm.passengers[i].isAdult) {
      _set(deepForm, 'adult', this.state.form.adult - 1)
    } else {
      _set(deepForm, 'child', this.state.form.child - 1)
    }
    _pullAt(deepForm.passengers, [i]);

		this.setState({
			form: deepForm
		})
	}

  onHotelNameSelect(code, name) {
		this.setState({
			form: _extend(this.state.form, {
				"hotelCode": code
			}),
      selectedHotelCodeName: name
		});
	}

  onSupplierCodeSelect(code, label) {
		this.setState({
			form: _extend(this.state.form, {
				'operatorCode': code
			}),
      selectedSupplierCodeName: label
		})
	}

  onAddServiceClick() {
		var item = {
      "itemName": "",
      "itemType": "R",
      "qty": 1,
      "startDate": "",
      "endDate": "",
      "status": "RQ",
      "sell": {
          "currencyCode": "HKD",
          "value": ''
      },
      "cost": {
          "currencyCode": "HKD",
          "value": ''
      }
		}

		var deepForm = _cloneDeep(this.state.form);
		let itemRow = deepForm.items
		itemRow.push(item)

		this.setState({
			form: deepForm,
		})
	}

  onServiceChange(e, i) {
		const key = e.target.name
		const value = e.target.value

		var deepForm = _cloneDeep(this.state.form);
		this.setState({
			form: _set(deepForm, ['items', i, key], value)
		})
	}

  onDateSelect(name, i, startDateValue, endDateValue) {
    if (name === 'xxDeadline') {
      this.setState({
        form: _extend(this.state.form, {
  				"xxDeadline": dateFormatToSend(startDateValue)
  			})
      })
    } else {
      var deepForm = _cloneDeep(this.state.form);
      _set(deepForm, ['items', i, 'startDate'], dateFormatToSend(startDateValue))
      _set(deepForm, ['items', i, 'endDate'], dateFormatToSend(endDateValue))

      this.setState({
        form: deepForm,
      })

    }

	}

  onPriceChange(e, i) {
		const key = e.target.name
		const value = e.target.value
		const splitKey = _split(key, '-')

		var deepForm = _cloneDeep(this.state.form);

		if(splitKey[1] === 'currencyCode') {
			this.setState({
				form: _set(deepForm, ['items', i, splitKey[0], splitKey[1]], value)
			})
		} else {
			this.setState({
				form: _set(deepForm, ['items', i, splitKey[0], splitKey[1]], value)
			})
		}
	}

  onAverageChange(e, i) {
		const key = e.target.name
		const value = e.target.value
		const price = value * noOfDays(this.state.form.items[i].startDate, this.state.form.items[i].endDate)
		var deepForm = _cloneDeep(this.state.form);

		this.setState({
			form: _set(deepForm, ['items', i, [key], 'value'], price)
		})
	}

  onRemoveItemClick(i) {
		var deepForm = _cloneDeep(this.state.form);
		_pullAt(deepForm.items, [i]);

		this.setState({
			form: deepForm
		})
	}

  onFieldChange(e) {
    this.setState({
      form: _extend(this.state.form, {
				[e.target.name]: e.target.value
			})
    })
  }

  toogleReviewModal() {
		if (
      !this.state.form.hotelCode ||
      !this.state.form.operatorCode ||
      // !this.state.form.rateType ||
      !this.state.form.xxDeadline ||
      this.state.form.items.length === 0 ||
      (this.state.type === 'appending-booking' && this.state.txno === '') ||
      (this.state.type === 'new-booking' && this.state.form.passengers.length === 0)
    ) {
      this.setState({
        isFormInvalid: true
      })

      cogoToast.error('Please fill in the form.')

		} else {
      this.setState({
        isReviewModalOpen: !this.state.isReviewModalOpen,
        isFormInvalid: false
      })
		}
	}

  submitBooking() {
    this.props.createManualBooking(this.state.form, this.state.txno)
  }

  render() {
    return (
      <div>
        <Title title="Manual Booking" browserTitle="Manual Booking"/>
        <div>
          <div className="border-bottom px-2 py-4">
            <Row>
              <Col xs="2" className="detail-label py-2">
                Type
              </Col>
              <Col xs="6">
                <Form.Check
                  type="radio"
                  id="checkbox-type-new-booking"
                  checked={this.state.type === 'new-booking'}
                  label="New Booking"
                  className="mr-5"
                  onChange={() => this.onTypeClick('new-booking')}
                  name="new-booking"
                />
                <Form.Check
                  type="radio"
                  id="checkbox-type-appending-booking"
                  checked={this.state.type === 'appending-booking'}
                  label="Appending Booking"
                  onChange={() => this.onTypeClick('appending-booking')}
                  name="appending-booking"
                />
              </Col>
            </Row>
          </div>
          {this.state.type === "appending-booking" &&
            <div className="border-bottom px-2 py-4">
              <Row>
                <Col xs="2" className="detail-label py-2">
                  *Booking Number
                </Col>
                <Col xs="6">
                  <Form.Control
                    type="text"
                    value={this.state.txno}
                    name="txno"
                    placeholder="Txno"
                    onChange={(e) => this.setState({
                      txno: e.target.value
                    })}
                    isInvalid={this.state.isFormInvalid && this.state.type === "appending-booking" && this.state.txno === ''}
                  />
                </Col>
              </Row>
            </div>
          }

          <div className="border-bottom px-2 py-4">
            {this.state.type === "new-booking" &&
              <Row className="pb-1">
                <Col xs="2" className="detail-label py-2">
                  *Guest
                </Col>
                <Col>
                  <div className="small mb-2">
                    {this.state.form.adult} Adult {this.state.form.child !== 0 ? `- ${this.state.form.child} Child` : ''}
                  </div>
                  <table className={`table table-bordered mb-0 ${this.state.isFormInvalid && !this.state.form.passengers.length ?  'table-danger' : ''}`}>
                    <thead>
                      <tr className="small">
                        <th width="43">#</th>
                        <th width='30%'>Last Name</th>
                        <th width='30%'>First Name</th>
                        <th>Title</th>
                        <th>Age(yr)</th>
                        <th width="127"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {_map(this.state.form.passengers, (pax, i) =>
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            <Form.Control
                              type="text"
                              value={pax.lastName}
                              name="lastName"
                              autoComplete="new-password"
                              onChange={(e) => this.onPaxesChange(e, i)}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              value={pax.firstName}
                              name="firstName"
                              autoComplete="new-password"
                              onChange={(e) => this.onPaxesChange(e, i)}
                            />
                          </td>
                          <td>
                            <Form.Control
                              as="select"
                              name="prefix"
                              value={pax.prefix}
                              onChange={(e) => this.onPaxesChange(e, i)}
                            >
                              {_map(paxTitle, (title, i)=>
                                <option key={i} value={title}>{title}</option>
                              )}
                            </Form.Control>
                          </td>
                          <td>
                            {!pax.isAdult &&
                              <Form.Control
                                type="text"
                                value={pax.age}
                                className="form-control--short"
                                name="age"
                                onChange={(e) => this.onPaxesChange(e, i)}
                              />
                            }
                          </td>
                          <td>
                            <Button
                              variant="secondary"
                              onClick={() => this.onRemovePaxClick(i)}
                              disabled={this.state.form.passengers.length === 1}
                            ><FontAwesomeIcon icon={faTrashAlt} /> Remove</Button>
                          </td>
                        </tr>
                      )}
                      <tr className="bg-white">
                        <td className="text-center" colSpan="6">
                          <button className="btn btn-outline-info" onClick={() => this.onAddPaxClick(false)}>+ Add Adult</button>
                          <button className="btn btn-outline-info ml-3" onClick={() => this.onAddPaxClick(true)}>+ Add Child</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            }
            <Row className="pb-1">
              <Col xs="2" className="detail-label py-2">
                *Cxl Deadline
              </Col>
              <Col xs="6">
                <CustomDatePicker
                  handleSelect={this.onDateSelect}
                  name="xxDeadline"
                  value={this.state.form.xxDeadline}
                  isInvalid={this.state.isFormInvalid && !this.state.form.xxDeadline}
                />
              </Col>
            </Row>
            <Row className="pb-1">
              <Col xs="2" className="detail-label py-2">
                *Supplier code
              </Col>
              <Col xs="6">
                <SupplierCodeSelect
                  handleSelect={this.onSupplierCodeSelect}
                  selectedSupplierCode={this.state.operatorCode}
                  isInvalid={this.state.isFormInvalid && !this.state.form.operatorCode}
                />
              </Col>
            </Row>
            <Row className="pb-1">
              <Col xs="2" className="detail-label py-2">
                Rate Type
              </Col>
              <Col xs="6">
                <Form.Control
                  type="text"
                  value={this.state.form.rateType}
                  name="rateType"
                  maxLength="2"
                  onChange={(e) => this.onFieldChange(e)}
                />
              </Col>
            </Row>
          </div>

          <div className="border-bottom px-2 py-4">
            <Row className="pb-1">
              <Col xs="2" className="detail-label py-2">
                *Hotel
              </Col>
              <Col xs="6">
                <HotelNameSelect
                  handleSelect={this.onHotelNameSelect}
                  selectedHotelName={this.state.form.hotelCode}
                  isClearable={false}
                  isInvalid={this.state.isFormInvalid && !this.state.form.hotelCode}
                />
              </Col>
            </Row>
            <Row className="pb-1">
              <Col xs="2" className="detail-label py-2">
                *Service Details
              </Col>
              <Col>
                <table className={`table table-bordered mb-0 table-striped ${this.state.isFormInvalid && !this.state.form.items.length ?  'table-danger' : ''}`}>
                  <thead>
                    <tr className="small">
                      <th width="43">#</th>
                      <th colSpan={4}></th>
                    </tr>
                  </thead>
                  <tbody >
                    {_map(this.state.form.items, (item, i) =>
                      {
                        return (
                          <tr key={i}>
                            <td >{i + 1}</td>
                            <td className="pb-0 pt-2">
                              <Row className="pb-2 border-bottom">
                                <Col xs={'2'} className="border-right">
                                  <Form.Control
                                    as="select"
                                    value={item.itemType}
                                    name="itemType"
                                    onChange={(e) => this.onServiceChange(e, i)}
                                  >
                                    {_map(itemType, (type)=>
                                      <option key={type.value} value={type.value}>{type.key}</option>
                                    )}
                                  </Form.Control>
                                </Col>
                                <Col xs={'auto'}>
                                  <Form.Control
                                    type="text"
                                    value={item.qty}
                                    name="qty"
                                    className="form-control--short"
                                    onChange={(e) => this.onServiceChange(e, i)}
                                  />
                                </Col>
                                <Col>
                                  <span className="slash">x</span>
                                  <Form.Control
                                    type="text"
                                    value={item.itemName}
                                    name="itemName"
                                    onChange={(e) => this.onServiceChange(e, i)}
                                    placeholder="Item Name"
                                  />
                                </Col>
                              </Row>

                              <Row className="pt-2">
                                <Col xs={'auto'} className="mb-2 mt-1">
                                  <Form.Label className="mr-2 mt-0 small font-weight-bold">Status</Form.Label>
                                  <Badge variant="warning" className="text-normal">RQ</Badge>
                                </Col>
                                <Col xs={'auto'} className="mb-2">
                                  <div className="form-inline">
                                    <Form.Label className="mr-2 mt-0 small font-weight-bold">Date</Form.Label>
                                    <CustomDateRangePicker
                                      handleSelect={this.onDateSelect}
                                      name="itemDate"
                                      startDateValue={item.startDate}
                                      endDateValue={item.endDate}
                                      index={i}
                                      isSmall
                                    />
                                  </div>
                                </Col>

                                <Col xs={'auto'} className=" mb-2">
                                  <div className="form-inline">
                                    <Form.Label className="mr-2 mt-0 small font-weight-bold">Selling</Form.Label>

                                    <Form.Control
                                      as="select"
                                      className="custom-select-sm mr-1"
                                      value={item.sell.currencyCode}
                                      name="sell-currencyCode"
                                      onChange={(e) => this.onPriceChange(e, i)}
                                    >
                                      {this.props.currencyList && _map(this.props.currencyList, (cur, i) =>
                                        <option key={i} value={cur}>{cur}</option>
                                      )}
                                    </Form.Control>

                                    <Form.Control
                                      type="text"
                                      onChange={(e) => this.onPriceChange(e, i)}
                                      name="sell-value"
                                      value={item.sell.value}
                                      className="form-control-sm form-control--w70 mr-2"
                                      min="0"
                                    />

                                    <AveragePriceComponent
																			item={item}
																			i={i}
																			name="sell"
																			handleAvgPrivceChange={this.onAverageChange}
																		/>

                                  </div>
                                </Col>

                                <Col xs={'auto'} className="mb-2">
                                  <div className="form-inline">
                                    <Form.Label className="mr-2 mt-0 small font-weight-bold">Net</Form.Label>

                                    <Form.Control
                                      as="select"
                                      className="custom-select-sm mr-1 red"
                                      value={item.cost.currencyCode}
                                      name="cost-currencyCode"
                                      onChange={(e) => this.onPriceChange(e, i)}
                                    >
                                      {this.props.currencyList && _map(this.props.currencyList, (cur, i) =>
                                        <option key={i} value={cur}>{cur}</option>
                                      )}
                                    </Form.Control>

                                    <Form.Control
                                      type="text"
                                      onChange={(e) => this.onPriceChange(e, i)}
                                      name="cost-value"
                                      value={item.cost.value}
                                      className="form-control-sm form-control--w70 mr-2 red"
                                      min="0"
                                    />

                                    <AveragePriceComponent
																			item={item}
																			i={i}
																			name="cost"
																			handleAvgPrivceChange={this.onAverageChange}
																		/>
                                  </div>
                                </Col>


                                <Col xs={'auto'} className="ml-auto text-right">
                                  <Button
                                    variant="secondary"
                                    size="sm"
                                    onClick={() => this.onRemoveItemClick(i)}
                                    disabled={this.state.form.items.length === 1}
                                    className="mb-1"
                                  ><FontAwesomeIcon icon={faTrashAlt} /> Remove</Button>
                                </Col>

                              </Row>
                            </td>
                          </tr>
                        )
                      }
                    )}
                    <tr className="bg-white">
                      <td className="text-center" colSpan="6">
                        <button className="btn btn-outline-info" id="add-guest-adult" onClick={() => this.onAddServiceClick()}>+ Add Item</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
            <Row className="pb-1">
              <Col xs="2" className="detail-label py-2">
                Hotel reference
              </Col>
              <Col xs="6">
                <Form.Control
                  type="text"
                  value={this.state.form.hotelRef}
                  name="hotelRef"
                  onChange={(e) => this.onFieldChange(e)}
                />
              </Col>
            </Row>
            <Row className="pb-1">
              <Col xs="2" className="detail-label py-2">
                Booking Remarks
              </Col>
              <Col xs="6">
                <Form.Control
                  type="text"
                  onChange={(e) => this.onFieldChange(e)}
                  name="note1"
                  value={this.state.note1}
                  className="mb-2"
                />
                <Form.Control
                  type="text"
                  onChange={(e) => this.onFieldChange(e)}
                  name="note2"
                  value={this.state.note2}
                />
              </Col>
            </Row>
          </div>

          <div className="p-4">
            <Row className="row justify-content-center">
              <Col xs="3" className="text-center">
                <Button
                  variant="info"
                  className=""
                  type="button"
                  size="lg"
                  onClick={() => this.toogleReviewModal()}
                >Review Booking</Button>
              </Col>
            </Row>
          </div>
        </div>

        <ConfirmBookingModal
          bookingInfo={this.state.form}
          txno={this.state.txno}
          isOpen={this.state.isReviewModalOpen}
          handleCloseModal={this.toogleReviewModal}
          handleSubmitClick={this.submitBooking}
          selectedSupplierCodeName={this.state.selectedSupplierCodeName}
          selectedHotelCodeName={this.state.selectedHotelCodeName}
          itemType={itemType}
        />


      </div>
    );
  }
}


ManualBookingPage.propTypes = {
	getCurrencyList: PropTypes.func,
	createManualBooking: PropTypes.func,
  msg: PropTypes.string,
  isError: PropTypes.bool,
  currencyList: PropTypes.array,
  createdTxno: PropTypes.string,
  createdSeqnum: PropTypes.string,
};

function mapStateToProps(state) {
	return {
		currencyList: state.general.currencyList,
		createdTxno: state.booking.createdTxno,
		createdSeqnum: state.booking.createdSeqnum,
	}
}

const mapDispatchToProps = {
  getCurrencyList,
  createManualBooking,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManualBookingPage));
