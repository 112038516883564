import {createStore, applyMiddleware} from "redux";
import thunk from 'redux-thunk';
// Logger with default options
import logger from "redux-logger";

import reducers from "./reducers/index";

export default createStore(reducers, applyMiddleware(thunk, logger));

// for chrome redux dev tool
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
// export default createStore(
//   reducers, /* preloadedState, */
//   composeEnhancers(
//     applyMiddleware(thunk, logger)
//   ));
