import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Auth from '../../Auth/Auth';
import {ComboBox, ComboBoxInput, ComboBoxList, ComboBoxOption, ComboBoxOptionText, ListItem} from "sancho";

import _debounce from 'lodash/debounce';
import _template from 'lodash/template';

const currentServerURL = process.env.REACT_APP_serverURL;
const auth = new Auth();

const endpoint = _template(`${currentServerURL}/information/RO/DestinationList?keywords=<%= string %>`);
const method = 'GET'

class ROHistoryToSelect extends Component {
	constructor(props) {
		super(props);
		this.onSelectInputChange = this.onSelectInputChange.bind(this);
		this.onSelectClick = this.onSelectClick.bind(this);
		this.delayedCallback = _debounce(this.onSelectInputChange, 1000).bind(this);
		this.onQueryChange = this.onQueryChange.bind(this);

		this.state = {
			options: [],
			isSearching: false,
			value: '',
		};
	}

	componentDidMount() {
		if (this.props.value) {
			this.setValue(this.props.value)
		}
	}

	setValue() {
		this.setState({
			value: this.props.value
		})
	}

	onQueryChange(value) {
		this.setState({
			value: value,
			options: null
		})

		if (value) {
			this.delayedCallback(value)
		} else {
			this.clearOptions()
		}
	}

	onSelectInputChange(string) {
		this.props.handleSelect(string)

		if (string) {
			this.setState({
				isSearching: true
			})
			fetch(endpoint({'string' : string}), {
				method: method,
				headers: {
					Authorization: 'Bearer ' + auth.getAccessToken(),
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			})
				.then(response => response.json())
				.then((result) => {
					if (result.length > 0) {
						this.setState({
							options: result,
							isSearching: false,
						})
					} else {
						this.setState({
							isSearching: false
						})
					}
				})
				.catch((error) => {
					console.log('error: ', error)
					this.setState({
						isSearching: false
					})
				});

		} else {
			this.clearOptions()
		}
	}

	onSelectClick(selectedOption) {
		if (selectedOption) {
			this.setState({
				value: selectedOption.destination
			})
			this.props.handleSelect(selectedOption.destination)
		}
	}

	clearOptions() {
		this.setState({
			options: '',
		});
	}

  render() {
		return (
			<div>
				<ComboBox
					query={this.state.value}
		      onQueryChange={v => this.onQueryChange(v)}
		      onSelect={v => this.onSelectClick(v)}
				>
					<ComboBoxInput
						className="form-control"
						aria-label="Query product"
						placeholder="Type email"
						autoComplete="new-password"
					/>
					{this.state.value && this.state.options && this.state.options.length > 0 && (
						<ComboBoxList aria-label="Query product">
						  {this.state.options.map((entry, i) => (
						    <ComboBoxOption value={entry} key={i}>
						      <ListItem
						        interactive={false}
						        primary={<ComboBoxOptionText value={entry.destination} />}
						      />
						    </ComboBoxOption>
						  ))}
						</ComboBoxList>
					)}
				</ComboBox>
				{this.state.isSearching &&
					<div className="spinner-border spinner-border-sm text-secondary product-search-bar__loading" role="status">
					  <span className="sr-only">Loading...</span>
					</div>
				}
			</div>
    );
  }
}

ROHistoryToSelect.propTypes = {
	handleSelect: PropTypes.func,
	value: PropTypes.string
}

export default ROHistoryToSelect;
