import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch, faTrashAlt, faEdit, faCheck, faTimes} from '@fortawesome/free-solid-svg-icons';

import Title from '../components/Title';
import {Form, Button, Row, Col} from 'react-bootstrap';
import CityNameSelect from '../components/CityNameSelect';
import Pagination from '../components/Pagination';
import CreateModal from '../components/AlertMsg/CreateModal';
import CustomDateRangePicker from '../components/CustomDateRangePicker';

import {prettyDateRange, dateFormatToSend} from '../util/transformDate.js';

import _get from 'lodash/get';
import _chunk from 'lodash/chunk';
import _trim from 'lodash/trim';
import _extend from 'lodash/extend';

import {
  getAlertMsg,
  deleteAlertMsg,
  createMsg,
  updateAlertMsg,
  clearAlertMsgRecords,
} from '../actions/alertMsg'; // Import your actions

const chunkSize = 10;

class AlertMessage extends Component {
  constructor(props) {
    super(props);
    this.onCityNameSelect = this.onCityNameSelect.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
		this.onPageChange = this.onPageChange.bind(this)
		this.createMsg = this.createMsg.bind(this)
		this.onEditClick = this.onEditClick.bind(this)
		this.onCheckClick = this.onCheckClick.bind(this)
		this.onDateSelect = this.onDateSelect.bind(this)
		this.onCancelClick = this.onCancelClick.bind(this)

    this.state = {
      selectedCityName: null,
      currentPage: 0,
      isCreateModalOpen: false,
      editAutoNum: null,
      editMsg: {
        heading: '',
        content: '',
        startDate: '',
        endDate: '',
      },
      errorMsg: null
    };
  }

  componentWillUnmount() {
    this.props.clearAlertMsgRecords()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.shouldUpdateAlertMsg && this.props.shouldUpdateAlertMsg) {
      this.getUpdatedAlertMsg();
    }
  }

	onCityNameSelect(code) {
		this.setState({
			selectedCityName: code
		});
	}

	onKeyDown(e) {
		if (e.key === 'Enter' && this.state.selectedCityName) {
			this.props.getAlertMsg(this.state.selectedCityName);
		}
	}

  onSearchClick() {
    if (this.state.selectedCityName) {
      this.setState({
        errorMsg: null
      })
      this.props.getAlertMsg(this.state.selectedCityName);
    } else {
      this.setState({
        errorMsg: 'Please input City Name.'
      })
    }
  }

	onPageChange(page) {
		this.setState({
			currentPage: page
		})
	}

  onDeleteClick(autoNum) {
    this.props.deleteAlertMsg(autoNum);
  }

  onCreateClick() {
    this.setState({
      isCreateModalOpen: true
    })
  }

  onFieldChange(e) {
    this.setState({
      editMsg: _extend(this.state.editMsg, {
        [e.target.name]: e.target.value,
      })
    })
  }

  onEditClick(detail) {
    this.setState({
      editAutoNum: detail.autoNum,
      editMsg: {
        heading: _trim(detail.heading),
        content: _trim(detail.content),
        startDate: detail.startDate,
        endDate: detail.endDate,
      },
    })
  }

  onCancelClick(detail) {
    this.setState({
      editAutoNum: null,
      editMsg: {
        heading: '',
        content: '',
        startDate: '',
        endDate: '',
      },
    })
  }

  onCheckClick() {
    this.props.updateAlertMsg(this.props.cityCode, this.state.editAutoNum, this.state.editMsg)
  }

  onDateSelect(name, i, startDateValue, endDateValue) {
    this.setState({
      editMsg: _extend(this.state.editMsg, {
        startDate: dateFormatToSend(startDateValue),
        endDate: dateFormatToSend(endDateValue),
      })
    })
	}

  getUpdatedAlertMsg() {
    this.props.getAlertMsg(this.props.cityCode);
    this.setState({
      isCreateModalOpen: false,
      editAutoNum: null,
      editMsg: {
        heading: '',
        content: '',
        startDate: '',
        endDate: '',
      },
    })
  }

  createMsg(form) {
    this.props.createMsg(this.props.cityCode, form);
  }

  render () {
		const hasRecord = this.props.details && this.props.details.length > 0

    return (
      <div>
        <Title title="Alert Message" browserTitle="Alert Message"/>
        <Row className={`justify-content-center ${this.props.cityCode ? '' : 'pt-30vh'}`}>
          <Col xs="6" className={`mt-5 pb-5 ${this.props.details ? 'border-bottom' : ''}`}>
            <div onKeyDown={(e) => this.onKeyDown(e)}>
              <Row>
                <Col xs="3" className="text-right"><Form.Label>City Code</Form.Label></Col>
                <Col xs="6">
                  <CityNameSelect
                    handleSelect={this.onCityNameSelect}
                    selectedCode={this.state.selectedCityName}
                  />
                  {this.state.errorMsg &&
                    <p className="small red mb-0 pt-1">
                      {this.state.errorMsg}
                    </p>
                  }
                </Col>
                <Col xs="3">
                  <Button variant="primary" onClick={() => this.onSearchClick()}><FontAwesomeIcon icon={faSearch} className="mr-1" /> Search</Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>

				{this.props.details &&
          <Row className="justify-content-center">
            <Col className="py-5">
              <div className="px-2">
                <p>City Code: <span className="font-weight-bold">{this.props.cityCode}</span> | {this.props.details.length} record(s)</p>
                <table className={`table table-bordered ${hasRecord && this.props.details.length > 1 ? 'table-striped' : ''}`}>
        					<thead>
        						<tr>
        							<th width="10">#</th>
        							<th width="230">Heading</th>
        							<th >Content</th>
        							<th width="210">Date</th>
        							<th width="180"></th>
        						</tr>
        					</thead>
        					<tbody>
        						{hasRecord ?
        						_chunk(this.props.details, chunkSize)[this.state.currentPage].map((detail, i) =>
      								<tr key={detail.autoNum}>
      									<td>{this.props.details.indexOf(detail) + 1}</td>
      									<td>
                          {this.state.editAutoNum === detail.autoNum ?
                            <Form.Control
          										type="text"
          										value={this.state.editMsg.heading}
          										name="heading"
          										onChange={(e) => this.onFieldChange(e)}
          										autoComplete="off"
          									/> :
                            _trim(_get(detail, 'heading'))
                          }
                        </td>
      									<td className="alertMsg__content__td">
                          {this.state.editAutoNum === detail.autoNum ?
                            <Form.Control
                              as="textarea"
          										rows="4"
                              value={this.state.editMsg.content}
                              name="content"
                              onChange={(e) => this.onFieldChange(e)}
                            /> :
                            <p className="alertMsg__content">{_trim(_get(detail, 'content'))}</p>
                          }</td>
      									<td>
                          {this.state.editAutoNum === detail.autoNum ?
                            <CustomDateRangePicker
                              handleSelect={this.onDateSelect}
                              startDateValue={this.state.editMsg.startDate}
                              endDateValue={this.state.editMsg.endDate}
                            /> :
                            prettyDateRange(_get(detail, 'startDate'), _get(detail, 'endDate'))
                          }
                        </td>
      									<td className="text-center">

                          {this.state.editAutoNum === detail.autoNum ?
                            <button
          										className="btn btn-outline-info"
          										type="button"
          										onClick={() => this.onCheckClick(detail)}
          									><FontAwesomeIcon icon={faCheck} /> Update</button> :
                            <button
          										className="btn btn-outline-info"
          										type="button"
          										onClick={() => this.onEditClick(detail)}
          									><FontAwesomeIcon icon={faEdit} /> Edit</button>
                          }

                          {this.state.editAutoNum === detail.autoNum ?
                            <button
          										className="btn btn-outline-info ml-3"
          										type="button"
          										onClick={() => this.onCancelClick()}
          									><FontAwesomeIcon icon={faTimes} /></button> :
                            <button
          										className="btn btn-outline-secondary ml-3"
          										type="button"
          										onClick={() => this.onDeleteClick(_get(detail, 'autoNum'))}
          									><FontAwesomeIcon icon={faTrashAlt} /></button>
                          }
                        </td>
      								</tr>) :
        							<tr><td colSpan="5">
        								<div className="text-center py-5">
        									No detail.
        								</div>
        							</td></tr>
        						}
                    <tr>
                      <td className="text-center bg-white" colSpan="5">
                        <button className="btn btn-outline-info" onClick={() => this.onCreateClick()}>+ Create Alert Message</button>
                      </td>
                    </tr>
        					</tbody>
        				</table>
        				{_chunk(this.props.details, chunkSize).length > 1 &&
        					<Pagination
        						handlePageChange={this.onPageChange}
        						noOfPages={_chunk(this.props.details, chunkSize).length}
        						currentPage={this.state.currentPage}
        					/>
        				}
              </div>
            </Col>
    			</Row>
        }

        <CreateModal
          isOpen={this.state.isCreateModalOpen}
          handleCloseModal={() => this.setState({isCreateModalOpen: false})}
          handleSubmit={this.createMsg}
          cityCode={this.props.cityCode}
        />

      </div>
    );
  }
}

AlertMessage.propTypes = {
  getAlertMsg: PropTypes.func,
  deleteAlertMsg: PropTypes.func,
  createMsg: PropTypes.func,
  updateAlertMsg: PropTypes.func,
  clearAlertMsgRecords: PropTypes.func,
  cityCode: PropTypes.string,
  shouldUpdateAlertMsg: PropTypes.bool,
};

function mapStateToProps(state) {
	return {
    details: state.alertMsg.details,
    cityCode: state.alertMsg.cityCode,
    shouldUpdateAlertMsg: state.alertMsg.shouldUpdateAlertMsg,
	}
}

const mapDispatchToProps = {
  getAlertMsg,
  deleteAlertMsg,
  createMsg,
  updateAlertMsg,
  clearAlertMsgRecords,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertMessage);
