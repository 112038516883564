import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Form, Button, Row, Col, Badge} from 'react-bootstrap';
import CustomDateRangePicker from '../CustomDateRangePicker';
import TypeCodeSelect from '../TypeCodeSelect';
import LoginSelect from '../LoginSelect';
import CityNameSelect from '../CityNameSelect';
import SuppCodeSelect from '../SupplierCodeSelect';
import HotelNameSelect from '../HotelNameSelect';
import {dateFormatToSend} from '../../util/transformDate.js'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';

import _get from 'lodash/get';
import _concat from 'lodash/concat';
import _pull from 'lodash/pull';
import _extend from 'lodash/extend';
import _omit from 'lodash/omit';
import _intersection from 'lodash/intersection';

class SearchForm extends Component {
	constructor(props) {
		super(props);
		this.onRefNumberDropdownChange = this.onRefNumberDropdownChange.bind(this);
		this.onFieldChange = this.onFieldChange.bind(this);
		this.onRefNumberChange = this.onRefNumberChange.bind(this);
		this.onDateSelect = this.onDateSelect.bind(this);
		this.onCheckboxClick = this.onCheckboxClick.bind(this);
		this.onTypeCodeSelect = this.onTypeCodeSelect.bind(this);
		this.onLoginSelectClick = this.onLoginSelectClick.bind(this);
		this.onCityNameSelect = this.onCityNameSelect.bind(this);
		this.onHotelNameSelect = this.onHotelNameSelect.bind(this);
		this.onSuppCodeSelect = this.onSuppCodeSelect.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onResetClick = this.onResetClick.bind(this);

		this.state = {
			refNumberName: 'BookingRef',
			refNumberValue: '',
			cityNameOptions: [],
			hotelOptions: [],
			selectedTypeCode: null,
			selectedLogin: null,
			selectedCityName: null,
			selectedHotelName: null,
			selectedSuppCode: null,
			isConfirm: true,
			isCancel: true,
			isRequest: true,
			criteria: {
				"BookingRef": "",
				"InvoiceNumber": "",
				"VoucherNumber": "",
				"AgentReferenceNumber": "",
				"EngRefNo": "",
				"HotelRefNo": "",
				"TypeCode": "",
				"Login": "",
				"GuestName": "",
				"CityCode": "",
				"HotelCode": "",
				"SuppCode": "",
				"BookingStatus": [1, 2, 3],
				"ViewType": "ALL",
				"StayPeriod": null,
				"BookingPeriod": null,
				"SearchFutureBooking": true,
      }
		};

	}

	componentDidMount() {
		if (this.props.criteria) {
			this.getCriteria();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!this.state.isConfirm && !this.state.isCancel &&!this.state.isRequest) {
			this.setBookingStatus()
		}
	}

	onTypeCodeSelect(typeCode, login) {
		this.setState({
			selectedTypeCode: typeCode ? typeCode : null,
			selectedLogin: null,
			criteria: _extend(this.state.criteria, {
				"TypeCode": typeCode,
				"Login": login
			})
		});
	}

	onCityNameSelect(code) {
		this.setState({
			selectedCityName: code,
			criteria: _extend(this.state.criteria, {
				"CityCode": code
			})
		});
	}
	onSuppCodeSelect(code) {
		this.setState({
			selectedSuppCode: code,
			criteria: _extend(this.state.criteria, {
				"SuppCode": code
			})
		});
	}

	onHotelNameSelect(code) {
		this.setState({
			selectedHotelName: code,
			criteria: _extend(this.state.criteria, {
				"HotelCode": code
			})
		});
	}

	onLoginSelectClick(login) {
		if (!login) {
			this.setState({
				selectedLogin: null,
				criteria: _extend(this.state.criteria, {
					"Login": ''
				})
			});
		} else {
			this.setState({
				selectedLogin: login,
				criteria: _extend(this.state.criteria, {
					"Login": login
				})
			});
		}
	}

	onRefNumberDropdownChange(e) {
		let refNumberName = e.target.value

		const refNumberNames = {
      'BookingRef': '',
      'InvoiceNumber': '',
      'VoucherNumber': '',
      'AgentReferenceNumber': '',
      'EngRefNo': '',
      'HotelRefNo': ''
    }
		const otherRefName = _omit(refNumberNames, [e.target.value])
		this.setState({
			refNumberName: refNumberName,
			criteria: _extend(this.state.criteria, otherRefName, {
				[e.target.value]: this.state.refNumberValue,
			})
		})
	}

	onFieldChange(e) {
		let fieldName = e.target.name
		let fieldValue = e.target.value

		this.setState({
			criteria: _extend(this.state.criteria, {
				[fieldName]: fieldValue
			})
		})
	}

	onRefNumberChange(e) {
		this.setState({
			refNumberValue: e.target.value,
			criteria: _extend(this.state.criteria, {
				[this.state.refNumberName]: e.target.value
			})
		})
	}

	onCheckboxClick(e, val) {
		let fieldName = e.target.name
		let isFieldChecked = e.target.checked
		if (fieldName === "SearchFutureBooking") {
			this.setState({
				criteria: _extend(this.state.criteria, {
					"SearchFutureBooking": isFieldChecked
				})
			})
		} else {
			let BookingStatusArr = this.state.criteria.BookingStatus
			let newBokingStatusArr = []
			if (isFieldChecked) {
				newBokingStatusArr = _concat(BookingStatusArr, val)
			} else {
				newBokingStatusArr = _pull(BookingStatusArr, val)
			}

			this.setState({
				[fieldName]: isFieldChecked,
				criteria: _extend(this.state.criteria, {
					"BookingStatus": newBokingStatusArr
				})
			})
		}
	}

	setBookingStatus() {
		this.setState({
			isConfirm: true,
			isCancel: true,
			isRequest: true,
			criteria: _extend(this.state.criteria, {
				"BookingStatus": [1, 2, 3],
			})
		})
	}

	onDateSelect(name, i, startDateValue, endDateValue) {
		let obj
		if (startDateValue) {
			obj = {
				"DateFrom": dateFormatToSend(startDateValue),
				"DateTo": dateFormatToSend(endDateValue),
			}
		} else {
			obj = null
		}

		this.setState({
			criteria: _extend(this.state.criteria, {
				[name]: obj
			})
		})
	}

	onKeyDown(e) {
		if (e.key === 'Enter') {
			this.props.handleFormSubmit(this.state.criteria)
		}
	}

	onSubmit() {
		this.props.handleFormSubmit(this.state.criteria)
	}

	onResetClick() {
		this.setState({
			refNumberName: 'BookingRef',
			refNumberValue: '',
			cityNameOptions: [],
			hotelOptions: [],
			selectedTypeCode: null,
			selectedLogin: null,
			selectedCityName: null,
			selectedHotelName: null,
			isConfirm: true,
			isCancel: true,
			isRequest: true,
			criteria: {
				"BookingRef": "",
				"InvoiceNumber": "",
				"VoucherNumber": "",
				"AgentReferenceNumber": "",
				"EngRefNo": "",
				"HotelRefNo": "",
				"TypeCode": "",
				"Login": "",
				"GuestName": "",
				"CityCode": "",
				"SuppCode": "",
				"HotelCode": "",
				"BookingStatus": [1, 2, 3],
				"ViewType": "ALL",
				"StayPeriod": null,
				"BookingPeriod": null,
				"SearchFutureBooking": true,
      }
		})
	}

	getCriteria() {
		if (this.props.criteria) {
			const refNumberNames = {
	      'BookingRef': '',
	      'InvoiceNumber': '',
	      'VoucherNumber': '',
	      'AgentReferenceNumber': '',
	      'EngRefNo': '',
	      'HotelRefNo': ''
	    }
			const refName = _intersection(Object.keys(this.props.criteria),
				Object.keys(refNumberNames))[0]

			this.setState({
				refNumberName: refName ? refName : 'BookingRef',
				refNumberValue: _get(this.props.criteria, refName, ''),
				selectedTypeCode: this.props.criteria.TypeCode ? this.props.criteria.TypeCode : null,
				selectedLogin: this.props.criteria.Login ? this.props.criteria.Login : null,
				selectedCityName: this.props.criteria.CityCode ? this.props.criteria.CityCode : null,
				selectedSuppCode: this.props.criteria.SuppCode ? this.props.criteria.SuppCode : null,
				selectedHotelName: this.props.criteria.HotelCode ? this.props.criteria.HotelCode : null,
				isConfirm: this.props.criteria.BookingStatus.includes(1),
				isCancel: this.props.criteria.BookingStatus.includes(2),
				isRequest: this.props.criteria.BookingStatus.includes(3),
				criteria: _extend(this.state.criteria, this.props.criteria)
			})
		}
	}

  render() {
		const refNumberNames = [
			{value: 'BookingRef', label: 'Booking Number'},
			{value: 'InvoiceNumber', label: 'Invoice Number'},
			{value: 'VoucherNumber', label: 'Voucher Number'},
			{value: 'AgentReferenceNumber', label: 'Agent Reference Number'},
			{value: 'EngRefNo', label: 'Hotel Supplier Reference Number'},
			{value: 'HotelRefNo', label: 'Hotel Reference Number'},
		]

		return (
      <Row>
			  <Col xs="8" className={`pt-4 offset-2 ${this.props.hasResult ? 'border-bottom' : ''}`}>
					<Form onKeyDown={(e) => this.onKeyDown(e)}>
						<Row className='pt-2 pb-4 mb-4 border-bottom'>
						  <Col xs="4">
								<Form.Control
									as="select"
									onChange={this.onRefNumberDropdownChange}
									name="refNumberDropdown"
									value={this.state.refNumberName}
								>
									{refNumberNames.map((name, i) =>
										<option key={i} value={name.value}>{name.label}</option>
									)}
								</Form.Control>
							</Col>
						  <Col xs="8">
							  <Form.Control
									type="text"
									onChange={(e) => this.onRefNumberChange(e)}
									name={this.state.refNumberName}
									autoComplete="off"
									value={this.state.refNumberValue}
									maxLength={this.state.refNumberName === "BookingRef" ? 10 : 100}
								/>
							</Col>
						</Row>

						<h2>Advanced Search</h2>
						<Form.Group>
							<Row>
								<Col xs="3"><Form.Label>Company</Form.Label></Col>
								<Col xs="4">
									<TypeCodeSelect
										handleSelect={this.onTypeCodeSelect}
										selectedTypeCode={(this.props.criteria && this.props.criteria.TypeCode) || this.state.selectedTypeCode}
										isClearable
									/>
								</Col>
								<Col xs="1"><Form.Label>User</Form.Label></Col>
								<Col xs="4">
									<LoginSelect
										handleSelect={this.onLoginSelectClick}
										selectedTypeCode={this.state.selectedTypeCode}
										selectedLogin={this.state.selectedLogin}
										isClearable
									/>
								</Col>
							</Row>

						</Form.Group>

						<Form.Group controlId="formTravelerName">
							<Row>
								<Col xs="3"><Form.Label>Traveler Name</Form.Label></Col>
								<Col xs="9">
									<Form.Control
										type="text"
										onChange={(e) => this.onFieldChange(e)}
										name="GuestName"
										autoComplete="new-password"
										value={_get(this.state.criteria, 'GuestName')}
									/>
								</Col>
							</Row>
						</Form.Group>

						<Form.Group controlId="formCityName">
							<Row>
								<Col xs="3"><Form.Label>City Code</Form.Label></Col>
								<Col xs="9">
									<CityNameSelect
										handleSelect={this.onCityNameSelect}
										selectedCode={(this.props.criteria && this.props.criteria.CityCode) || this.state.selectedCityName}
									/>
								</Col>
							</Row>
						</Form.Group>

						<Form.Group controlId="formHotelName">
							<Row>
								<Col xs="3"><Form.Label>Hotel Name</Form.Label></Col>
								<Col xs="9">
									<HotelNameSelect
										handleSelect={this.onHotelNameSelect}
										selectedHotelName={(this.props.criteria && this.props.criteria.HotelCode) ||this.state.selectedHotelName}
										cityCode={this.state.selectedCityName}
										isClearable
									/>
								</Col>
							</Row>
						</Form.Group>

						<Form.Group controlId="formBookingStatus">
							<Row>
								<Col xs="3"><Form.Label>Booking Status</Form.Label></Col>
								<Col xs="9">
									<Form.Check
										type="checkbox"
										id="checkbox-confirm"
										label={<Badge variant="success" className="text-normal">Confirm (KK-HK-AA)</Badge>}
										className="mr-4"
										checked={this.state.isConfirm}
										onChange={(e) => this.onCheckboxClick(e, 1)}
										name="isConfirm"
									/>
									<Form.Check
										type="checkbox"
										id="checkbox-cancel"
										label={<Badge variant="danger" className="text-normal">Cancel (XX-UC-HX)</Badge>}
										className="mr-4"
										checked={this.state.isCancel}
										onChange={(e) => this.onCheckboxClick(e, 2)}
										name="isCancel"
									/>
									<Form.Check
										type="checkbox"
										id="checkbox-request"
										label={<Badge variant="warning" className="text-normal">Request (RQ-HN)</Badge>}
										checked={this.state.isRequest}
										onChange={(e) => this.onCheckboxClick(e, 3)}
										name="isRequest"
									/>
								</Col>
							</Row>
						</Form.Group>

						<Form.Group controlId="formViewType">
							<Row>
								<Col xs="3"><Form.Label>View Type</Form.Label></Col>
								<Col xs="9">
									<Form.Control as="select" onChange={(e) => this.onFieldChange(e)} name="ViewType" value={this.state.criteria.ViewType} >
										<option>ALL</option>
										<option>TEAM</option>
										<option>COMPANY</option>
										<option>MYSELF</option>
									</Form.Control>
								</Col>
							</Row>
						</Form.Group>

						<Form.Group controlId="formStayPeriod">
							<Row>
								<Col xs="3"><Form.Label>Stay Period</Form.Label></Col>
								<Col xs="9">
									<CustomDateRangePicker
										handleSelect={this.onDateSelect}
										name="StayPeriod"
										startDateValue={_get(this.state.criteria.StayPeriod, 'DateFrom')}
										endDateValue={_get(this.state.criteria.StayPeriod, 'DateTo')}
										isClearAvailable
									/>
								</Col>
							</Row>
						</Form.Group>

						<Form.Group controlId="formBookingPeriod">
							<Row>
								<Col xs="3"><Form.Label>Booking Period</Form.Label></Col>
								<Col xs="9">
									<CustomDateRangePicker
										handleSelect={this.onDateSelect}
										name="BookingPeriod"
										startDateValue={_get(this.state.criteria.BookingPeriod, 'DateFrom', '')}
										endDateValue={_get(this.state.criteria.BookingPeriod, 'DateTo', '')}
										isClearAvailable
									/>
								</Col>
							</Row>
						</Form.Group>

						<Form.Group controlId="formSuppCode">
							<Row>
								<Col xs="3"><Form.Label>Supplier code</Form.Label></Col>
								<Col xs="9">
									<SuppCodeSelect
										handleSelect={this.onSuppCodeSelect}
										selectedSupplierCode={(this.props.criteria && this.props.criteria.SuppCode) || this.state.selectedSuppCode}
										isClearable
									/>
								</Col>
							</Row>
						</Form.Group>

						<Form.Group controlId="formFutureBooking">
							<Row>
								<Col xs="9" className="offset-3">
									<Form.Check
										type="checkbox"
										id="checkbox-future-booking"
										label="Future Booking"
										checked={this.state.criteria.SearchFutureBooking}
										onChange={(e) => this.onCheckboxClick(e)}
										name="SearchFutureBooking"
									/>
								</Col>
							</Row>
						</Form.Group>
						<div className="text-center pt-3 pb-5">
							<Button variant="outline-info" size="lg" type="button" className="mr-3" onClick={() => this.onResetClick()}>Reset
							</Button>
							<Button variant="info" size="lg" type="button" onClick={() => this.onSubmit()}>
								<FontAwesomeIcon icon={faSearch} className="mr-1" />Search
							</Button>
						</div>
					</Form>
				</Col>
			</Row>
    );
  }
}

SearchForm.propTypes = {
	handleFormSubmit: PropTypes.func,
	criteria: PropTypes.object,
	hasResult: PropTypes.bool
};

function mapStateToProps(state) {
	return {
		criteria: state.search.criteria
	}
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
