import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, Row, Col, Button, Modal} from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import TextareaAutosize from 'react-autosize-textarea';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSortNumericDown} from '@fortawesome/free-solid-svg-icons';

import Pagination from '../Pagination';
import {prettyDateTime} from '../../util/transformDate.js';
import _get from 'lodash/get';
import _chunk from 'lodash/chunk';
import _size from 'lodash/size';
import _trim from 'lodash/trim';
import _extend from 'lodash/extend';

const chunkSize = 30;

class TabHistory extends Component {
	constructor(props) {
		super(props);
		this.onFieldChange = this.onFieldChange.bind(this)
		this.onROContentChange = this.onROContentChange.bind(this)
		this.onSubmitClick = this.onSubmitClick.bind(this)
		this.onSendROClick = this.onSendROClick.bind(this)
		this.onPageChange = this.onPageChange.bind(this)
		this.toogleModal = this.toogleModal.bind(this)
		this.onShowAllClick = this.onShowAllClick.bind(this)

		this.state = {
			note: '',
			currentPage: 0,
			isPopUpModalOpen: false,
			form: {
				to: '',
				from: '',
				cc: '',
				content: '',
			},
			isReverse: false,
			isShowAll: false,
		};

	}

	componentDidUpdate(prevProps, prevState) {
		if(_size(prevProps.histories) < _size(this.props.histories)) {
			this.clearNote()
		}

		if (!prevProps.reSendROContent && this.props.reSendROContent) {
			this.toogleModal(true)
		}
	}

	onFieldChange(e) {
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	onROContentChange(e) {
		this.setState({
			form: _extend(this.state.form, {
				content: e.target.value
			})
		})
	}

	onPageChange(page) {
		this.setState({
			currentPage: page
		})
	}

	onSubmitClick() {
		this.props.handleAddHistory(
			this.props.bookingDetail.get('txno'),
			this.props.bookingDetail.get('seqnum'),
			this.state.note
		);
	}

	onSendROClick() {
		this.props.handleSendRO(
			_get(this.props.reSendROContent, 'txno'),
			_get(this.props.reSendROContent, 'seqNum'),
			this.state.form
		);
	}

	onKeyDown(e) {
		if (e.key === 'Enter') {
			this.onSubmitClick()
		}
	}

	onReOrder() {
		this.setState({
			isReverse: !this.state.isReverse
		})
	}

	toogleModal(bool) {
		this.setState({
			isPopUpModalOpen: bool
		})
		if (bool === false) {
			this.props.handleClearROHistoryContent()
		} else {
			this.setState({
				form: {
					to: _trim(_get(this.props.reSendROContent, 'destination')),
					from: _trim(_get(this.props.reSendROContent, 'fromEmail')),
					cc: _trim(_get(this.props.reSendROContent, 'ccEmail')),
					content: _get(this.props.reSendROContent, 'content')
				}
			})
		}
	}

	clearNote() {
		this.setState({
			note: '',
			currentPage: 0
		})
	}

	onShowAllClick() {
		this.setState({
			isShowAll: !this.state.isShowAll
		})
	}

	getRow(history, i) {
		return (
			<tr key={i}>
				<td>{this.props.histories.indexOf(history) + 1}</td>
				<td>{prettyDateTime(_get(history, 'timeStamp'))}</td>
				<td>{_get(history, 'loginCode')}</td>
				<td>{history.isRO ?
					<Button variant="link" className={`p-0 text-left ${_get(history, 'historyType') === 'SYSTEM' ? '' : 'font-weight-bold'}`} onClick={() => this.props.handleNoteClick(_get(history, 'autonum'))}>{_get(history, 'notes')}</Button> :
					<span className={_get(history, 'historyType') === 'SYSTEM' ? '' : 'font-weight-bold'}>{_get(history, 'notes')}</span>
				}</td>
			</tr>
		)
	}

	render() {
		const hasHistory = this.props.histories && this.props.histories.length > 0
		const isMultiPage = hasHistory && _chunk(this.props.histories, chunkSize).length > 1
		let orderOfHistory = this.state.isReverse ? this.props.histories.slice().reverse() : this.props.histories
		return (
			<div className="mt-4">
				{hasHistory &&
					<div>{this.props.histories.length} record(s){isMultiPage && <div className="custom-control custom-switch inline ml-3">
					  <input type="checkbox" className="custom-control-input" id="customSwitch1" onChange={this.onShowAllClick} />
					  <label className="custom-control-label" htmlFor="customSwitch1">Show All</label>
					</div>}
					</div>
				}
				<table className={`table table-bordered mt-2 mb-5 ${hasHistory ? 'table-striped' : ''}`}>
					<thead>
						<tr>
							<th width="24">#</th>
							<th width="270">Date/ Time<Button
								variant="none"
								onClick={() => this.onReOrder()}
								className="py-0"
							><FontAwesomeIcon icon={faSortNumericDown} /></Button></th>
							<th width="130">Login</th>
							<th>Notes</th>
						</tr>
					</thead>
					<tbody>
						{hasHistory &&
							this.state.isShowAll ? orderOfHistory.map((history, i) =>
								this.getRow(history, i)) :
							_chunk(orderOfHistory, chunkSize)[this.state.currentPage].map((history, i) =>
								this.getRow(history, i))
						}

						{!hasHistory &&
							<tr><td colSpan="5">
								<div className="text-center py-5">
									No history.
								</div>
							</td></tr>
						}
					</tbody>
				</table>
				{isMultiPage && !this.state.isShowAll &&
					<Pagination
						handlePageChange={this.onPageChange}
						noOfPages={_chunk(this.props.histories, chunkSize).length}
						currentPage={this.state.currentPage}
					/>
				}
			  <div className="pb-4 mt-5">
			    <div className="row justify-content-center">
			      <div className="col-lg-8 pt-4">
			        <div onKeyDown={(e) => this.onKeyDown(e)}>
								<Form.Group as={Row} controlId="note" className="justify-content-center">
									<Form.Label xs="3" className="text-right">
										Add history note:
									</Form.Label>
									<Col xs="7">
										<Form.Control
											type="text"
											value={this.state.note}
											name="note"
											onChange={(e) => this.onFieldChange(e)}
											maxLength={250}
										/>
									</Col>
								</Form.Group>

			          <div className="text-center mt-4">
			            <Button
										variant="secondary"
										type="button"
										onClick={this.onSubmitClick}
										disabled={!this.state.note}
										size="lg"
									>Submit</Button>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>

				<Modal show={this.state.isPopUpModalOpen} centered onHide={() => this.toogleModal(false)} size="lg">
				  <Modal.Header closeButton>
						<Modal.Title>
							RO History
						</Modal.Title>
				  </Modal.Header>

					<Modal.Body>
						<div ref={el => (this.componentRef = el)}>
							<div className="row p-1">
								<div className="col-3 detail-label py-2">
									Booking No.
								</div>
								<div className="col-7 py-2">
									{_get(this.props.reSendROContent, 'txno')} ({_get(this.props.reSendROContent, 'seqNum')})
								</div>
							</div>
							<div className="row p-1">
								<div className="col-3 detail-label py-2">
									Email
								</div>
								<div className="col-7 py-2">
									{_get(this.props.reSendROContent, 'destination')}
								</div>
							</div>
							<div className="row p-1">
								<div className="col-3 detail-label py-2">
									Time Sent
								</div>
								<div className="col-7 py-2">
									{prettyDateTime(_get(this.props.reSendROContent, 'sendTime'))}
								</div>
							</div>
							<div className="row p-1">
								<div className="col-3 detail-label py-2">
									User
								</div>
								<div className="col-7 py-2">
									{_get(this.props.reSendROContent, 'user')}
								</div>
							</div>
							<div className="row p-1 mb-3">
								<div className="col-3 detail-label py-2">
									Subject
								</div>
								<div className="col-7 py-2">
									{_get(this.props.reSendROContent, 'subject')}
								</div>
							</div>
							<TextareaAutosize
								value={this.state.form.content}
								name="content"
								className="form-control email-content"
								onResize={(e) => {}}
								onChange={(e) => this.onROContentChange(e)}
								rows={10}
							/>
						</div>
				  </Modal.Body>

				  <Modal.Footer>
						<div className="text-center w-100">
							<Button variant="primary" onClick={() => this.onSendROClick()} className="mr-2" >Re-Send</Button>
							<ReactToPrint
			          trigger={() => <Button variant="secondary">Print</Button>}
			          content={() => this.componentRef}
			        />
						</div>
				  </Modal.Footer>
				</Modal>
			</div>
	  );
	}
}

TabHistory.propTypes = {
	bookingDetail: PropTypes.object,
	histories: PropTypes.array,
	handleAddHistory: PropTypes.func,
	handleNoteClick: PropTypes.func,
	handleClearROHistoryContent: PropTypes.func,
	reSendROContent: PropTypes.object,
	handleSendRO: PropTypes.func,
};

export default TabHistory;
