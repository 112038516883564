import * as ActionTypes from '../constants/ActionTypes';
import moment from 'moment';

const initialState = {
	msg: null,
	isError: false,
	timestamp: null
};

const msg = (state = initialState, action) => {
	switch (action.type) {
	case ActionTypes.UPDATE_BOOKING_SUCCESS:
	case ActionTypes.SEND_RO_SUCCESS:
	case ActionTypes.ADD_HISTORY_SUCCESS:
	case ActionTypes.VERIFY_BOOKING_SUCCESS:
	case ActionTypes.UNVERIFY_BOOKING_SUCCESS:
	case ActionTypes.CLONE_SEGMENT_SUCCESS:
	case ActionTypes.COPY_ONE_TO_W_BOOKING_SUCCESS:
	case ActionTypes.COPY_W_TO_ONE_BOOKING_SUCCESS:
	case ActionTypes.CANCEL_BOOKING_SUCCESS:
	case ActionTypes.DELETE_ALERT_MSG_SUCCESS:
	case ActionTypes.UPDATE_ALERT_MSG_SUCCESS:
	case ActionTypes.UPDATE_ROSTERS_SUCCESS:
	case ActionTypes.ADD_ROSTERS_SUCCESS:
	case ActionTypes.CREATE_MSG_SUCCESS:
	case ActionTypes.CREATE_MANUAL_BOOKING_SUCCESS:
		return Object.assign({}, state, {
			msg: action.msg,
			isError: false,
			timestamp: moment().valueOf()
		});
	case ActionTypes.COPY_ONE_TO_W_BOOKING_FAILURE:
	case ActionTypes.COPY_W_TO_ONE_BOOKING_FAILURE:
	case ActionTypes.CLONE_SEGMENT_FAILURE:
	case ActionTypes.GET_BOOKING_FAILURE:
	case ActionTypes.UPDATE_BOOKING_FAILURE:
	case ActionTypes.GET_HEADER_FAILURE:
	case ActionTypes.SEND_RO_FAILURE:
	case ActionTypes.READ_MSG_FAILURE:
	case ActionTypes.DELETE_MSG_FAILURE:
	case ActionTypes.CREATE_MSG_FAILURE:
	case ActionTypes.ADD_HISTORY_FAILURE:
	case ActionTypes.VERIFY_BOOKING_FAILURE:
	case ActionTypes.UNVERIFY_BOOKING_FAILURE:
	case ActionTypes.SEARCH_BOOKING_FAILURE:
	case ActionTypes.SEARCH_BOOKING_NOTISSUE_FAILURE:
	case ActionTypes.CANCEL_BOOKING_FAILURE:
	case ActionTypes.DELETE_ALERT_MSG_FAILURE:
	case ActionTypes.UPDATE_ALERT_MSG_FAILURE:
	case ActionTypes.CREATE_ALERT_MSG_FAILURE:
	case ActionTypes.GET_RO_HISTORY_CONTENT_FAILURE:
	case ActionTypes.GET_CURRENCY_LIST_FAILURE:
	case ActionTypes.GET_PROFILE_FAILURE:
	case ActionTypes.GET_ROSTERS_FAILURE:
	case ActionTypes.GET_TEAM_MEMBER_FAILURE:
	case ActionTypes.GET_ALERT_MSG_FAILURE:
	case ActionTypes.UPDATE_ROSTERS_FAILURE:
	case ActionTypes.ADD_ROSTERS_FAILURE:
	case ActionTypes.CREATE_MANUAL_BOOKING_FAILURE:
		return Object.assign({}, state, {
			msg: action.msg,
			isError: true,
			timestamp: moment().valueOf()
		});
	case ActionTypes.CLEAR_BOOKING:
	case ActionTypes.CLEAR_NOTISSUE_BOOKING:
	case ActionTypes.CLEAR_MSG:
		return Object.assign({}, state, {
			msg: null
		});
	default:
		return state;
	}
};

export default msg;
