import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowAltLeft} from '@fortawesome/free-solid-svg-icons';

const BackButton = (props) => {
	return (
		<div className="px-2 pt-2 pb-4 back-btn-wrapper">
			<Button variant="light" onClick={() => props.handleClick()}>
				<FontAwesomeIcon icon={faLongArrowAltLeft} className="mr-2" />Back {props.title}
			</Button>
		</div>
  );
}

BackButton.propTypes = {
	title: PropTypes.string,
	handleClick: PropTypes.func
};

export default BackButton;
