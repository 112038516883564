import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Media, Button} from 'react-bootstrap';
import {faUserCircle, faHome, faSearch, faFileAlt, faBell, faSignOutAlt, faBars, faCircle, faBusinessTime, faHandSpock, faPercentage} from '@fortawesome/free-solid-svg-icons';
import Auth from '../Auth/Auth';

import {getProfile} from '../actions/index'; // Import your actions
import {clearBookingSearchResult} from '../actions/search'; // Import your actions
import {clearBookingSearchNotIssueResult} from '../actions/searchnotissue'; // Import your actions

import Loading from '../components/Loading';
import PopupMsg from '../components/PopupMsg';

const auth = new Auth();


class MainMenu extends Component {
	constructor(props) {
		super(props);
		this.logout = this.logout.bind(this);
		this.onMainAreaClick = this.onMainAreaClick.bind(this);
		this.onMenuClick = this.onMenuClick.bind(this);

		this.state = {
			currentNav: ''
		}
	}

	componentDidMount() {
		document.getElementById('root').addEventListener('click', this.onMainAreaClick)

		 if (auth.isAuthenticated()) {
			 this.props.getProfile()
		 }

		// get profile every min
		// window.setInterval(() => {
		// 	if (auth.isAuthenticated()) this.props.getProfile();
		// }, 60000);

		this.checkCurrentNav();
	}

	componentWillUnmount() {
		document.getElementById('root').removeEventListener('click', this.onMainAreaClick)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.currentPage !== this.props.currentPage) {
			this.checkCurrentNav();
		}
	}

	onMainAreaClick(e) {
		const area = this.area;

		if (area.contains(e.target) && this.props.isOpen) {
			this.props.handleBurgerClick()
		}
	}

	onMenuClick(linkTo) {
		this.setState({
			currentNav: linkTo
		})
		this.props.handleBurgerClick()

		if (linkTo !== '/search-booking' && this.props.searchResult) {
			this.props.clearBookingSearchResult()
		} else if (linkTo !== '/search-booking-notissue' && this.props.searchNotIssueResult) {
			this.props.clearBookingSearchNotIssueResult()
		}

	}

	logout() {
		auth.logout();
	}

	checkCurrentNav() {
		this.setState({
			currentNav: this.props.currentPage
		})
	}

	render() {

    const hasMsg = this.props.unreadMsg.length > 0
		let navInfo = []
		if (this.props.profileInfo) {
			navInfo = [
				{ name: 'Home', navName: 'home', linkTo: '/', icon: faHome, isShow: true },
				{ name: 'Search Booking', navName: 'search-booking', linkTo: '/search-booking', icon: faSearch, isShow: this.props.profileInfo.accessControl.HFS_BKG_SEARCH === 'Y' },
				{ name: 'Search Booking(Not Issue)', navName: 'search-booking-notissue', linkTo: '/search-booking-notissue', icon: faSearch, isShow: this.props.profileInfo.accessControl.HFS_BKG_NOTISSUE_SEARCH === 'Y' },
				{ name: 'Manual Booking', navName: 'manual-booking', linkTo: '/manual-booking', icon: faHandSpock, isShow: this.props.profileInfo.accessControl.HFS_BKG_MANUALBOOKING === 'Y' },
				{ name: 'Report', navName: 'report', linkTo: '/report', icon: faFileAlt, isShow:
					this.props.profileInfo.accessControl.HFS_RPT_ARRIVAL === 'Y' ||
					this.props.profileInfo.accessControl.HFS_RPT_PREPAYMENT === 'Y' ||
					this.props.profileInfo.accessControl.HFS_RPT_PENDINGBKGQUEUE === 'Y' ||
					this.props.profileInfo.accessControl.HFS_RPT_PENDINGVCHQUEUE === 'Y' ||
					this.props.profileInfo.accessControl.HFS_RPT_INSTANCEALLOTMENT === 'Y' ||
					this.props.profileInfo.accessControl.HFS_RPT_PROBLEMBOOKINGRESERVE === 'Y' ||
					this.props.profileInfo.accessControl.HFS_RPT_PROBLEMBOOKINGCANCEL === 'Y'
				},
				{ name: 'Alert Message', navName: 'alert-message', linkTo: '/alert-message', icon: faBell, isShow: this.props.profileInfo.accessControl.HFS_ALERTMSG === 'Y' },
				{ name: 'Rosters', navName: 'rosters', linkTo: '/rosters', icon: faBusinessTime, isShow: this.props.profileInfo.accessControl.HFS_ROSTERS === 'Y' },
				{ name: 'Rate Maintenance', navName: 'rate-maintenance', linkTo: 'https://hotelapp.travelctm.asia/ratemaintenance/', icon: faPercentage, isExternalLink: true, isShow: this.props.profileInfo.accessControl.HFS_RATEMAIN === 'Y' },
			]
		}

		return (
			<div>
				<div
					className={`overlay ${this.props.isOpen ? '' : 'hidden'}`}
					ref={ref => this.area = ref}
				/>
				<nav className={`left-nav ${this.props.isOpen ? 'nav-open' : 'nav-close'}`}>
					<ul className="list-group list-group-flush left-nav__content">
						<div className="text-center">
							<img className="m-2" src="images/logo.png" alt="Westminster" />
							<p className="white px-1 mb-2">Hotel Fulfillment System</p>
						</div>
						<div className="text-center pb-2 white">
							<div className="font-weight-bold h5"><FontAwesomeIcon icon={faUserCircle} /> {this.props.profileInfo && `${this.props.profileInfo.typeCode}/${this.props.profileInfo.loginCode}`}</div>
						</div>
						<div className="menu-buttons">
							{navInfo.map((nav, i) =>
								nav.isShow &&
								<li
									key={nav.linkTo}
									className={`list-group-item ${this.state.currentNav === nav.linkTo ? 'active' : ''}`}
								>
									{nav.isExternalLink ?
										<a href={nav.linkTo} target="blank" className="nav-link px-3 py-3">
											<FontAwesomeIcon icon={nav.icon} className="mr-2" />
											{nav.name}
										</a> :
										<Link
											className="nav-link px-3 py-3"
											to={nav.linkTo}
											onClick={() => this.onMenuClick(nav.linkTo)}
										>
											<FontAwesomeIcon icon={nav.icon} className="mr-2" />
											{nav.name}
											{nav.navName === "home" && this.props.profileInfo && hasMsg &&
												<FontAwesomeIcon icon={faCircle} size="xs" className="red ml-1" />
											}
										</Link>
									}

								</li>
							)}
						</div>
						<li className="list-group-item mt-auto">
							<Button className="nav-link px-3 py-3 btn-block" onClick={() => this.logout()}>
								<Media>
									<FontAwesomeIcon icon={faSignOutAlt} className="mr-2 mt-1" />
									<div className="text-left">Sign out</div>
								</Media>
							</Button>
						</li>
					</ul>
					<div className="toggle-nav-wrapper">
						<button className="btn toggle-nav-btn" onClick={() => this.props.handleBurgerClick()}>
							<FontAwesomeIcon icon={faBars} />
						</button>
						{this.props.profileInfo && hasMsg &&
							<FontAwesomeIcon icon={faCircle} size="xs" className="red notification-dot" />
						}
					</div>
				</nav>

        {this.props.pageLoading && <Loading />}

				<PopupMsg
          msg={this.props.msg}
          timestamp={this.props.timestamp}
          isError={this.props.isError}
        />
			</div>
		);
	}
}

MainMenu.propTypes = {
	handleBurgerClick: PropTypes.func,
	clearBookingSearchResult: PropTypes.func,
	clearBookingSearchNotIssueResult: PropTypes.func,
	isOpen: PropTypes.bool,
	currentPage: PropTypes.string,
	msg: PropTypes.string,
	isError: PropTypes.bool,
	timestamp: PropTypes.number,
	unreadMsg: PropTypes.array,
};

function mapStateToProps(state) {
	return {
		profileInfo: state.general.profileInfo,
		searchResult: state.search.searchResult,
		searchNotIssueResult: state.searchnotissue.searchNotIssueResult,
		pageLoading: state.loading.pageLoading,
		msg: state.msg.msg,
    isError: state.msg.isError,
    timestamp: state.msg.timestamp,
    unreadMsg: state.general.unreadMsg,
	}
}

const mapDispatchToProps = {
	getProfile,
	clearBookingSearchResult,
	clearBookingSearchNotIssueResult,
};

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
