import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import {prettySingleDate, dateFormat, dateFormatToday, prettySingleDateTime} from '../util/transformDate';

class CustomDatePicker extends Component {
	constructor(props) {
		super(props);
		this.onDateChange = this.onDateChange.bind(this)
	}

	onDateChange(event, picker) {
		this.props.handleSelect(this.el.name, this.props.index, picker ? picker.startDate.format() : '')
	}

  render() {
		return (
			<div className="relative">
				<DateRangePicker
					onApply={this.onDateChange}
					autoApply
					singleDatePicker
					startDate={this.props.value ? dateFormat(this.props.value) : dateFormatToday()}
					timePicker={this.props.hasTimePicker}
					timePickerIncrement={5}
				>
					<Form.Control
						type="text"
						value={this.props.hasTimePicker ? prettySingleDateTime(this.props.value) : prettySingleDate(this.props.value)}
						name={this.props.name}
						ref={ref => this.el = ref}
						autoComplete="off"
						onChange={() => console.log()}
						placeholder="Pick a Date"
						isInvalid={this.props.isInvalid}
					/>
				</DateRangePicker>
				{this.props.isClearAvailable && this.props.value && <button type="button" onClick={() => this.onDateChange(this.props.name, '')} className="btn date-range__cancel-icon"><FontAwesomeIcon icon={faTimes} size="sm" /></button>}
			</div>
    );
  }
}

CustomDatePicker.propTypes = {
	handleSelect: PropTypes.func,
	name: PropTypes.string,
	value: PropTypes.string,
	hasTimePicker: PropTypes.bool,
	index: PropTypes.number,
	isInvalid: PropTypes.bool,
	isClearAvailable: PropTypes.bool,
};

export default CustomDatePicker;
